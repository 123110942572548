<template>
  <div class="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8 lg:mt-0 mt-20"
    v-if="authStore.isAuthenticated">
    <!-- Left column -->
    <div class="grid grid-cols-1 gap-4 lg:col-span-2">
      <!-- Welcome panel -->
      <section aria-labelledby="profile-overview-title">
        <div class="overflow-hidden rounded-lg bg-white shadow">
          <!-- User Header -->
          <div class="bg-blue-700/80 text-white p-6">
            <div class="sm:flex sm:items-center sm:justify-between">
              <div class="sm:flex sm:space-x-5">
                <div class="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                  <p class="text-sm font-medium">
                    {{ welcomeMessage }}
                  </p>
                  <p class="capitalize text-xl font-bold sm:text-2xl">
                    {{ userName }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- User Profile Info -->
          <div class="p-6">
            <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div v-for="field in profileFields" :key="field.label" class="sm:col-span-1">
                <dt class="text-sm font-medium text-gray-500">{{ field.label }}</dt>
                <dd class="mt-1 text-sm text-gray-900">{{ field.value }}</dd>
              </div>
            </dl>
          </div>
        </div>

        <!-- Pending Tasks Section -->
        <div v-if="getPendingTasks.length > 0" class="border-t border-gray-200 bg-yellow-50 p-4">
          <div class="space-y-4">
            <h3 class="text-lg font-medium text-gray-900">Pending Tasks</h3>
            <div v-for="task in getPendingTasks" :key="task.name" class="bg-white p-4 rounded-lg shadow-sm">
              <div class="flex items-center justify-between">
                <div class="flex items-center">
                  <component :is="task.icon" class="h-6 w-6 text-gray-400" aria-hidden="true" />
                  <div class="ml-3">
                    <p class="text-sm font-medium text-gray-900">{{ task.name }}</p>
                    <p class="text-sm text-gray-500">{{ task.description }}</p>
                  </div>
                </div>
                <router-link :to="task.to"
                  class="ml-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  Start Now
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <!-- Admin Actions -->
        <div v-if="roleActions.length > 0" class="border-t border-gray-200 p-4">
          <div class="flex flex-wrap gap-2">
            <router-link v-for="action in roleActions" :key="action.name" :to="action.to"
              class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700">
              {{ action.name }}
            </router-link>
          </div>
        </div>
      </section>

      <!-- Role-specific Dashboard -->
      <component :is="roleDashboard" v-if="roleDashboard" :actions="dashboardActions" />
    </div>

    <!-- Right column -->
    <component :is="rightPanel" v-if="rightPanel" :users="users" @send-link="handleSendLoginLink" />
  </div>

  <!-- Redirect if not authenticated -->
  <router-link v-else to="/login" replace />
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useInternalAuthStore } from "@/stores/internalAuth";
import { useRouter } from "vue-router";
import { ExclamationTriangleIcon, DocumentTextIcon } from '@heroicons/vue/24/outline';
import DashboardPanel from '@/components/Dashboard/DashboardPanel.vue';
import AdminDashboard from '@/components/Dashboard/AdminDashboard.vue';
import EmployeeDashboard from '@/components/Dashboard/EmployeeDashboard.vue';
import SendLoginLinkPanel from '@/components/Dashboard/SendLoginLinkPanel.vue';
import { AcademicCapIcon, UsersIcon, ShieldCheckIcon } from "@heroicons/vue/24/outline";

const authStore = useInternalAuthStore();
const router = useRouter();
const users = ref([]);

// Computed properties
const userName = computed(() =>
  `${authStore.user?.firstName || ''} ${authStore.user?.lastName || ''}`
);

const welcomeMessage = computed(() => {
  if (authStore.isAdminUser) return 'Welcome to the Admin Portal! 👋';
  if (authStore.isCandidateUser) return 'Welcome to the Assessment Portal! 📝';
  if (authStore.isEmployeeUser) return 'Welcome to the Employee Portal! 🏢';
  return 'Welcome to the HR Portal! 🎉';
});

const policyAccepted = computed(() => authStore.user?.policyAccepted || false);
const communicationExercisesCompleted = computed(() =>
  authStore.user?.prefs?.communication_exercises_completed === true
);

// Updated to show buttons based on role and task status
const getPendingTasks = computed(() => {
  const tasks = [];

  if (authStore.isCandidateUser && !communicationExercisesCompleted.value) {
    tasks.push({
      name: 'Complete Communication Exercises',
      to: '/kurtz',
      priority: 'high',
      icon: AcademicCapIcon,
      description: 'Required assessment for all candidates'
    });
  }

  if (authStore.isEmployeeUser && !policyAccepted.value) {
    tasks.push(
      {
        name: 'Review Company Policy',
        to: '/accept-policy',
        priority: 'high',
        icon: DocumentTextIcon,
        description: 'Required for all employees'
      },
      {
        name: 'Complete Security Assessment',
        to: '/security-assessment',
        priority: 'high',
        icon: ShieldCheckIcon,
        description: 'Required for all employees'
      }
    );
  }

  return tasks;
});

const showActionButtons = computed(() => {
  return roleActions.value.length > 0 || getPendingTasks.value.length > 0;
});

const roleActions = computed(() => {
  if (authStore.isAdminUser) {
    return [
      { name: 'Invite Employee', to: '/invite-team-member' },
      { name: 'Invite Candidate', to: '/invite-candidate' },
      { name: 'Review Kurtz Kit', to: { name: 'Kurtz' } }
    ];
  }
  return [];
});

const visibleButtons = computed(() => {
  return [...getPendingTasks.value, ...roleActions.value];
});

const profileFields = computed(() => [
  { label: 'Email', value: authStore.user?.email },
  { label: 'Role', value: authStore.userRole }
]);

const dashboardActions = [
  {
    icon: UsersIcon,
    name: "Recent Hires",
    description: "View all recent hires",
    href: "recent-hires",
    iconForeground: "text-green-700",
    iconBackground: "bg-green-50",
  },
];

// Dynamic components based on user role
const roleDashboard = computed(() => {
  if (authStore.isAdminUser) return AdminDashboard;
  if (authStore.isEmployeeUser) return EmployeeDashboard;
  return null;
});

const rightPanel = computed(() => {
  if (authStore.isAdminUser) return SendLoginLinkPanel;
  return null;
});

// Methods
const handleSendLoginLink = async (email) => {
  try {
    await authStore.sendMagicURL(email);
  } catch (error) {
    console.error('Failed to send login link:', error);
  }
};

// Lifecycle hooks
onMounted(async () => {
  if (authStore.isAdminUser) {
    try {
      const response = await authStore.fetchAllUsers();
      users.value = response.users;
    } catch (error) {
      console.error('Failed to fetch users:', error);
    }
  }
});
</script>
