<template>
  <Popover as="header" class="bg-blue-200 sm:pb-24 mt-10 lg:mt-0" v-slot="{ open }">
    <div class="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
      <div class="relative flex flex-wrap items-center justify-center lg:justify-between">
        <!-- Logo -->
        <div class="absolute left-0 flex-shrink-0 py-5 lg:static">
          <router-link to="/" class="-m-1.5 p-1.5">
            <span class="sr-only">Protocase Inc</span>
            <Logo />
          </router-link>
        </div>

        <!-- Right section on desktop -->
        <div class="hidden lg:ml-4 lg:flex lg:items-center lg:py-2 lg:pr-0.5">
          <nav class="flex space-x-4">
            <a
              v-for="item in navigation"
              :key="item.name"
              :href="item.href"
              :class="[
                item.current ? 'text-white' : 'text-gray-500',
                'rounded-md bg-white bg-opacity-0 px-3 py-2 text-sm font-medium hover:bg-opacity-10',
              ]"
              :aria-current="item.current ? 'page' : undefined"
              >{{ item.name }}</a
            >
          </nav>

          <!-- Logout button -->
          <template v-if="authStore.isAuthenticated">
            <Logout />
          </template>
        </div>

        <!-- Menu button -->
        <div class="absolute right-0 flex flex-shrink-0 lg:hidden">
          <!-- Logout for mobile -->
          <template v-if="authStore.isAuthenticated">
            <Logout />
          </template>
          <!-- Mobile menu button -->
          <PopoverButton
            class="relative inline-flex items-center justify-center rounded-md bg-transparent p-2 text-blue-800 hover:bg-white hover:bg-opacity-10 hover:text-blue-900 focus:outline-none focus:ring-2 focus:ring-white"
          >
            <span class="absolute -inset-0.5" />
            <span class="sr-only">Open main menu</span>
            <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </PopoverButton>
        </div>
      </div>
    </div>

    <TransitionRoot as="template" :show="open">
      <div class="lg:hidden">
        <TransitionChild
          as="template"
          enter="duration-150 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-150 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <PopoverOverlay class="fixed inset-0 z-20 bg-black bg-opacity-25" />
        </TransitionChild>

        <TransitionChild
          as="template"
          enter="duration-150 ease-out"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="duration-150 ease-in"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <PopoverPanel
            focus
            class="absolute inset-x-0 top-0 z-30 mx-auto w-full max-w-3xl origin-top transform p-2 transition"
          >
            <div
              class="divide-y divide-gray-200 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
            >
              <div class="pb-2 pt-3">
                <div class="flex items-center justify-between px-4">
                  
                  <div class="-mr-2">
                    <PopoverButton
                      class="relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500"
                    >
                      <span class="absolute -inset-0.5" />
                      <span class="sr-only">Close menu</span>
                      <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                    </PopoverButton>
                  </div>
                </div>
                
                <div class="mt-3 space-y-1 px-2">
                  <a
                    v-for="item in navigation"
                    :key="item.name"
                    :href="item.href"
                    class="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800"
                    >{{ item.name }}</a
                  >
                </div>
                
              </div>
              
            </div>
          </PopoverPanel>
        </TransitionChild>
      </div>
    </TransitionRoot>
  </Popover>
</template>

<script setup>
import { ref, onMounted } from "vue";
import {
  Popover,
  PopoverButton,
  PopoverOverlay,
  PopoverPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/vue/24/outline";
import { useInternalAuthStore } from "@/stores/internalAuth";
import Logo from "@/components/Logo.vue";
import Logout from "@/components/Logout.vue";

const authStore = useInternalAuthStore();

const navigation = [
  { name: "Home", href: "/", current: true },
  { name: "Profile", href: "#", current: false },
  { name: "Resources", href: "#", current: false },
  { name: "Company Directory", href: "#", current: false },
  { name: "Openings", href: "#", current: false },
];

// Hide navigation based on auth and role
if (!authStore.isAuthenticated) {
  navigation.length = 0;
}

if (!authStore.isAdmin) {
  navigation.length = 0;
}

onMounted(async () => {
  await authStore.init();
});
</script>
