import { useInternalAuthStore } from '@/stores/internalAuth';

const PUBLIC_ROUTES = [
    '/login',
    '/register',
    '/forgot-password',
    '/reset-password',
    '/verify-email',
    '/verify-invitation',
    '/magic-link',
    '/activate', // Add activate to public routes
];

export async function setupAuthGuard(router) {
    router.beforeEach(async (to, from, next) => {
        const authStore = useInternalAuthStore();
        
        // Check if route requires authentication
        const isPublicRoute = PUBLIC_ROUTES.some(route => 
            to.path.startsWith(route)
        ) || to.meta.isPublic;

        if (isPublicRoute) {
            next();
            return;
        }

        // For protected routes, check authentication
        if (!authStore.isAuthenticated) {
            authStore.redirectPath = to.fullPath;
            next('/login');
            return;
        }

        next();
    });
}
