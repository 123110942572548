<template>
  <div class="verify-container">
    <div v-if="loading" class="loading">
      <h2>Verifying your login...</h2>
      <div class="loading-spinner"></div>
    </div>
    <div v-else-if="error" class="error">
      <h2>Verification Failed</h2>
      <p>{{ error }}</p>
      <router-link to="/login" class="button">Return to Login</router-link>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useInternalAuthStore } from '@/stores/internalAuth';

const route = useRoute();
const router = useRouter();
const authStore = useInternalAuthStore();

const loading = ref(true);
const error = ref('');

onMounted(async () => {
  const token = route.query.token;

  console.log('Token:', token);

  if (!token) {
    error.value = 'Invalid login link';
    loading.value = false;
    return;
  }

  try {
    await authStore.verifyMagicLink(token);
    router.push('/');
  } catch (err) {
    console.error('Magic link verification error:', err);
    error.value = err.response?.data?.error || 'Failed to verify login link';
  } finally {
    loading.value = false;
  }
});
</script>

<style scoped>
.verify-container {
  max-width: 400px;
  margin: 40px auto;
  padding: 20px;
  text-align: center;
}

.loading {
  color: #666;
}

.loading-spinner {
  /* Add your loading spinner styles */
  margin: 20px auto;
}

.error {
  color: #dc3545;
  margin-top: 20px;
}

.button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 4px;
}

.button:hover {
  background-color: #0056b3;
}
</style>
