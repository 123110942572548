<template>
  <div class="bg-blue-50 rounded-lg mx-auto p-4">


    <div v-if="loading" class="flex justify-center items-center min-h-[200px]">
      <div class="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
    </div>
    <div v-else>
      <!-- Controls section -->
      <div class="mb-6 space-y-4">
        <!-- Search and filters -->
        <div class="grid grid-cols-1 md:grid-cols-4 gap-4">
          <div class="col-span-1">
            <input type="text" v-model="searchQuery" placeholder="Search by name..."
              class="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              @input="debouncedSearch" />
          </div>
          <div class="col-span-1">
            <select v-model="sortBy"
              class="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500">
              <option value="name">Sort by Name</option>
              <option value="date">Sort by Date</option>
            </select>
          </div>
          <div class="col-span-1">
            <select v-model="sortOrder"
              class="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500">
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </select>
          </div>
          <div class="col-span-1">
            <select v-model="showArchived"
              class="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500">
              <option value="active">Active Results</option>
              <option value="archived">Archived Results</option>
              <option value="all">All Results</option>
            </select>
          </div>
        </div>

        <!-- Stats summary -->
        <div class="bg-white p-4 rounded-lg shadow-sm">
          <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
            <div class="text-center">
              <p class="text-gray-600 text-sm">Total Results</p>
              <p class="text-xl font-bold">{{ filteredResults.length }}</p>
            </div>
            <div class="text-center">
              <p class="text-gray-600 text-sm">Last 7 Days</p>
              <p class="text-xl font-bold">{{ lastSevenDaysCount }}</p>
            </div>
            <div class="text-center">
              <p class="text-gray-600 text-sm">This Month</p>
              <p class="text-xl font-bold">{{ thisMonthCount }}</p>
            </div>
            <div class="text-center">
              <p class="text-gray-600 text-sm">Total Users</p>
              <p class="text-xl font-bold">{{ uniqueUsersCount }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Results table -->
      <div class="bg-white rounded-lg shadow overflow-hidden">
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr>
              <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date
              </th>
              <th class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-if="paginatedResults.length === 0">
              <td colspan="3" class="px-6 py-4 text-center text-gray-500">
                No results found 
              </td>
            </tr>
            <tr v-for="entry in paginatedResults" :key="entry.id" class="hover:bg-gray-50">
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="text-sm font-medium text-gray-900">{{ entry.name }}</div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="text-sm text-gray-500">
                  {{ formatDate(entry.createdAt) }}
                </div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <button @click="viewResult(entry)" class="text-blue-600 hover:text-blue-900 mr-2">
                  View
                </button>
                <button @click="toggleArchive(entry)" class="text-gray-600 hover:text-gray-900"
                  :class="{ 'text-amber-600 hover:text-amber-900': isArchived(entry) }">
                  {{ isArchived(entry) ? 'Unarchive' : 'Archive' }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Pagination -->
        <div class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
          <div class="flex-1 flex justify-between sm:hidden">
            <button @click="prevPage" :disabled="currentPage === 1"
              class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              :class="{ 'opacity-50 cursor-not-allowed': currentPage === 1 }">
              Previous
            </button>
            <button @click="nextPage" :disabled="currentPage >= totalPages"
              class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              :class="{ 'opacity-50 cursor-not-allowed': currentPage >= totalPages }">
              Next
            </button>
          </div>
          <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
              <p class="text-sm text-gray-700">
                Showing
                <span class="font-medium">{{ paginationStart }}</span>
                to
                <span class="font-medium">{{ paginationEnd }}</span>
                of
                <span class="font-medium">{{ filteredResults.length }}</span>
                results
              </p>
            </div>
            <div>
              <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
                <button @click="prevPage" :disabled="currentPage === 1"
                  class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  :class="{ 'opacity-50 cursor-not-allowed': currentPage === 1 }">
                  Previous
                </button>

                <!-- First page -->
                <button v-if="totalPages > 0" @click="goToPage(1)"
                  class="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium"
                  :class="currentPage === 1 ? 'text-blue-600 bg-blue-50' : 'text-gray-700 hover:bg-gray-50'">
                  1
                </button>

                <!-- Left ellipsis -->
                <span v-if="showLeftEllipsis"
                  class="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                  ...
                </span>

                <!-- Middle pages -->
                <button v-for="page in middlePages" :key="page" @click="goToPage(page)"
                  class="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium"
                  :class="currentPage === page ? 'text-blue-600 bg-blue-50' : 'text-gray-700 hover:bg-gray-50'">
                  {{ page }}
                </button>

                <!-- Right ellipsis -->
                <span v-if="showRightEllipsis"
                  class="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                  ...
                </span>

                <!-- Last page -->
                <button v-if="totalPages > 1" @click="goToPage(totalPages)"
                  class="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium"
                  :class="currentPage === totalPages ? 'text-blue-600 bg-blue-50' : 'text-gray-700 hover:bg-gray-50'">
                  {{ totalPages }}
                </button>

                <button @click="nextPage" :disabled="currentPage >= totalPages"
                  class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  :class="{ 'opacity-50 cursor-not-allowed': currentPage >= totalPages }">
                  Next
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, onUnmounted, watch } from "vue";
import { useInternalAuthStore } from "@/stores/internalAuth"; // Changed back to useInternalAuthStore
import { useRouter } from "vue-router";

const authStore = useInternalAuthStore(); // Changed back to useInternalAuthStore

// Initialize with empty array instead of undefined
const kurtzAllResults = ref([]);
const loading = ref(true); // Start with loading true
const searchQuery = ref("");
const sortBy = ref("date");
const sortOrder = ref("desc");
const showArchived = ref('active');

// Debouncing
let debounceTimer;
const debouncedSearch = () => {
  clearTimeout(debounceTimer);
  debounceTimer = setTimeout(() => { }, 300);
};

// Update date formatting with ISO string handling
const formatDate = (date) => {
  if (!date) return 'No Date';

  try {
    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) {
      console.error('Invalid date:', date);
      return 'Invalid Date';
    }

    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }).format(parsedDate);
  } catch (error) {
    console.error('Date parsing error:', error);
    return 'Invalid Date';
  }
};

// Computed properties with null checks
const filteredResults = computed(() => {
  if (!authStore.adminKurtzResults) return [];

  let results = authStore.adminKurtzResults;

  // Filter by search query
  if (searchQuery.value) {
    const query = searchQuery.value.toLowerCase();
    results = results.filter((entry) =>
      entry.name?.toLowerCase().includes(query)
    );
  }

  // Filter by archive status with type coercion handling
  if (showArchived.value === 'active') {
    results = results.filter(entry => 
      entry.archived === false || 
      entry.archived === 0 || 
      entry.archived === "0" || 
      entry.archived === "false"
    );
  } else if (showArchived.value === 'archived') {
    results = results.filter(entry => 
      entry.archived === true || 
      entry.archived === 1 || 
      entry.archived === "1" || 
      entry.archived === "true"
    );
  }

  return results;
});

const sortedResults = computed(() => {

  if (!filteredResults.value) return [];
  
  return [...filteredResults.value].sort((a, b) => {
    if (sortBy.value === "name") {
      return sortOrder.value === "asc"
        ? (a.name || '').localeCompare(b.name || '')
        : (b.name || '').localeCompare(a.name || '');
    } else {
      // Handle ISO date strings
      const dateA = a.createdAt ? new Date(a.createdAt).getTime() : 0;
      const dateB = b.createdAt ? new Date(b.createdAt).getTime() : 0;

      return sortOrder.value === "asc" ? dateA - dateB : dateB - dateA;
    }
  });
});

// Add pagination state
const currentPage = ref(1);
const itemsPerPage = ref(10);

// Add pagination computed properties
const paginatedResults = computed(() => {

  const start = (currentPage.value - 1) * itemsPerPage.value;
  const end = start + itemsPerPage.value;
  return sortedResults.value.slice(start, end);
});

const totalPages = computed(() => {
  return Math.ceil(filteredResults.value.length / itemsPerPage.value);
});

const paginationStart = computed(() => {
  return (currentPage.value - 1) * itemsPerPage.value + 1;
});

const paginationEnd = computed(() => {
  const end = currentPage.value * itemsPerPage.value;
  return end > filteredResults.value.length ? filteredResults.value.length : end;
});

// Add pagination methods
const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
};

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
};

// Reset pagination when filters change
watch([searchQuery, sortBy, sortOrder, showArchived], () => {
  currentPage.value = 1;
});

// Update stats computations with null checks
const lastSevenDaysCount = computed(() => {
  if (!filteredResults.value) return 0;

  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

  return filteredResults.value.filter(entry => {
    const entryDate = new Date(entry.createdAt);
    return !isNaN(entryDate.getTime()) && entryDate > sevenDaysAgo;
  }).length;
});

const thisMonthCount = computed(() => {
  if (!filteredResults.value) return 0;

  const firstDayOfMonth = new Date();
  firstDayOfMonth.setDate(1);
  firstDayOfMonth.setHours(0, 0, 0, 0);

  return filteredResults.value.filter(entry => {
    const entryDate = new Date(entry.createdAt);
    return !isNaN(entryDate.getTime()) && entryDate >= firstDayOfMonth;
  }).length;
});

const uniqueUsersCount = computed(() => {
  if (!filteredResults.value) return 0;

  const uniqueUsers = new Set(filteredResults.value.map((entry) => entry.userId));
  return uniqueUsers.size;
});

const router = useRouter();

// Actions
const viewResult = (entry) => {
  router.push({ name: "KurtzResultDetail", params: { id: entry.id } });
};

const downloadPDF = async (entry) => {
  await authStore.generatePDF(entry);
};

// Add this helper function
const isArchived = (entry) => {
  return Boolean(
    entry.archived === true || 
    entry.archived === 1 || 
    entry.archived === "1" || 
    entry.archived === "true"
  );
};

const toggleArchive = async (entry) => {
  try {
    const currentArchived = isArchived(entry);
    await authStore.toggleArchiveResult(entry.id, !currentArchived);
    
    // Update the entry's archived status locally
    entry.archived = !currentArchived;
    
    // Optionally refresh the results if needed
    // await authStore.fetchKurtzResults();
    // kurtzAllResults.value = authStore.kurtzResults;
  } catch (error) {
    console.error('Failed to toggle archive status:', error);
  }
};

// Add these new computed properties for pagination
const showLeftEllipsis = computed(() => {
  return currentPage.value > 4;
});

const showRightEllipsis = computed(() => {
  return currentPage.value < totalPages.value - 3;
});

const middlePages = computed(() => {
  const pages = [];
  let start = Math.max(2, currentPage.value - 2);
  let end = Math.min(totalPages.value - 1, currentPage.value + 2);

  if (currentPage.value <= 4) {
    start = 2;
    end = Math.min(6, totalPages.value - 1);
  } else if (currentPage.value >= totalPages.value - 3) {
    start = Math.max(totalPages.value - 5, 2);
    end = totalPages.value - 1;
  }

  for (let i = start; i <= end; i++) {
    pages.push(i);
  }
  return pages;
});

// Add goToPage method
const goToPage = (page) => {
  currentPage.value = page;
};

onMounted(async () => {
  try {
    loading.value = true;
    await authStore.fetchKurtzResults();
    kurtzAllResults.value = authStore.adminKurtzResults;
  } catch (error) {
    console.error('Failed to fetch Kurtz results:', error);
    kurtzAllResults.value = [];
  } finally {
    loading.value = false;
  }
});

onUnmounted(() => {
  clearTimeout(debounceTimer);
});
</script>

<style scoped>
/* Add any custom styling here */
</style>
