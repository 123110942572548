import axios from 'axios';

const API_URL = import.meta.env.VITE_BACKEND_API;
const APP_URL = import.meta.env.VITE_APP_URL;

// base axios instance with credentials support
export const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
    withCredentials: true,
    timeout: 10000,
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN'
});

// Add response interceptor for better error handling
axiosInstance.interceptors.response.use(
    response => response,
    error => {
        if (error.code === 'ERR_NETWORK') {
            console.error('Network error details:', {
                baseURL: axiosInstance.defaults.baseURL,
                url: error.config?.url,
                method: error.config?.method,
                origin: APP_URL,
                error: error.message
            });
        }
        return Promise.reject(error);
    }
);
