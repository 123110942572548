<template>
  <div class="bg-blue-50 rounded-lg mx-auto p-4">
    <div v-if="loading" class="flex justify-center items-center min-h-[200px]">
      <div class="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
    </div>
    <div v-else>
      <!-- Stats summary -->
      <div class="bg-white p-4 rounded-lg shadow-sm mb-6">
        <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
          <div class="text-center">
            <p class="text-gray-600 text-sm">Total Users</p>
            <p class="text-xl font-bold">{{ auditData.length }}</p>
          </div>
          <div class="text-center">
            <p class="text-gray-600 text-sm">Completed</p>
            <p class="text-xl font-bold">{{ completedCount }}</p>
          </div>
          <div class="text-center">
            <p class="text-gray-600 text-sm">Pending</p>
            <p class="text-xl font-bold">{{ pendingCount }}</p>
          </div>
          <div class="text-center">
            <p class="text-gray-600 text-sm">Completion Rate</p>
            <p class="text-xl font-bold">{{ completionRate }}%</p>
          </div>
        </div>
      </div>

      <!-- Search and Filters -->
      <div class="bg-white p-4 rounded-lg shadow-sm mb-6">
        <div class="grid grid-cols-1 md:grid-cols-4 gap-4">
          <div>
            <input v-model="searchQuery" type="text" placeholder="Search by name or email"
              class="w-full px-3 py-2 border rounded-lg" />
          </div>
          <div>
            <select v-model="statusFilter" class="w-full px-3 py-2 border rounded-lg">
              <option value="">All Status</option>
              <option value="completed">Completed</option>
              <option value="pending">Pending</option>
            </select>
          </div>
          <div>
            <input v-model="dateFilter" type="date" class="w-full px-3 py-2 border rounded-lg" />
          </div>
          <div>
            <button @click="resetFilters" class="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200">
              Reset Filters
            </button>
          </div>
        </div>
      </div>

      <!-- Results table -->
      <div class="bg-white rounded-lg shadow overflow-x-auto">
        <table class="max-w-7xl divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr>
              <th v-for="column in columns" :key="column.key" @click="sort(column.key)"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                {{ column.label }}
                <span v-if="sortBy === column.key">
                  {{ sortDirection === 'asc' ? '↑' : '↓' }}
                </span>
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="entry in paginatedAndFilteredData" :key="entry.email" class="hover:bg-gray-50">
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="text-sm font-medium text-gray-900">{{ entry.name }}</div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="text-sm text-gray-500">{{ entry.email }}</div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <span :class="{
                  'px-2 py-1 text-xs font-medium rounded': true,
                  'bg-green-100 text-green-800': entry.status === 'completed',
                  'bg-yellow-100 text-yellow-800': entry.status === 'pending'
                }">
                  {{ entry.status }}
                </span>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="text-sm text-gray-500">{{ formatDate(entry.invitedDate) }}</div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="text-sm text-gray-500">
                  {{ entry.completedDate ? formatDate(entry.completedDate) : '-' }}
                </div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">
                <div class="flex flex-col space-y-2">
                  <button v-if="entry.status === 'completed'" @click="viewResult(entry)"
                    class="inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24"
                      stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                    </svg>
                    View Result
                  </button>
                  <div v-else class="flex flex-col space-y-2">
                    <button @click="updateStatus(entry)"
                      class="inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-colors duration-200">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                      </svg>
                      Mark Completed
                    </button>
                    <button @click="sendMagicLink(entry)" :disabled="entry.isLoading"
                      class="inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200">
                      <svg v-if="!entry.isLoading" xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                      </svg>
                      <svg v-else class="animate-spin h-4 w-4 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                        </circle>
                        <path class="opacity-75" fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                        </path>
                      </svg>
                      {{ entry.isLoading ? 'Sending...' : 'Send Magic Link' }}
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Pagination -->
        <div class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200">
          <div class="flex-1 flex justify-between sm:hidden">
            <button @click="currentPage--" :disabled="currentPage === 1"
              class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
              Previous
            </button>
            <button @click="currentPage++" :disabled="currentPage === totalPages"
              class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
              Next
            </button>
          </div>
          <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
              <p class="text-sm text-gray-700">
                Showing {{ paginationStart }} to {{ paginationEnd }} of {{ filteredData.length }} results
              </p>
            </div>
            <div>
              <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
                <!-- Previous button -->
                <button @click="currentPage--" :disabled="currentPage === 1"
                  class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                  <span class="sr-only">Previous</span>
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd"
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clip-rule="evenodd" />
                  </svg>
                </button>

                <!-- First page -->
                <button @click="currentPage = 1" :class="[
                  currentPage === 1 ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50',
                  'relative inline-flex items-center px-4 py-2 border text-sm font-medium'
                ]">
                  1
                </button>

                <!-- Left ellipsis -->
                <span v-if="startPage > 2"
                  class="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                  ...
                </span>

                <!-- Page numbers -->
                <button v-for="page in displayedPages" :key="page" @click="currentPage = page" :class="[
                  currentPage === page ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50',
                  'relative inline-flex items-center px-4 py-2 border text-sm font-medium'
                ]">
                  {{ page }}
                </button>

                <!-- Right ellipsis -->
                <span v-if="endPage < totalPages - 1"
                  class="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                  ...
                </span>

                <!-- Last page -->
                <button v-if="totalPages > 1" @click="currentPage = totalPages" :class="[
                  currentPage === totalPages ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50',
                  'relative inline-flex items-center px-4 py-2 border text-sm font-medium'
                ]">
                  {{ totalPages }}
                </button>

                <!-- Next button -->
                <button @click="currentPage++" :disabled="currentPage === totalPages"
                  class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                  <span class="sr-only">Next</span>
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd" />
                  </svg>
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useInternalAuthStore } from '@/stores/internalAuth';
import { useRouter } from 'vue-router';

const authStore = useInternalAuthStore();
const router = useRouter();
const loading = ref(true);
const auditData = ref([]);

// Stats computations
const completedCount = computed(() => auditData.value.filter(entry => entry.status === 'completed').length);
const pendingCount = computed(() => auditData.value.filter(entry => entry.status === 'pending').length);
const completionRate = computed(() => {
  if (auditData.value.length === 0) return 0;
  return Math.round((completedCount.value / auditData.value.length) * 100);
});

const formatDate = (date) => {
  if (!date) return '-';
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  }).format(new Date(date));
};

const viewResult = (entry) => {
  if (entry.kurtzResultId) {
    router.push({ name: 'KurtzResultDetail', params: { id: entry.kurtzResultId } });
  }
};

const sendMagicLink = async (entry) => {
  try {
    // Set loading state for this entry
    entry.isLoading = true;
    await authStore.sendMagicURL(entry.email);
    // Show success notification (you can implement this based on your UI framework)
    alert(`Magic link sent to ${entry.email}`);
  } catch (error) {
    console.error('Failed to send magic link:', error);
    alert('Failed to send magic link. Please try again.');
  } finally {
    entry.isLoading = false;
  }
};

onMounted(async () => {
  try {
    loading.value = true;
    const response = await authStore.fetchKurtzAudit();
    // Add isLoading property to each entry
    auditData.value = response.data.map(entry => ({
      ...entry,
      isLoading: false
    }));
  } catch (error) {
    console.error('Failed to fetch Kurtz audit data:', error);
  } finally {
    loading.value = false;
  }
});

// Pagination and filtering state
const currentPage = ref(1);
const itemsPerPage = ref(10);
const searchQuery = ref('');
const statusFilter = ref('');
const dateFilter = ref('');
const sortBy = ref('invitedDate');
const sortDirection = ref('desc');

const columns = [
  { key: 'name', label: 'Name' },
  { key: 'email', label: 'Email' },
  { key: 'status', label: 'Status' },
  { key: 'invitedDate', label: 'Invited Date' },
  { key: 'completedDate', label: 'Completed Date' },
  { key: 'actions', label: 'Actions' },
];

const updateStatus = async (entry) => {
  try {
    if (!confirm(`Mark ${entry.name}'s assessment as completed?`)) return;

    entry.isLoading = true;
    await authStore.updateKurtzStatus(entry.email, 'completed');
    entry.status = 'completed';

    // Show success message
    alert('Status updated successfully');
  } catch (error) {
    console.error('Failed to update status:', error);
    alert('Failed to update status. Please try again.');
  } finally {
    entry.isLoading = false;
  }
};



// Filtering and sorting
const filteredData = computed(() => {
  return auditData.value
    .filter(entry => {
      const matchesSearch = searchQuery.value
        ? entry.name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        entry.email.toLowerCase().includes(searchQuery.value.toLowerCase())
        : true;

      const matchesStatus = statusFilter.value
        ? entry.status === statusFilter.value
        : true;

      const matchesDate = dateFilter.value
        ? new Date(entry.invitedDate).toLocaleDateString() === new Date(dateFilter.value).toLocaleDateString()
        : true;

      return matchesSearch && matchesStatus && matchesDate;
    })
    .sort((a, b) => {
      const modifier = sortDirection.value === 'asc' ? 1 : -1;
      return a[sortBy.value] < b[sortBy.value] ? -1 * modifier : 1 * modifier;
    });
});

// Pagination
const paginatedAndFilteredData = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage.value;
  const end = start + itemsPerPage.value;
  return filteredData.value.slice(start, end);
});

const totalPages = computed(() => Math.ceil(filteredData.value.length / itemsPerPage.value));
const paginationStart = computed(() => ((currentPage.value - 1) * itemsPerPage.value) + 1);
const paginationEnd = computed(() => Math.min(currentPage.value * itemsPerPage.value, filteredData.value.length));

// Sorting
const sort = (column) => {
  if (sortBy.value === column) {
    sortDirection.value = sortDirection.value === 'asc' ? 'desc' : 'asc';
  } else {
    sortBy.value = column;
    sortDirection.value = 'asc';
  }
};

// Reset filters
const resetFilters = () => {
  searchQuery.value = '';
  statusFilter.value = '';
  dateFilter.value = '';
  currentPage.value = 1;
};

const windowSize = 2; // Number of pages to show on each side of current page

const startPage = computed(() => {
  return Math.max(2, currentPage.value - windowSize);
});

const endPage = computed(() => {
  return Math.min(totalPages.value - 1, currentPage.value + windowSize);
});

const displayedPages = computed(() => {
  const pages = [];
  for (let i = startPage.value; i <= endPage.value; i++) {
    pages.push(i);
  }
  return pages;
});
</script>
