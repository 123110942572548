<template>
    <button @click="handleLogout"
        class="rounded-md bg-red-500 text-white px-3 py-2 text-sm font-medium hover:bg-red-600">
        Logout
    </button>
</template>

<script setup>
import { useInternalAuthStore } from '@/stores/internalAuth';
import { useRouter } from 'vue-router';

const authStore = useInternalAuthStore();
const router = useRouter();

const handleLogout = async () => {
    try {
        const success = await authStore.logout();
        if (success) {
            await router.push('/login');
        }
    } catch (error) {
        console.error('Logout failed:', error);
    }
};
</script>