<template>
  <section aria-labelledby="login-link-title">
    <div class="overflow-hidden rounded-lg bg-white shadow">
      <div class="p-6">
        <h2 class="text-base font-medium text-gray-900" id="login-link-title">
          Send login link
        </h2>
        <div class="mt-6">
          <p class="text-sm text-gray-500">
            Select a user from the list below to send a login link.
          </p>
        </div>
        <div class="mt-6 flow-root">
          <select v-model="selectedUser"
                  class="w-full p-2 border border-gray-300 rounded-lg">
            <option value="" disabled>Select User</option>
            <option v-for="user in users" 
                    :key="user.email" 
                    :value="user">
              {{ user.firstName }} {{ user.lastName }}
            </option>
          </select>
        </div>

        <div class="mt-6">
          <button type="button"
                  :disabled="!selectedUser"
                  @click="handleSendLink"
                  class="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed">
            Send Login Link
          </button>
          <div v-if="authStore.success" class="text-green-500 mt-4">
            {{ authStore.success }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue';
import { useInternalAuthStore } from '@/stores/internalAuth';

const props = defineProps(['users']);
const emit = defineEmits(['send-link']);
const authStore = useInternalAuthStore();
const selectedUser = ref(null);

const handleSendLink = () => {
  if (selectedUser.value) {
    emit('send-link', selectedUser.value.email);
    selectedUser.value = null;
  }
};
</script>
