<template>
  <div>
    <div class="mb-8 p-4 md:p-6 rounded-lg border border-gray-200 bg-white shadow-sm">
      <h3 class="text-lg font-medium text-gray-800 mb-4">Please Review Your Submission</h3>
      <p class="text-sm text-gray-600 mb-4">
        Before submitting your security assessment, please verify that all information provided is accurate
        and complete.
        Once submitted, changes may require contacting the security office.
      </p>

      <!-- Controls for expand/collapse all -->
      <div class="flex justify-end mb-4">
        <button 
          @click="toggleAllSections(true)" 
          class="text-sm text-blue-600 hover:text-blue-800 mr-4 flex items-center"
        >
          <i class="fas fa-expand-alt mr-1"></i> Expand All
        </button>
        <button 
          @click="toggleAllSections(false)" 
          class="text-sm text-blue-600 hover:text-blue-800 flex items-center"
        >
          <i class="fas fa-compress-alt mr-1"></i> Collapse All
        </button>
      </div>

      <!-- Summary sections -->
      <div class="space-y-4 mt-4">
        <!-- Application Type -->
        <review-section title="Application Information" :expanded="expandedSections.application" @toggle="toggleSection('application')">
          <div class="review-table">
            <div class="review-row">
              <div class="review-cell header">Application Type</div>
              <div class="review-cell data capitalize">{{ formData.applicationType || 'N/A' }}</div>
            </div>
            <div class="review-row">
              <div class="review-cell header">Preferred Language</div>
              <div class="review-cell data capitalize">{{ formData.preferredLanguage || 'N/A' }}</div>
            </div>
          </div>
        </review-section>
        
        <!-- Personal Information -->
        <review-section title="Personal Information" :expanded="expandedSections.personal" @toggle="toggleSection('personal')">
          <div class="review-table">
            <div class="review-row">
              <div class="review-cell header">Full Name</div>
              <div class="review-cell data">{{ formData.personal?.givenNames }} {{ formData.personal?.surname }}</div>
            </div>
            <div class="review-row">
              <div class="review-cell header">Gender</div>
              <div class="review-cell data capitalize">{{ formatGender(formData.personal?.gender) }}</div>
            </div>
            <div class="review-row">
              <div class="review-cell header">Date of Birth</div>
              <div class="review-cell data">{{ formatDate(formData.personal?.dateOfBirth) }}</div>
            </div>
            <div class="review-row">
              <div class="review-cell header">Marital Status</div>
              <div class="review-cell data capitalize">{{ formatMaritalStatus(formData.personal?.maritalStatus) }}</div>
            </div>
            <div class="review-row">
              <div class="review-cell header">Surname at Birth</div>
              <div class="review-cell data">{{ formData.personal?.surnameAtBirth || 'N/A' }}</div>
            </div>
            <div class="review-row">
              <div class="review-cell header">Other Names</div>
              <div class="review-cell data">{{ formData.personal?.otherNames || 'N/A' }}</div>
            </div>
          </div>
        </review-section>
        
        <!-- Contact Information -->
        <review-section title="Contact Information" :expanded="expandedSections.contact" @toggle="toggleSection('contact')">
          <div class="review-table">
            <div class="review-row">
              <div class="review-cell header">Mobile Phone</div>
              <div class="review-cell data">{{ formData.contact?.mobilePhone || 'N/A' }}</div>
            </div>
            <div class="review-row">
              <div class="review-cell header">Home Phone</div>
              <div class="review-cell data">{{ formData.contact?.homePhone || 'N/A' }}</div>
            </div>
            <div class="review-row">
              <div class="review-cell header">Business Phone</div>
              <div class="review-cell data">{{ formData.contact?.businessPhone || 'N/A' }}</div>
            </div>
            <div class="review-row">
              <div class="review-cell header">Personal Email</div>
              <div class="review-cell data">{{ formData.contact?.personalEmail || 'N/A' }}</div>
            </div>
            <div class="review-row">
              <div class="review-cell header">Business Email</div>
              <div class="review-cell data">{{ formData.contact?.businessEmail || 'N/A' }}</div>
            </div>
          </div>
        </review-section>
        
        <!-- Citizenship Information -->
        <review-section title="Citizenship Information" :expanded="expandedSections.citizenship" @toggle="toggleSection('citizenship')">
          <div class="review-table">
            <div class="review-row">
              <div class="review-cell header">Citizenships</div>
              <div class="review-cell data">{{ formatCitizenships(formData.citizenship?.citizenships) }}</div>
            </div>
            <div class="review-row">
              <div class="review-cell header">Country of Birth</div>
              <div class="review-cell data">{{ formData.citizenship?.countryOfBirth || 'N/A' }}</div>
            </div>
            <div class="review-row">
              <div class="review-cell header">City of Birth</div>
              <div class="review-cell data">{{ formData.citizenship?.cityOfBirth || 'N/A' }}</div>
            </div>
            <div class="review-row">
              <div class="review-cell header">Province of Birth</div>
              <div class="review-cell data">{{ formData.citizenship?.provinceOfBirth || 'N/A' }}</div>
            </div>
            <div class="review-row">
              <div class="review-cell header">Date of Entry to Canada</div>
              <div class="review-cell data">{{ formatDate(formData.citizenship?.dateOfEntry) }}</div>
            </div>
            <div class="review-row">
              <div class="review-cell header">Port of Entry</div>
              <div class="review-cell data">{{ formData.citizenship?.portOfEntry || 'N/A' }}</div>
            </div>
          </div>
          
          <!-- Foreign Passports -->
          <div v-if="formData.citizenship?.foreignPassports?.length" class="mt-4">
            <h5 class="font-medium text-gray-600 mb-3">Foreign Passports</h5>
            <div class="overflow-x-auto">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-100">
                  <tr>
                    <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Country</th>
                    <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Number</th>
                    <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name on Passport</th>
                    <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Issued</th>
                    <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Expires</th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="(passport, index) in formData.citizenship.foreignPassports" :key="index">
                    <td class="px-4 py-2 text-sm text-gray-900">{{ passport.country }}</td>
                    <td class="px-4 py-2 text-sm text-gray-900">{{ passport.number }}</td>
                    <td class="px-4 py-2 text-sm text-gray-900">{{ passport.nameOnPassport }}</td>
                    <td class="px-4 py-2 text-sm text-gray-900">{{ formatDate(passport.dateOfIssuance) }}</td>
                    <td class="px-4 py-2 text-sm text-gray-900">{{ formatDate(passport.expiryDate) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </review-section>
        
        <!-- Residential History -->
        <review-section title="Residential History" :expanded="expandedSections.residence" @toggle="toggleSection('residence')">
          <div v-if="formData.residentialHistory?.length" class="space-y-4">
            <div v-for="(residence, index) in formData.residentialHistory" :key="index" class="p-3 border border-gray-200 rounded-md">
              <div class="flex flex-col sm:flex-row justify-between mb-2">
                <h6 class="font-medium text-gray-900">{{ formatDateRange(residence.fromDate, residence.toDate, residence.isPresent) }}</h6>
                <span class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800" v-if="residence.isPresent">
                  Current
                </span>
              </div>
              <p class="text-sm text-gray-700">{{ formatResidentialAddress(residence) }}</p>
            </div>
          </div>
          <div v-else class="text-sm text-gray-500 p-4 bg-gray-50 rounded">No residential history provided</div>
        </review-section>
        
        <!-- Employment History -->
        <review-section title="Employment & Education History" :expanded="expandedSections.employment" @toggle="toggleSection('employment')">
          <div v-if="formData.employmentHistory?.length" class="space-y-4">
            <div 
              v-for="(employment, index) in formData.employmentHistory" 
              :key="index" 
              class="p-4 border border-gray-200 rounded-md"
              :class="{'bg-blue-50 border-blue-200': employment.isPresent, 'bg-gray-50': employment.type === 'unemployment'}"
            >
              <div class="flex flex-wrap justify-between mb-2">
                <h6 class="font-medium text-gray-900 capitalize">
                  <span 
                    class="inline-flex items-center mr-2 px-2.5 py-0.5 rounded-full text-xs font-medium" 
                    :class="{
                      'bg-green-100 text-green-800': employment.type === 'employment',
                      'bg-purple-100 text-purple-800': employment.type === 'education',
                      'bg-gray-100 text-gray-800': employment.type === 'unemployment'
                    }"
                  >
                    {{ employment.type || 'N/A' }}
                  </span>
                  {{ employment.type !== 'unemployment' ? employment.employer : 'Unemployment Period' }}
                </h6>
                <span class="text-sm text-gray-600">{{ formatDateRange(employment.fromDate, employment.toDate, employment.isPresent) }}</span>
              </div>
              
              <div v-if="employment.type !== 'unemployment'" class="mb-2">
                <div class="text-sm"><span class="font-medium">Position:</span> {{ employment.title }}</div>
                <div class="text-sm"><span class="font-medium">Address:</span> {{ formatAddress(employment.address) }}</div>
                <div v-if="employment.isRemote" class="text-sm text-gray-500 italic">Remote position</div>
              </div>
              
              <div v-if="employment.description" class="text-sm mt-2 bg-gray-50 p-2 rounded">
                <span class="font-medium">Description:</span> {{ employment.description }}
              </div>
            </div>
          </div>
          <div v-else class="text-sm text-gray-500 p-4 bg-gray-50 rounded">No employment history provided</div>
        </review-section>
        
        <!-- Criminal History -->
        <review-section title="Criminal History" :expanded="expandedSections.criminal" @toggle="toggleSection('criminal')">
          <div v-if="formData.criminalHistory?.hasConvictions === 'yes'" class="space-y-4">
            <div class="p-3 border border-gray-200 rounded-md">
              <h5 class="font-medium text-gray-700 mb-3">Convictions</h5>
              <div class="overflow-x-auto" v-if="formData.criminalHistory.convictions?.length">
                <table class="min-w-full divide-y divide-gray-200">
                  <thead class="bg-gray-100">
                    <tr>
                      <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Charges</th>
                      <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Police Service</th>
                      <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Location</th>
                      <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                      <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Sentence</th>
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-for="(conviction, index) in formData.criminalHistory.convictions" :key="index">
                      <td class="px-4 py-2 text-sm text-gray-900">{{ conviction.charges }}</td>
                      <td class="px-4 py-2 text-sm text-gray-900">{{ conviction.policeService }}</td>
                      <td class="px-4 py-2 text-sm text-gray-900">{{ formatCriminalLocation(conviction.location) }}</td>
                      <td class="px-4 py-2 text-sm text-gray-900">{{ formatYearMonth(conviction.date) }}</td>
                      <td class="px-4 py-2 text-sm text-gray-900">{{ conviction.sentence }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else class="text-sm text-gray-500">No convictions reported</div>
            </div>
            
            <div class="p-3 border border-gray-200 rounded-md">
              <h5 class="font-medium text-gray-700 mb-3">Detentions</h5>
              <div class="overflow-x-auto" v-if="formData.criminalHistory.detentions?.length">
                <table class="min-w-full divide-y divide-gray-200">
                  <thead class="bg-gray-100">
                    <tr>
                      <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Occurrence</th>
                      <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Details</th>
                      <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Location</th>
                      <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                      <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-for="(detention, index) in formData.criminalHistory.detentions" :key="index">
                      <td class="px-4 py-2 text-sm text-gray-900">{{ detention.occurrence }}</td>
                      <td class="px-4 py-2 text-sm text-gray-900">{{ detention.details }}</td>
                      <td class="px-4 py-2 text-sm text-gray-900">{{ formatCriminalLocation(detention.location) }}</td>
                      <td class="px-4 py-2 text-sm text-gray-900">{{ formatYearMonth(detention.date) }}</td>
                      <td class="px-4 py-2 text-sm text-gray-900">{{ detention.status }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else class="text-sm text-gray-500">No detentions reported</div>
            </div>
          </div>
          <div v-else class="flex items-center p-4 bg-green-50 rounded border border-green-200">
            <i class="fas fa-check-circle text-green-500 mr-2"></i>
            <span class="text-sm text-green-700">No criminal history reported</span>
          </div>
        </review-section>
        
        <!-- Travel History -->
        <review-section title="Travel History" :expanded="expandedSections.travel" @toggle="toggleSection('travel')">
          <div v-if="formData.travelHistory?.length" class="overflow-x-auto">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-100">
                <tr>
                  <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Country</th>
                  <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Period</th>
                  <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Duration</th>
                  <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Purpose</th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="(travel, index) in formData.travelHistory" :key="index">
                  <td class="px-4 py-2 text-sm text-gray-900">{{ travel.country }}</td>
                  <td class="px-4 py-2 text-sm text-gray-900">{{ formatDateRange(travel.fromDate, travel.toDate) }}</td>
                  <td class="px-4 py-2 text-sm text-gray-900">{{ travel.weeksSpent }} weeks</td>
                  <td class="px-4 py-2 text-sm text-gray-900 capitalize">{{ travel.purpose }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else class="text-sm text-gray-500 p-4 bg-gray-50 rounded">No travel history provided</div>
        </review-section>
        
        <!-- References -->
        <review-section title="References" :expanded="expandedSections.references" @toggle="toggleSection('references')">
          <div v-if="formData.references?.length" class="grid gap-4 grid-cols-1 md:grid-cols-2">
            <div 
              v-for="(reference, index) in formData.references" 
              :key="index" 
              class="p-4 border border-gray-200 rounded-md bg-gray-50"
            >
              <div class="flex justify-between items-start mb-2">
                <h6 class="font-medium text-gray-900">{{ reference.givenName }} {{ reference.surname }}</h6>
                <span 
                  class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize"
                  :class="{
                    'bg-blue-100 text-blue-800': reference.type === 'personal',
                    'bg-green-100 text-green-800': reference.type === 'professional'
                  }"
                >
                  {{ reference.type }}
                </span>
              </div>
              <div class="text-sm"><span class="font-medium">Relationship:</span> {{ reference.relationship }}</div>
              <div class="text-sm"><span class="font-medium">Known Since:</span> {{ formatYearMonth(reference.knownSince) }}</div>
              <div class="text-sm mt-2 flex flex-wrap">
                <span class="inline-flex items-center mr-3">
                  <i class="fas fa-phone text-gray-400 mr-1"></i> {{ reference.telephone }}
                </span>
                <span class="inline-flex items-center">
                  <i class="fas fa-envelope text-gray-400 mr-1"></i> {{ reference.email }}
                </span>
              </div>
            </div>
          </div>
          <div v-else class="text-sm text-gray-500 p-4 bg-gray-50 rounded">No references provided</div>
        </review-section>
        
        <!-- Security Clearance -->
        <review-section title="Security Clearance Information" :expanded="expandedSections.security" @toggle="toggleSection('security')">
          <div class="review-table">
            <div class="review-row">
              <div class="review-cell header">Clearance Level</div>
              <div class="review-cell data capitalize">{{ formData.securityClearance?.level || 'N/A' }}</div>
            </div>
            <div class="review-row">
              <div class="review-cell header">Issuing Country</div>
              <div class="review-cell data">{{ formData.securityClearance?.issuingCountry || 'N/A' }}</div>
            </div>
            <div class="review-row">
              <div class="review-cell header">Issuing Department</div>
              <div class="review-cell data">{{ formData.securityClearance?.issuingDepartment || 'N/A' }}</div>
            </div>
            <div class="review-row">
              <div class="review-cell header">Expiry Date</div>
              <div class="review-cell data">{{ formatDate(formData.securityClearance?.expiryDate) }}</div>
            </div>
          </div>
        </review-section>
        
        <!-- Statements -->
        <review-section title="Declaration Statements" :expanded="expandedSections.statements" @toggle="toggleSection('statements')">
          <div class="space-y-3">
            <div v-for="(statement, key) in filteredStatements" :key="key" 
                class="p-3 rounded-md border" 
                :class="statement.value ? 'border-amber-300 bg-amber-50' : 'border-green-300 bg-green-50'">
              <div class="flex items-start">
                <div class="mr-3 mt-1">
                  <i class="fas" :class="statement.value ? 'fa-exclamation-circle text-amber-500' : 'fa-check-circle text-green-500'"></i>
                </div>
                <div class="flex-1">
                  <div class="font-medium text-gray-900 mb-1">{{ statement.label }}</div>
                  <div class="text-sm">
                    <span class="font-medium">Response:</span> 
                    <span :class="statement.value ? 'text-amber-700' : 'text-green-700'">{{ statement.value ? 'Yes' : 'No' }}</span>
                  </div>
                  <div v-if="statement.value === true" class="text-sm bg-white p-2 rounded mt-1 border border-amber-200">
                    <span class="font-medium">Details:</span> {{ statement.details || 'N/A' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </review-section>
        
        <!-- Uploaded Documents -->
<review-section title="Uploaded Documents" :expanded="expandedSections.documents" @toggle="toggleSection('documents')">
  <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
    <document-summary
      title="Identification Documents"
      :documents="enhanceDocuments(formData.supportingDocuments?.identificationDocuments)"
      icon="fa-id-card"
    />
    
    <document-summary
      title="Address Proof"
      :documents="enhanceDocuments(formData.supportingDocuments?.addressProof)"
      icon="fa-home"
    />
    
    <document-summary
      title="Employment Documents"
      :documents="enhanceDocuments(formData.supportingDocuments?.employmentDocuments)"
      icon="fa-briefcase"
    />
    
    <document-summary
      title="Education Documents"
      :documents="enhanceDocuments(formData.supportingDocuments?.educationDocuments)"
      icon="fa-graduation-cap"
    />
    
    <document-summary
      title="Security Clearance Documents"
      :documents="enhanceDocuments(formData.supportingDocuments?.securityClearanceDocuments)"
      icon="fa-shield-alt"
    />
    
    <document-summary
      title="Criminal Record Check"
      :documents="enhanceDocuments(formData.supportingDocuments?.criminalRecordCheck)"
      icon="fa-file-alt"
    />
    
    <document-summary
      title="Work Permit"
      :documents="enhanceDocuments(formData.supportingDocuments?.workPermit)"
      icon="fa-file-contract"
    />
    
    <document-summary
      title="Additional Documents"
      :documents="enhanceDocuments(formData.supportingDocuments?.additionalDocuments)"
      icon="fa-file"
    />
  </div>
</review-section>

      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed, ref } from 'vue';
import ReviewSection from './ReviewSection.vue';
import DocumentSummary from './DocumentSummary.vue';

const props = defineProps({
  formData: {
    type: Object,
    required: true
  }
});

// Set up section expansion state
const expandedSections = ref({
  application: true,
  personal: true,
  contact: true,
  citizenship: true,
  residence: true,
  employment: true,
  criminal: true,
  travel: true,
  references: true,
  security: true,
  statements: true,
  documents: true,
  consent: true
});

// Toggle individual section
const toggleSection = (section) => {
  expandedSections.value[section] = !expandedSections.value[section];
};

// Toggle all sections at once
const toggleAllSections = (isExpanded) => {
  Object.keys(expandedSections.value).forEach(key => {
    expandedSections.value[key] = isExpanded;
  });
};

// Format address object into a readable string
const formatAddress = (address) => {
  if (!address) return 'N/A';
  
  let parts = [];
  if (address.streetNumber) parts.push(address.streetNumber);
  if (address.streetName) parts.push(address.streetName);
  if (address.city) parts.push(address.city);
  if (address.state || address.province) parts.push(address.state || address.province);
  if (address.postalCode) parts.push(address.postalCode);
  if (address.country) parts.push(address.country);
  
  return parts.join(', ');
};

// Format residential address
const formatResidentialAddress = (residence) => {
  if (!residence) return 'N/A';
  
  let parts = [];
  if (residence.apartmentNumber) parts.push(`Apt ${residence.apartmentNumber}`);
  if (residence.streetNumber) parts.push(residence.streetNumber);
  if (residence.streetName) parts.push(residence.streetName);
  if (residence.city) parts.push(residence.city);
  if (residence.provinceTerritory) parts.push(residence.provinceTerritory);
  if (residence.country) parts.push(residence.country);
  if (residence.postalCode) parts.push(residence.postalCode);
  
  return parts.join(', ');
};

// Format criminal location
const formatCriminalLocation = (location) => {
  if (!location) return 'N/A';
  
  let parts = [];
  if (location.city) parts.push(location.city);
  if (location.province) parts.push(location.province);
  if (location.country) parts.push(location.country);
  
  return parts.join(', ');
};

// Format date
const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  
  try {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-CA', { year: 'numeric', month: 'long', day: 'numeric' });
  } catch {
    return dateString;
  }
};

// Format year-month only
const formatYearMonth = (dateString) => {
  if (!dateString) return 'N/A';
  
  try {
    const [year, month] = dateString.split('-');
    const date = new Date(year, month - 1);
    return date.toLocaleDateString('en-CA', { year: 'numeric', month: 'long' });
  } catch {
    return dateString;
  }
};

// Format date range
const formatDateRange = (fromDate, toDate, isPresent = false) => {
  const formattedFromDate = formatYearMonth(fromDate) || 'N/A';
  if (isPresent) {
    return `${formattedFromDate} - Present`;
  }
  const formattedToDate = formatYearMonth(toDate) || 'N/A';
  return `${formattedFromDate} - ${formattedToDate}`;
};

// Format gender
const formatGender = (gender) => {
  if (!gender) return 'N/A';
  
  const genderMap = {
    male: 'Male',
    female: 'Female',
    another_gender: 'Other',
    prefer_not_to_say: 'Prefer not to say'
  };
  
  return genderMap[gender] || gender;
};

// Format marital status
const formatMaritalStatus = (status) => {
  if (!status) return 'N/A';
  
  const statusMap = {
    single: 'Single',
    married: 'Married',
    common_law: 'Common Law',
    divorced: 'Divorced',
    separated: 'Separated',
    widowed: 'Widowed'
  };
  
  return statusMap[status] || status;
};

// Format citizenships
const formatCitizenships = (citizenships) => {
  if (!citizenships || !citizenships.length) return 'N/A';
  
  return citizenships.map(c => c.country).join(', ');
};

// Get document count as a string
const getDocumentCount = (documents) => {
  if (!documents || !Array.isArray(documents)) return 'None';
  return documents.length > 0 ? `${documents.length} document(s)` : 'None';
};

// Filter statements based on application type
const filteredStatements = computed(() => {
  const applicationType = props.formData.applicationType;
  const statements = props.formData.statements || {};
  
  if (!statements || typeof statements !== 'object') {
    return {};
  }
  
  if (applicationType === 'canadian') {
    return Object.fromEntries(
      Object.entries(statements).filter(([key, statement]) => {
        return statement && 
               statement.applyTo && 
               Array.isArray(statement.applyTo) && 
               statement.applyTo.includes('canadian');
      })
    );
  } else if (applicationType === 'international') {
    return Object.fromEntries(
      Object.entries(statements).filter(([key, statement]) => {
        return statement && 
               statement.applyTo && 
               Array.isArray(statement.applyTo) && 
               statement.applyTo.includes('international');
      })
    );
  } else {
    return statements;
  }
});

// Enhance documents with additional properties needed for display and download
const enhanceDocuments = (documents) => {
  if (!documents || !Array.isArray(documents)) {
    return [];
  }
  
  return documents.map((doc, index) => {
    // Make sure we have a name for the document
    const docName = doc.name || doc.fileName || `Document ${index + 1}`;
    
    // Set up download URL (placeholder - replace with actual API endpoint)
    const downloadUrl = doc.url || doc.downloadUrl || `/api/documents/${doc.id}/download`;
    
    // Create enhanced document object
    return {
      ...doc,
      name: docName,
      downloadUrl: downloadUrl,
      dateUploaded: doc.dateUploaded || doc.uploadDate || new Date().toISOString()
    };
  });
};
</script>

<style scoped>
.review-table {
  display: table;
  width: 100%;
  border-collapse: collapse;
}

.review-row {
  display: table-row;
}

.review-row:nth-child(even) {
  background-color: rgba(243, 244, 246, 0.5);
}

.review-cell {
  display: table-cell;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #e5e7eb;
}

.review-cell.header {
  font-weight: 500;
  color: #4b5563;
  width: 30%;
}

.review-cell.data {
  color: #1f2937;
}

@media (max-width: 768px) {
  .review-table {
    display: block;
  }
  
  .review-row {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #e5e7eb;
    padding: 0.5rem 0;
  }
  
  .review-cell {
    display: block;
    padding: 0.25rem 1rem;
    border-bottom: none;
  }
  
  .review-cell.header {
    font-weight: 600;
    padding-bottom: 0;
  }
}
</style>