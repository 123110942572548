<template>
  <div class="hidden lg:block w-64 bg-white border-r border-gray-200 overflow-y-auto h-screen sticky top-0">
    <nav class="px-4 py-6">
      <ul class="space-y-2">
        <li v-for="(section, index) in sections" :key="section.id" @click="navigateToStep(index)">
          <button 
            :class="[
              'w-full text-left px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200',
              hasError(section.id)
                ? 'bg-red-100 text-red-800 border-2 border-red-500 shadow-sm animate-shake'
                : currentStep === index
                  ? 'bg-green-50 text-green-700'
                  : 'text-gray-600 hover:bg-gray-50',
            ]"
          >
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <span :class="[
                  'w-6 h-6 flex items-center justify-center rounded-full mr-2 text-xs font-bold shadow-sm',
                  currentStep > index
                    ? 'bg-green-100 text-green-700'
                    : currentStep === index
                      ? 'bg-green-600 text-white'
                      : hasError(section.id)
                        ? 'bg-red-600 text-white'
                        : 'bg-gray-100 text-gray-500',
                ]">
                  {{ index + 1 }}
                </span>
                {{ section.title }}
              </div>
              <div v-if="hasError(section.id)" class="flex items-center bg-red-50 px-2 py-1 rounded-full">
                <svg class="h-4 w-4 text-red-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                </svg>
                <span class="ml-1 text-xs font-semibold text-red-700">Error</span>
              </div>
            </div>
          </button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script setup>
defineProps({
  sections: {
    type: Array,
    required: true
  },
  currentStep: {
    type: Number,
    required: true
  },
  hasError: {
    type: Function,
    required: true
  }
});

const emit = defineEmits(['update:currentStep']);

const navigateToStep = (index) => {
  emit('update:currentStep', index);
};
</script>

<style>
@keyframes shake {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-4px); }
  75% { transform: translateX(4px); }
}

.animate-shake {
  animation: shake 0.5s ease-in-out;
}
</style>
