<template>
  <div class="p-4 bg-white rounded-lg shadow-sm border border-gray-200">
    <div class="flex items-center mb-3">
      <div class="rounded-full p-2 bg-blue-100 text-blue-600 mr-3">
        <i :class="`fas ${icon}`"></i>
      </div>
      <h3 class="font-medium text-gray-800">{{ title }}</h3>
      <span class="ml-auto text-sm text-gray-500">{{ documents.length || 0 }} document(s)</span>
    </div>

    <div v-if="documents && documents.length > 0" class="space-y-2">
      <div v-for="(doc, index) in documents" :key="index" 
           class="p-2 bg-gray-50 rounded flex items-center justify-between text-sm border border-gray-200">
        <div class="flex items-center">
          <i class="fas fa-file-alt text-gray-400 mr-2"></i>
          <span class="text-gray-700 truncate" :title="doc.name">{{ doc.name }}</span>
        </div>
        <div class="flex items-center">
          <span class="text-xs text-gray-500 mr-3">
            {{ formatDate(doc.dateUploaded) }}
          </span>
          <button 
            @click="downloadDocument(doc)" 
            class="text-blue-600 hover:text-blue-800 flex items-center"
            title="Download document">
            <i class="fas fa-download"></i>
          </button>
        </div>
      </div>
    </div>
    
    <div v-else class="text-center py-4 text-sm text-gray-500">
      No documents uploaded
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';
import { useSecurityAssessmentStore } from '../../../stores/securityAssessment';

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  documents: {
    type: Array,
    default: () => []
  },
  icon: {
    type: String,
    default: 'fa-file'
  }
});

const securityStore = useSecurityAssessmentStore();

function formatDate(dateString) {
  if (!dateString) return '';
  
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', { 
    year: 'numeric', 
    month: 'short', 
    day: 'numeric' 
  });
}

async function downloadDocument(doc) {
  try {
    if (!doc.downloadUrl) {
      console.error('No download URL available for this document');
      securityStore.addError('Download URL not available for this document');
      return;
    }
    
    // Create a temporary anchor to trigger the download
    const link = document.createElement('a');
    link.href = doc.downloadUrl;
    link.setAttribute('download', doc.name || 'document');
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error downloading document:', error);
    securityStore.addError('Failed to download document. Please try again.');
  }
}
</script>