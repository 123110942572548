<template>
  <div v-if="!authStore.isAuthenticated" class="text-center p-8">
    <h2 class="text-2xl font-bold mb-4">Authentication Required</h2>
    <p class="mb-4">Please log in to access the Communication Exercise Portal.</p>
    <router-link to="/" class="mt-4 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
      Go to Login
    </router-link>
  </div>

  <div v-else>
    <!-- Your existing template code -->
    <div class="overflow-hidden rounded-lg bg-white shadow mt-20">
      <h2 class="sr-only" id="profile-overview-title">Profile Overview</h2>
      <div class="bg-blue-700/80 text-white p-6">
        <div class="sm:flex sm:items-center sm:justify-between">
          <div class="sm:flex sm:space-x-5">
            <div class="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
              <p class="text-sm font-medium">
                Welcome to the Communication Exercise Portal!
              </p>
              <p class="capitalize text-xl font-bold sm:text-2xl">
                {{ `${user.firstName} ${user.lastName}` }}
              </p>

              <div v-if="step === undefined">
                <input type="email" v-model="email"
                  class="mt-4 text-gray-800 w-full px-4 py-2 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300"
                  placeholder="Enter your email to start" :class="{ 'border-red-500': emailError }" />
                <p v-if="emailError" class="mt-1 text-red-300 text-sm">
                  {{ emailError }}
                </p>
                <button @click="startNow"
                  class="mt-4 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 disabled:opacity-50 disabled:cursor-not-allowed"
                  :disabled="!email">
                  Continue
                </button>
              </div>
              <p class="mt-2 text-sm text-gray-300" v-else>
                {{ email }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4 max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md" v-if="step !== undefined">
      <Dictionary />

      <div v-if="step === 0" class="flex flex-col items-center text-center p-8">
        <div class="max-w-4xl mx-auto p-6 bg-white text-start">
          <h1 class="text-3xl font-bold mb-6 text-center">Index Instructions</h1>
          <p class="text-sm text-gray-600 mb-8 leading-6">
            The following index is designed to give us insights into your communication
            style, how you process information, and your overall cultural fit within the
            organization. It’s natural to feel a bit anxious before taking the index, but
            rest assured—there’s nothing to worry about! This is not a personality "test"
            to reveal hidden traits. The sole purpose of the index is to provide useful
            information that can ultimately help make your job and interactions with
            colleagues smoother and more enjoyable.
          </p>
          <p class="text-sm text-gray-600 mb-8 leading-6">
            You will be guided through 6 pages of questionnaires:
          </p>
          <ul class="list-disc list-inside text-sm text-start text-gray-600 mb-8 leading-6">
            <li>Each section includes its own set of instructions.</li>
            <li>
              This is a timed exercise. You will have 10 minutes to complete each section.
            </li>
            <li>
              Answer authentically—there are no wrong answers. Try to avoid selecting
              responses you think might sound best.
            </li>
            <li>
              A built-in dictionary function is available for your convenience if you need
              help understanding any words.
            </li>
          </ul>
          <p class="text-sm text-gray-600 mb-8 leading-6">
            Once you’ve completed your assessment, our team will review all the
            information to determine the best fit for our available positions. We’ll
            utilize every tool at our disposal to make well-informed decisions about the
            candidates we believe are best positioned for success within our company.
          </p>
          <p class="text-sm text-gray-600 mb-8 leading-6">
            Please allow us up to two weeks for your next update (but we’ll try to be
            faster😉).
          </p>
        </div>
        <button @click="startIndex" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
          Start Now
        </button>
      </div>

      <div v-if="step > 0" class="mb-4">
        <div class="w-full bg-gray-300 rounded-full h-2.5">
          <div class="bg-blue-500 h-2.5 rounded-full" :style="{ width: progressPercentage + '%' }"></div>
        </div>
      </div>

      <div v-if="step > 0" class="kurtz-kit-container">
        <component :is="currentStepComponent" @valid-submit="handleNextStep" ref="currentComponentRef" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { useInternalAuthStore } from "@/stores/internalAuth";
import Descriptors from "@/components/Kurtz/DescriptorsSelection.vue";
import AdjectiveChecklist from "@/components/Kurtz/AdjectiveChecklist.vue";
import ColorRanking from "@/components/Kurtz/ColorSheetRanking.vue";
import WordSquare from "@/components/Kurtz/WordSquare.vue";
import Dictionary from "@/components/Dictionary.vue";
import OrganizationalValues from "@/components/Kurtz/OrganizationalValues.vue";
import Qualities from "@/components/Kurtz/Qualities.vue";

const authStore = useInternalAuthStore();
const router = useRouter();
const step = ref(undefined);
const elapsedTime = ref(0);
const email = ref("");
const emailError = ref("");

const communicationExercisesCompleted = computed(() => {
  return authStore.user?.communicationExercisesCompleted === true;
});

// Updated user computed property to match internalAuth store structure
const user = computed(() => ({
  firstName: authStore.user?.firstName || "N/A",
  lastName: authStore.user?.lastName || "N/A",
  email: authStore.user?.email || "N/A",
  role: authStore.user?.role || "N/A"
}));

const components = [
  Descriptors,
  AdjectiveChecklist,
  ColorRanking,
  WordSquare,
  Qualities,
  OrganizationalValues,
];

const currentStepComponent = computed(() => components[step.value - 1]);
const progressPercentage = computed(() => (step.value / components.length) * 100);

let timerInterval;

const startTimer = () => {
  clearInterval(timerInterval);
  timerInterval = setInterval(() => {
    elapsedTime.value += 1;
  }, 1000);
};

const resetTimer = () => {
  elapsedTime.value = 0;
  clearInterval(timerInterval);
  startTimer();
};

const startIndex = () => {
  step.value = 1;
  resetTimer();
};

const handleNextStep = async () => {
  console.log("Handling next step");
  const currentComponent = currentComponentRef.value;

  if (currentComponent?.validateAndSubmit) {
    try {
      await currentComponent.validateAndSubmit();

      if (step.value < components.length) {
        step.value += 1;
        resetTimer();
      } else {
        completeKit();
      }
    } catch (error) {
      console.error("Validation failed:", error);
      const componentNames = {
        DescriptorsSelection: 'descriptorsselection',
        AdjectiveChecklist: 'adjectivechecklist',
        ColorRanking: 'colorranking',
        WordSquare: 'wordsquare',
        Qualities: 'qualities',
        OrganizationalValues: 'organizationalvalues'
      };

      const componentName = componentNames[currentStepComponent.value.name];
      if (componentName && authStore.kurtzResults?.errorTracking?.[componentName] !== undefined) {
        authStore.kurtzResults.errorTracking[componentName]++;
      }
      return;
    }
  } else {
    if (step.value < components.length) {
      step.value += 1;
      resetTimer();
    } else {
      completeKit();
    }
  }
};

const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const startNow = () => {
  // Initialize kurtzResults with user's full name
  const fullName = `${user.value.firstName} ${user.value.lastName}`;
  
  authStore.kurtzResults = {
    descriptors: [],
    adjectives: [],
    colorSheets: [],
    wordSquare: { value: null, totals: null },
    organizationalValues: { totalScore: 0, questions: [] },
    qualities: [],
    name: fullName, // Add name here
    errorTracking: {},
    attempts: 0
  };

  step.value = 0;
  console.log("Initialized Kurtz results with name:", fullName, authStore.kurtzResults);
};

const currentComponentRef = ref(null);

const checkPreviousCompletion = async () => {
  try {
    await authStore.fetchKurtzResults();
    if (communicationExercisesCompleted.value) {
      console.log(`Starting attempt #${authStore.kurtzResults?.length + 1 || 1}`);
    }
  } catch (error) {
    console.error('Error checking previous completion:', error);
  }
};

onMounted(async () => {
  startNow(); // Initialize immediately with the user's name
  await checkPreviousCompletion();
  console.log(user.value);
});

const completeKit = async () => {
  try {
    // Make sure we still have the name in the final submission
    const fullName = `${user.value.firstName} ${user.value.lastName}`;
    const finalResults = {
      ...authStore.kurtzResults,
      name: fullName, // Ensure name is set here
      elapsedTime: elapsedTime.value
    };
    
    console.log('Submitting final Kurtz results:', finalResults);
    await authStore.submitKurtzResults(finalResults);
    router.push("/kurtz/submission");
  } catch (error) {
    console.error("Failed to save Kurtz Kit results:", error);
    alert("Failed to save results. Please try again.");
  }
};

onUnmounted(() => {
  clearInterval(timerInterval);
});
</script>
