// src/stores/securityAssessment.js
import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { axiosInstance } from '@/lib/axios';
import { getDefaultFormData } from '@/utils/formDefaults';

/**
 * Security Assessment store to centralize all security assessment functionality.
 * This provides a clean interface for managing state, saving drafts, and submitting forms.
 * 
 */
export const useSecurityAssessmentStore = defineStore('securityAssessment', () => {
  // State
  const formData = ref(getDefaultFormData());
  const errors = ref([]);
  const fieldErrors = ref({});
  const isLoading = ref(false);
  const isSaving = ref(false);
  const isSubmitting = ref(false);
  const currentStep = ref(0);
  const showRecoveryDialog = ref(false);
  const savedFormDate = ref(null);
  const showSubmitConfirmation = ref(false);
  const lastSaved = ref(null);
  const submissionStatus = ref('draft');
  const isSavingNow = ref(false); // Add new state for manual save button

  // Additional state for submissions and assessment details
  const submissions = ref([]);
  const currentAssessment = ref(null);
  const isExporting = ref(false);
  const isUploading = ref(false);

  // Additional state for file uploads
  const uploadProgress = ref({});
  const uploadStatus = ref({});
  const fileErrors = ref({});

  // Computed properties
  const completionPercentage = computed(() => {
    const totalFields = Object.keys(formData.value).length;
    const completedFields = Object.entries(formData.value).filter(([key, value]) => {
      if (Array.isArray(value)) return value.length > 0;
      if (typeof value === 'object') return Object.keys(value).length > 0;
      return !!value;
    }).length;
    return Math.round((completedFields / totalFields) * 100);
  });

  /**
   * Checks if there's a saved form on the server for the current user
   */
  async function checkForSavedForm() {

    try {
      const draftResponse = await axiosInstance.get('/auth/security-assessment/load-draft');
      console.log('Draft response:', draftResponse.data.submissionStatus);
      if (draftResponse.data.status === 'ok') {
        savedFormDate.value = new Date(draftResponse.data.lastSaved);
        showRecoveryDialog.value = true;
        submissionStatus.value = draftResponse.data.submissionStatus;
        return true;
      }
    } catch (error) {
      console.error("Error checking for saved form:", error);
      // Handle authentication errors specifically
      if (error.response?.status === 401) {
        errors.value.push("You must be logged in to check for saved drafts");
      } else {
        errors.value.push(error.response?.data?.error || "Failed to check for saved form");
      }
    }
    return false;
  }

  /**
   * Saves the form draft to the server for the current user
   */
  async function saveServerDraft() {
    isSaving.value = true;
    try {
      console.log('Saving draft with formData:', formData.value);
      if (!formData.value) {
        throw new Error('Form data is null or undefined');
      }

      // The auth header will include the user's token for identification
      const response = await axiosInstance.post('/auth/security-assessment/draft', {
        formData: formData.value
      });

      console.log('Server response:', response.data);

      if (response.data.status === 'ok') {
        lastSaved.value = new Date();
        return true;
      }
      throw new Error(response.data.error || 'Failed to save draft');
    } catch (error) {
      console.error("Failed to save draft to server:", error);
      // Handle authentication errors specifically
      if (error.response?.status === 401) {
        errors.value.push("You must be logged in to save drafts");
      } else {
        errors.value.push(error.message);
      }
      throw error;
    } finally {
      isSaving.value = false;
    }
  }

  /**
   * Loads server draft for the current user if available
   */
  async function loadDraft() {
    // Only load from server if explicitly requested
    isLoading.value = true;
    try {
      // The auth header will include the user's token for identification
      const response = await axiosInstance.get('/auth/security-assessment/load-draft');
      console.log('Load draft response:', response.data);
      if (response.data.status === 'ok') {
        formData.value = JSON.parse(response.data.draft);
        lastSaved.value = new Date(response.data.timestamp || response.data.lastSaved);


        return true;
      }
      return false;
    } catch (error) {
      console.error("Failed to load draft from server:", error);
      // Handle authentication errors specifically
      if (error.response?.status === 401) {
        errors.value.push("You must be logged in to access your saved drafts");
      } else {
        errors.value.push(error.response?.data?.error || "Failed to load draft from server");
      }
      return false;
    } finally {
      isLoading.value = false;
    }
  }

  /**
   * Start fresh with a new form
   */
  function startFresh() {
    // Close the dialog
    showRecoveryDialog.value = false;
    // Reset form to default state
    formData.value = getDefaultFormData();
    // Reset other state
    currentStep.value = 0;
    errors.value = [];
    fieldErrors.value = {};
    lastSaved.value = null;
    savedFormDate.value = null;
  }

  /**
   * Recover a previously saved form from server for the current user
   */
  async function recoverSavedForm() {
    try {
      isLoading.value = true;
      // Close the dialog
      showRecoveryDialog.value = false;

      // Load draft from server using authenticated request
      const response = await axiosInstance.get('/auth/security-assessment/load-draft');
      if (response.data.status === 'ok') {
        formData.value = JSON.parse(response.data.draft);
        lastSaved.value = new Date(response.data.timestamp || response.data.lastSaved);
        return true;
      }
      return false;
    } catch (error) {
      console.error("Error recovering saved form:", error);
      // Handle authentication errors specifically
      if (error.response?.status === 401) {
        errors.value.push("You must be logged in to recover your saved drafts");
      } else {
        errors.value.push("Failed to recover saved form data");
      }
      return false;
    } finally {
      isLoading.value = false;
    }
  }

  /**
   * Submit the security assessment form
   */
  async function submitForm() {
    isSubmitting.value = true;
    errors.value = [];

    try {
      const response = await axiosInstance.post('/auth/security-assessment/submit', {
        formData: formData.value
      });

      if (response.data.status === 'ok') {
        // Reset form on successful submission
        formData.value = getDefaultFormData();
        currentStep.value = 0;
        return true;
      }
      throw new Error(response.data.error || 'Failed to submit form');
    } catch (error) {
      errors.value.push(error.response?.data?.error || "An unexpected error occurred. Please try again later.");
      throw error;
    } finally {
      isSubmitting.value = false;
    }
  }

  /**
   * Move to the next form step
   */
  function nextStep() {
    console.log("Current step:", currentStep.value);
    if (currentStep.value < 15) { // Assuming 15 total steps
      currentStep.value++;
      console.log("Next step:", currentStep.value);
      saveServerDraft(); // Auto-save when moving to next step
    }
  }

  /**
   * Move to the previous form step
   */
  function prevStep() {
    if (currentStep.value > 0) {
      currentStep.value--;
    }
  }

  /**
   * Set a specific form step
   */
  function setStep(step) {
    if (step >= 0 && step < 15) { // Assuming 15 total steps
      currentStep.value = step;
    }
  }

  /**
   * Add validation error
   */
  function addError(error) {
    errors.value.push(error);
  }

  /**
   * Clear all validation errors
   */
  function clearErrors() {
    errors.value = [];
    fieldErrors.value = {};
  }

  /**
   * Set a field error
   */
  function setFieldError(field, hasError) {
    fieldErrors.value[field] = hasError;
  }

  /**
   * Update form data fields
   */
  function updateFormData(path, value) {
    const keys = path.split('.');
    let current = formData.value;

    // Navigate to the nested property
    for (let i = 0; i < keys.length - 1; i++) {
      const key = keys[i];
      // Handle array access if the key contains brackets (e.g., 'references[0]')
      if (key.includes('[')) {
        const [arrayName, indexStr] = key.split('[');
        const index = parseInt(indexStr.replace(']', ''));
        current = current[arrayName][index];
      } else {
        current = current[key];
      }
    }

    // Set the value
    const lastKey = keys[keys.length - 1];
    current[lastKey] = value;
  }

  /**
   * Saves the current form data immediately to the server for the current user
   */
  async function saveNow() {

    if (isSavingNow.value) return; // Prevent multiple simultaneous saves

    isSavingNow.value = true;
    errors.value = [];

    try {

      // The auth header will include the user's token for identification
      const response = await axiosInstance.post('/auth/security-assessment/draft', {
        formData: formData.value
      });
      console.log('Server response:', response.data);

      if (response.data.status === 'ok') {
        lastSaved.value = new Date();
        return true;
      }
      throw new Error(response.data.error || 'Failed to save draft');
    } catch (error) {
      // Handle authentication errors specifically
      if (error.response?.status === 401) {
        errors.value.push("You must be logged in to save drafts");
      } else {
        errors.value.push(error.response?.data?.error || 'Failed to save draft');
      }
      throw error;
    } finally {
      isSavingNow.value = false;
    }
  }

  /**
   * Retrieves the list of all security assessment submissions
   */
  async function getSubmissions() {
    isLoading.value = true;
    errors.value = [];

    try {
      const response = await axiosInstance.get('/auth/security-assessment/submissions');

      if (response.data.status === 'ok') {
        submissions.value = response.data.submissions;
        return submissions.value;
      }
      throw new Error(response.data.error || 'Failed to fetch submissions');
    } catch (error) {
      errors.value.push(error.response?.data?.error || 'Failed to fetch submissions');
      throw error;
    } finally {
      isLoading.value = false;
    }
  }

  /**
   * Retrieves a specific security assessment by ID
   */
  async function getAssessmentById(id) {
    isLoading.value = true;
    errors.value = [];

    try {
      const response = await axiosInstance.get(`/auth/security-assessment/${id}`);

      if (response.data.status === 'ok') {
        currentAssessment.value = response.data.assessment;
        return currentAssessment.value;
      }
      throw new Error(response.data.error || 'Failed to fetch assessment');
    } catch (error) {
      errors.value.push(error.response?.data?.error || 'Failed to fetch assessment');
      throw error;
    } finally {
      isLoading.value = false;
    }
  }

  /**
   * Exports a security assessment as PDF
   */
  async function exportToPdf(id) {
    isExporting.value = true;
    errors.value = [];

    try {
      const response = await axiosInstance.get(`/auth/security-assessment/${id}/pdf`, {
        responseType: 'blob'
      });

      // Create a blob link to download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `security-assessment-${id}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      return true;
    } catch (error) {
      errors.value.push('Failed to export assessment as PDF');
      throw error;
    } finally {
      isExporting.value = false;
    }
  }

  /**
   * Reviews a security assessment (admin only)
   */
  async function reviewAssessment(id, reviewData) {
    isLoading.value = true;
    errors.value = [];

    try {
      const response = await axiosInstance.post(`/auth/security-assessment/${id}/review`, reviewData);

      if (response.data.status === 'ok') {
        return response.data;
      }
      throw new Error(response.data.error || 'Failed to submit review');
    } catch (error) {
      errors.value.push(error.response?.data?.error || 'Failed to submit review');
      throw error;
    } finally {
      isLoading.value = false;
    }
  }

  /**
   * Track upload progress for multiple files
   */
  function updateUploadProgress(fileId, progress) {
    uploadProgress.value[fileId] = progress;
  }

  /**
   * Track upload status for files
   */
  function updateUploadStatus(fileId, status) {
    uploadStatus.value[fileId] = status;
  }

  /**
   * Uploads a document for a specific assessment
   */
  async function uploadDocument(id, documentData) {
    const fileId = `${Date.now()}-${documentData.file.name}`;
    updateUploadStatus(fileId, 'uploading');
    updateUploadProgress(fileId, 0);

    try {
      const formData = new FormData();
      formData.append('file', documentData.file);
      formData.append('type', documentData.type);
      formData.append('description', documentData.description || '');

      const response = await axiosInstance.post(
        'auth/files/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            updateUploadProgress(fileId, percentCompleted);
          }
        }
      );

      if (response.data.status === 'error' || !response.data.file) {
        throw new Error(response.data.error || 'Upload failed');
      }

      updateUploadStatus(fileId, 'completed');
      return {
        fileId: response.data.file.id,
        url: response.data.file.url,
        path: response.data.file.path,
        name: documentData.file.name,
        type: documentData.type
      };
    } catch (error) {
      console.error('Upload failed:', error);
      updateUploadStatus(fileId, 'error');
      fileErrors.value[fileId] = error.response?.data?.error || error.message || 'Upload failed';
      throw error;
    }
  }

  /**
   * Gets a document and returns the blob
   */
  async function getDocument(assessmentId, documentId) {
    errors.value = [];

    try {
      const response = await axiosInstance.get(
        `/auth/security-assessment/${assessmentId}/documents/${documentId}`,
        {
          responseType: 'blob'
        }
      );

      // Create and return a blob from the response data
      return new Blob([response.data], {
        type: response.headers['content-type'] || 'application/octet-stream'
      });

    } catch (error) {
      errors.value.push(error.response?.data?.error || 'Failed to retrieve document');
      throw error;
    }
  }

  /**
 * Downloads a document to the user's device
 */
  async function downloadDocument(assessmentId, documentId, fileName) {
    try {
      const blob = await getDocument(assessmentId, documentId);

      // Create a download link and trigger it
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName || 'document');
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      return true;
    } catch (error) {
      console.error('Error downloading document:', error);
      errors.value.push('Unable to download document');
      return false;
    }
  }

  /**
   * Opens a document in a new browser tab
   */
  async function openDocument(assessmentId, documentId) {
    try {
      const blob = await getDocument(assessmentId, documentId);
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank');

      // Clean up the URL after a delay to ensure the document loads
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 1000);

      return true;
    } catch (error) {
      console.error('Error opening document:', error);
      errors.value.push('Unable to open document');
      return false;
    }
  }


  /**
   * Lists all documents for an assessment
   */
  async function listAssessmentDocuments(assessmentId) {
    isLoading.value = true;
    errors.value = [];

    try {
      const response = await axiosInstance.get(`/api/v1/auth/files`, {
        params: {
          category: `security-assessments/${assessmentId}`
        }
      });

      if (response.data.status === 'ok') {
        return response.data.files;
      }
      throw new Error(response.data.error || 'Failed to list documents');
    } catch (error) {
      errors.value.push(error.response?.data?.error || 'Failed to list documents');
      throw error;
    } finally {
      isLoading.value = false;
    }
  }

  /**
   * Lists all files for the current user
   */
  async function listUserFiles(category) {
    isLoading.value = true;
    errors.value = [];

    try {
      const response = await axiosInstance.get('/api/v1/auth/files', {
        params: { category }
      });

      if (response.data.status === 'ok') {
        return response.data.files;
      }
      throw new Error(response.data.error || 'Failed to list files');
    } catch (error) {
      errors.value.push(error.response?.data?.error || 'Failed to list files');
      throw error;
    } finally {
      isLoading.value = false;
    }
  }

  /**
   * Delete a user's file
   */
  async function deleteUserFile(filePath) {
    isLoading.value = true;
    errors.value = [];

    try {
      const response = await axiosInstance.delete(`/api/v1/auth/files/${encodeURIComponent(filePath)}`);

      if (response.data.status === 'ok') {
        return true;
      }
      throw new Error(response.data.error || 'Failed to delete file');
    } catch (error) {
      errors.value.push(error.response?.data?.error || 'Failed to delete file');
      throw error;
    } finally {
      isLoading.value = false;
    }
  }

  /**
   * Edit Assessment - Switch back to draft mode for editing
   */
  async function editAssessment() {
    try {
      const response = await axiosInstance.post('/auth/security-assessment/status', {
        status: 'draft'
      });
      
      if (response.data.status === 'ok') {
        submissionStatus.value = 'draft';
        return true;
      }
      throw new Error(response.data.error || 'Failed to update status');
    } catch (error) {
      errors.value.push(error.response?.data?.error || 'Failed to switch to edit mode');
      throw error;
    }
  }

  return {
    // State
    formData,
    errors,
    fieldErrors,
    isLoading,
    isSaving,
    isSubmitting,
    submissionStatus,
    currentStep,
    showRecoveryDialog,
    savedFormDate,
    showSubmitConfirmation,
    lastSaved,
    isSavingNow,
    submissions,
    currentAssessment,
    isExporting,
    isUploading,
    uploadProgress,
    uploadStatus,
    fileErrors,

    // Computed
    completionPercentage,

    // Actions
    checkForSavedForm,
    saveServerDraft,
    loadDraft,
    startFresh,
    recoverSavedForm,
    submitForm,
    nextStep,
    prevStep,
    setStep,
    addError,
    clearErrors,
    setFieldError,
    updateFormData,
    saveNow,
    editAssessment,  // Added editAssessment to exposed actions
    // Document handling actions
    uploadDocument,
    getDocument,
    openDocument,
    downloadDocument,
    listAssessmentDocuments,
    updateUploadProgress,
    updateUploadStatus,
    // Other actions
    getSubmissions,
    getAssessmentById,
    exportToPdf,
    reviewAssessment,
    listUserFiles,
    deleteUserFile
  };
});