<template>
  <section aria-labelledby="admin-dashboard-title">
    <div class="bg-white shadow rounded-lg">
      <div class="px-4 py-5 sm:px-6">
        <h2 id="admin-dashboard-title" class="text-lg font-medium text-gray-900">
          Administrative Tasks
        </h2>
      </div>
      <div class="border-t border-gray-200">
        <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 p-4">
          <div v-for="action in adminActions" 
               :key="action.name"
               class="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400">
            <div class="flex-shrink-0">
              <span :class="[action.iconForeground, 'inline-flex rounded-lg p-3 ring-4 ring-white']">
                <component :is="action.icon" class="h-6 w-6" aria-hidden="true" />
              </span>
            </div>
            <div class="flex-1 min-w-0">
              <router-link :to="action.to" class="focus:outline-none">
                <span class="absolute inset-0" aria-hidden="true" />
                <p class="text-sm font-medium text-gray-900">{{ action.name }}</p>
                <p class="text-sm text-gray-500 truncate">{{ action.description }}</p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue';
import {
  UsersIcon,
  DocumentIcon,
  AcademicCapIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  DocumentTextIcon,
  ClipboardIcon,
} from '@heroicons/vue/24/outline';

// Define props
defineProps({
  actions: {
    type: Array,
    default: () => []
  }
});

const adminActions = computed(() => [
  {
    name: 'Recent Hires',
    to: '/recent-hires',
    icon: UsersIcon,
    description: 'View and manage recent hires',
    iconForeground: 'text-blue-700'
  },
  {
    name: 'Policy Status',
    to: '/policy-acceptance-status',
    icon: DocumentTextIcon,
    description: 'Track policy acceptance status',
    iconForeground: 'text-yellow-700'
  },
  {
    name: 'Kurtz Results',
    to: '/kurtz-results',
    icon: AcademicCapIcon,
    description: 'View assessment results',
    iconForeground: 'text-purple-700'
  },
  {
    name: 'Kurtz Audit',
    to: '/kurtz/audit',
    icon: ClipboardIcon,
    description: 'Review Kurtz submissions',
    iconForeground: 'text-indigo-700'
  },
  {
    name: 'Security Assessment',
    to: '/security-assessment',
    icon: ShieldCheckIcon,
    description: 'Review security assessments',
    iconForeground: 'text-red-700'
  },
  {
    name: 'Security Assessments Submissions',
    to: '/admin/security-assessments',
    icon: ShieldCheckIcon,
    description: 'Review security assessments',
    iconForeground: 'text-red-700'
  },
 
]);
</script>

<style scoped>
.admin-action-card:hover {
  transform: translateY(-2px);
  transition: all 0.2s ease-in-out;
}
</style>
