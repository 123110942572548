<template>
  <div>
    <h2 class="text-xl font-bold mb-4">Security Assessment Statements</h2>
    <SectionInstructions sectionTitle="Security Assessment Statements" sectionId="statements" />

    <!-- Info box -->
    <div class="mb-4 p-4 bg-blue-50 border-l-4 border-blue-400 rounded">
      <p class="text-sm text-blue-700">
        Please review each statement carefully. For any statement you mark as "Yes",
        you must provide detailed information in the text field that appears.
      </p>
    </div>

    <div class="space-y-6">
      <div v-for="(statement, key, i) in filteredStatements" :key="key"
        class="p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors">
        <div class="flex items-start justify-between">
          <div class="flex-grow pr-4">

            <label class="text-sm text-gray-700 block select-none">
              <span class="font-semibold text-gray-800">
              
                {{ i + 1 }}.
              </span>
              {{ statement.label }}
            </label>
            <div v-if="statement.value === true" class="mt-3">
              <input v-model="statement.details" type="text" :id="`security-statement-details-${key}`"
                class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500 placeholder:text-gray-400"
                :class="{ 'border-red-500': fieldErrors[`statement-${key}`] }" placeholder="please provide details"
                @input="handleDetailsInput(key)" />
              <p v-if="fieldErrors[`statement-${key}`]" class="mt-1 text-sm text-red-600">
                Please provide required details
              </p>
            </div>
          </div>
          <div class="flex-shrink-0 flex items-center space-x-4">
            <div class="flex items-center space-x-2">
              <input type="radio" :name="`security-statement-${key}`" :id="`security-statement-${key}-yes`"
                :value="true" v-model="statement.value"
                class="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500 cursor-pointer"
                @change="validateStatement(key)" />
              <label :for="`security-statement-${key}-yes`" class="text-sm text-gray-600 cursor-pointer select-none">
                Yes
              </label>

              <input type="radio" :name="`security-statement-${key}`" :id="`security-statement-${key}-no`"
                :value="false" v-model="statement.value"
                class="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500 cursor-pointer ml-4"
                @change="validateStatement(key)" />
              <label :for="`security-statement-${key}-no`" class="text-sm text-gray-600 cursor-pointer select-none">
                No
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import SectionInstructions from '../../SectionInstructions.vue';

const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  }
});

const emit = defineEmits(['update:modelValue']);
const fieldErrors = ref({});

// Add computed property for filtered statements
const filteredStatements = computed(() => {
  const applicationType = props.modelValue.applicationType;
  const statements = props.modelValue.statements;
  // if applicationType is set to canadian , return statements where applyTo has canadian 
  if (applicationType === 'canadian') {
    // check if applyTo includes canadian
    return Object.fromEntries(
      Object.entries(statements).filter(([key, statement]) => {
        return statement.applyTo.includes('canadian');
      })
    );
  } else if (applicationType === 'international') {
    // check if applyTo includes international
    return Object.fromEntries(
      Object.entries(statements).filter(([key, statement]) => {
        return statement.applyTo.includes('international');
      })
    );
  } else {
    // if applicationType is not set to canadian or international, return all statements
    return statements;
  }

});

function validateStatement(key) {
  // Only validate statements that are visible/applicable
  if (!filteredStatements.value[key]) return;

  const statement = props.modelValue.statements[key];

  if (statement.value === true && !statement.details) {
    fieldErrors.value[`statement-${key}`] = true;
  } else {
    delete fieldErrors.value[`statement-${key}`];
  }

  if (statement.value === false) {
    statement.details = '';
  }

  emit('update:modelValue', props.modelValue);
}

function handleDetailsInput(key) {
  // Only validate statements that are visible/applicable
  if (!filteredStatements.value[key]) return;

  const statement = props.modelValue.statements[key];

  if (statement.value === true && statement.details) {
    delete fieldErrors.value[`statement-${key}`];
  } else if (statement.value === true) {
    fieldErrors.value[`statement-${key}`] = true;
  }

  emit('update:modelValue', props.modelValue);
}
</script>
