<template>
  <div>
    <h2 class="text-xl font-bold mb-4">Employment & Educational History</h2>

    <!-- Enhanced Instructions -->
    <div class="bg-blue-50 p-4 rounded-lg mb-6 border border-blue-200">
      <h3 class="font-bold text-blue-800 mb-2">Requirements:</h3>
      <ul class="list-disc pl-5 text-blue-800">
        <li>List all employment, education, and unemployment periods for the past 5 years</li>
        <li>No gaps allowed - account for all time periods</li>
        <li>Include full institution names and addresses</li>
        <li>For remote work, provide employer's main office address</li>
        <li>Start with your current position</li>
        <li>Include education and employment that overlap</li>
      </ul>
    </div>

    <!-- Table View -->
    <div class="mb-6">
      <div class="overflow-x-auto">
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Employer / Academic Institution
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Title / Field of Study
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Full Address
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                From Date
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                To Date
              </th>
              <th scope="col" class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="(record, index) in modelValue.employmentHistory" :key="index"
              :class="{ 'bg-green-50': record.isPresent, 'hover:bg-gray-50': !record.isPresent }">
              <td class="px-6 py-4">
                <div class="text-sm text-gray-900">{{ record.employer }}</div>
                <span :class="{
                  'px-2 py-1 text-xs leading-5 font-semibold rounded-full mt-1 inline-block': true,
                  'bg-blue-100 text-blue-800': record.type === 'employment',
                  'bg-purple-100 text-purple-800': record.type === 'education',
                  'bg-gray-100 text-gray-800': record.type === 'unemployment'
                }">
                  {{ capitalize(record.type) }}
                </span>
                <span v-if="record.type === 'employment' && record.isRemote" class="ml-2 text-xs text-gray-500">
                  (Remote)
                </span>
              </td>
              <td class="px-6 py-4">
                <div class="text-sm text-gray-900">{{ record.title || record.description }}</div>
              </td>
              <td class="px-6 py-4">
                <div class="text-sm text-gray-900 whitespace-pre-wrap">
                  {{ getFormattedAddress(record) }}
                </div>
              </td>
              <td class="px-6 py-4">
                <div class="text-sm text-gray-900">{{ formatDate(record.fromDate) }}</div>
              </td>
              <td class="px-6 py-4">
                <div class="text-sm text-gray-900">
                  {{ record.isPresent ? 'Present' : formatDate(record.toDate) }}
                </div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <button @click="editRecord(index)" class="text-blue-600 hover:text-blue-900 mr-3">
                  Edit
                </button>
                <button @click="removeRecord(index)" v-if="modelValue.employmentHistory.length > 1"
                  class="text-red-600 hover:text-red-900">
                  Remove
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <button type="button" @click="addRecord"
        class="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700">
        Add Record
      </button>

      <!-- Edit Modal -->
    </div>

    <!-- Edit Modal -->
    <div v-if="showEditModal"
      class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-50"
      @click.self="closeEditModal">
      <div class="relative bg-white rounded-lg shadow-2xl max-w-3xl w-full mx-4 my-8" @click.stop>
        <!-- Modal Header -->
        <div class="bg-gray-50 px-6 py-4 border-b rounded-t-lg flex justify-between items-center">
          <h3 class="text-lg font-semibold text-gray-900">
            {{ editingRecord.isPresent ? 'Edit Current Position' : 'Edit Previous Position' }}
          </h3>
          <button @click="closeEditModal" class="text-gray-400 hover:text-gray-600 p-1">
            <span class="sr-only">Close</span>
            <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div class="p-6 max-h-[calc(100vh-200px)] overflow-y-auto">
          <div class="space-y-6">
            <!-- Activity Type Section -->
            <div class="bg-gray-50 p-4 rounded-lg">
              <div class="mb-4">
                <label class="block text-sm font-medium text-gray-700">
                  Type <span class="text-red-600">*</span>
                </label>
                <select v-model="editingRecord.type" 
                  class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3 bg-white focus:ring-blue-500 focus:border-blue-500">
                  <option value="employment">Employment</option>
                  <option value="education">Education</option>
                  <option value="unemployment">Unemployment/Leave</option>
                </select>
              </div>
            </div>

            <!-- Employment/Education Fields -->
            <template v-if="editingRecord.type !== 'unemployment'">
              <div class="bg-gray-50 p-4 rounded-lg">
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label class="block text-sm font-medium text-gray-700">
                      {{ editingRecord.type === 'employment' ? 'Company Name' : 'Institution Name' }}
                      <span class="text-red-600">*</span>
                    </label>
                    <input v-model="editingRecord.employer" type="text"
                      class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500" />
                  </div>
                  <div>
                    <label class="block text-sm font-medium text-gray-700">
                      {{ editingRecord.type === 'employment' ? 'Job Title' : 'Program/Field of Study' }}
                      <span class="text-red-600">*</span>
                    </label>
                    <input v-model="editingRecord.title" type="text"
                      class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500" />
                  </div>
                </div>

                <div v-if="editingRecord.type === 'employment'" class="mt-4">
                  <label class="inline-flex items-center">
                    <input type="checkbox" v-model="editingRecord.isRemote" 
                      class="rounded border-gray-300 text-blue-600 focus:ring-blue-500" />
                    <span class="ml-2 text-sm text-gray-700">Remote Position</span>
                  </label>
                </div>
              </div>

              <!-- Address Section -->
              <div class="bg-gray-50 p-4 rounded-lg">
                <h4 class="text-sm font-medium text-gray-900 mb-4">Address Information</h4>
                <!-- Address Search -->
                <div class="mb-4">
                  <label class="block text-sm font-medium text-gray-700">
                    Search Address
                  </label>
                  <div class="relative mt-1">
                    <input type="text" v-model="addressSearch" @input="handleAddressSearch"
                      placeholder="Start typing to search address..."
                      class="block w-full border rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500" />
                    <div v-if="isLoading" class="absolute right-2 top-2">
                      <svg class="animate-spin h-5 w-5 text-gray-400" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" fill="none" />
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z" />
                      </svg>
                    </div>
                  </div>
                  <!-- Search Results -->
                  <div v-if="searchResults.length > 0"
                    class="absolute z-10 mt-1 w-full bg-white shadow-lg rounded-md border border-gray-200 max-h-60 overflow-auto">
                    <ul class="py-1">
                      <li v-for="(result, index) in searchResults" :key="index" @click="selectAddress(result)"
                        class="px-3 py-2 hover:bg-gray-100 cursor-pointer text-sm">
                        {{ result.fullAddress }}
                      </li>
                    </ul>
                  </div>
                </div>

                <!-- Address Fields Grid -->
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label class="block text-sm font-medium text-gray-700">
                      Street Number <span class="text-red-600">*</span>
                    </label>
                    <input v-model="editingRecord.address.streetNumber" type="text"
                      class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500" />
                  </div>
                  <div>
                    <label class="block text-sm font-medium text-gray-700">
                      Street Name <span class="text-red-600">*</span>
                    </label>
                    <input v-model="editingRecord.address.streetName" type="text"
                      class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500" />
                  </div>
                  <div>
                    <label class="block text-sm font-medium text-gray-700">
                      City <span class="text-red-600">*</span>
                    </label>
                    <input v-model="editingRecord.address.city" type="text"
                      class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500" />
                  </div>
                  <div>
                    <label class="block text-sm font-medium text-gray-700">
                      Province/State <span class="text-red-600">*</span>
                    </label>
                    <input v-model="editingRecord.address.province" type="text"
                      class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500" />
                  </div>
                  <div>
                    <label class="block text-sm font-medium text-gray-700">
                      Country <span class="text-red-600">*</span>
                    </label>
                    <input v-model="editingRecord.address.country" type="text"
                      class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500" />
                  </div>
                  <div>
                    <label class="block text-sm font-medium text-gray-700">
                      Postal Code <span class="text-red-600">*</span>
                    </label>
                    <input v-model="editingRecord.address.postalCode" type="text"
                      class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500" />
                  </div>
                </div>
              </div>
            </template>

            <!-- Unemployment Fields -->
            <template v-else>
              <div class="bg-gray-50 p-4 rounded-lg">
                <div>
                  <label class="block text-sm font-medium text-gray-700">
                    Reason <span class="text-red-600">*</span>
                  </label>
                  <textarea v-model="editingRecord.description" rows="3"
                    class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500"></textarea>
                </div>
              </div>
            </template>

            <!-- Dates Section -->
            <div class="bg-gray-50 p-4 rounded-lg">
              <h4 class="text-sm font-medium text-gray-900 mb-4">Date Information</h4>
              <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label class="block text-sm font-medium text-gray-700">
                    From Date (Month/Year) <span class="text-red-600">*</span>
                  </label>
                  <input v-model="editingRecord.fromDate" type="month" :max="getCurrentYearMonth()"
                    class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500" 
                    @change="validateEditDates" required />
                </div>
                <div>
                  <label class="block text-sm font-medium text-gray-700">
                    To Date (Month/Year) <span class="text-red-600">*</span>
                  </label>
                  <div class="flex items-center space-x-4">
                    <input v-if="!editingRecord.isPresent" v-model="editingRecord.toDate" type="month"
                      :min="editingRecord.fromDate" :max="getCurrentYearMonth()"
                      class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500" 
                      @change="validateEditDates" required />
                    <label class="inline-flex items-center mt-1">
                      <input type="checkbox" v-model="editingRecord.isPresent" 
                        class="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        @change="handleEditPresentChange" />
                      <span class="ml-2 text-sm text-gray-600">Present</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal Footer -->
        <div class="bg-gray-50 px-6 py-4 border-t rounded-b-lg flex justify-end space-x-3">
          <button @click="closeEditModal" 
            class="px-4 py-2 border rounded text-gray-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500">
            Cancel
          </button>
          <button @click="saveEditedRecord" 
            class="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500">
            Save Changes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import { defineProps, defineEmits } from 'vue';
import { useAddressSearch } from '../../../composables/useAddressSearch';

const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  }
});

const emit = defineEmits(['update:modelValue']);
const showEditModal = ref(false);
const editingRecord = ref({});
const editingIndex = ref(-1);

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function getFormattedAddress(record) {
  if (record.type === 'unemployment') return 'N/A';
  if (!record.address) return '';

  const parts = [];
  if (record.address.streetNumber) parts.push(record.address.streetNumber);
  if (record.address.streetName) parts.push(record.address.streetName);
  if (record.address.city) parts.push(record.address.city);
  if (record.address.province) parts.push(record.address.province);
  if (record.address.country) parts.push(record.address.country);

  return parts.join(', ');
}

function formatDate(date) {
  if (!date) return '';

  try {
    const [year, month] = date.split('-');
    const utcDate = new Date(Date.UTC(parseInt(year), parseInt(month) - 1, 1));

    return new Intl.DateTimeFormat('en', {
      year: 'numeric',
      month: 'long',
      timeZone: 'UTC'
    }).format(utcDate);
  } catch (e) {
    return date;
  }
}

function getCurrentYearMonth() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  return `${year}-${month}`;
}

function addRecord() {
  editingRecord.value = {
    type: "employment",
    employer: "",
    title: "",
    address: {
      streetNumber: "",
      streetName: "",
      city: "",
      province: "",
      country: "",
      postalCode: "",
    },
    fromDate: getCurrentYearMonth(),
    toDate: "",
    isPresent: false,
    description: "",
    isRemote: false,
  };
  editingIndex.value = -1; 
  showEditModal.value = true;
}

function removeRecord(index) {
  const updatedHistory = [...props.modelValue.employmentHistory];
  updatedHistory.splice(index, 1);
  
  emit('update:modelValue', {
    ...props.modelValue,
    employmentHistory: updatedHistory
  });
}

function editRecord(index) {
  const recordToEdit = props.modelValue.employmentHistory[index];
  editingIndex.value = index;
  editingRecord.value = JSON.parse(JSON.stringify({
    ...recordToEdit,
    fromDate: recordToEdit.fromDate ? recordToEdit.fromDate.substring(0, 7) : '',
    toDate: recordToEdit.toDate ? recordToEdit.toDate.substring(0, 7) : '',
    address: recordToEdit.address || {
      streetNumber: "",
      streetName: "",
      city: "",
      province: "",
      country: "",
      postalCode: "",
    }
  }));
  showEditModal.value = true;
}

function closeEditModal() {
  showEditModal.value = false;
  editingRecord.value = {};
  editingIndex.value = -1;
  addressSearch.value = '';
  searchResults.value = [];
}

function saveEditedRecord() {
  if (editingIndex.value >= 0) {
    props.modelValue.employmentHistory[editingIndex.value] = { ...editingRecord.value };
  } else {
    props.modelValue.employmentHistory.push({ ...editingRecord.value });
  }
  
  if (editingRecord.value.isPresent) {
    props.modelValue.employmentHistory.forEach((r, i) => {
      if (i !== editingIndex.value) r.isPresent = false;
    });
  }
  
  emit('update:modelValue', { ...props.modelValue });
  closeEditModal();
}

// Add address search setup
const { searchResults, isLoading, searchAddressMapbox } = useAddressSearch();
const addressSearch = ref('');

let searchTimeout;
function handleAddressSearch(event) {
  clearTimeout(searchTimeout);
  searchTimeout = setTimeout(() => {
    searchAddressMapbox(event.target.value);
  }, 300);
}

function selectAddress(address) {
  editingRecord.value.address = {
    ...editingRecord.value.address,
    streetNumber: address.streetNumber || '',
    streetName: address.streetName || '',
    city: address.city || '',
    province: address.provinceTerritory || '',
    country: address.country || '',
    postalCode: address.postalCode || ''
  };

  addressSearch.value = '';
  searchResults.value = [];
}

onMounted(() => {
  if (props.modelValue.employmentHistory.length === 0) {
    props.modelValue.employmentHistory.push({
      type: 'employment',
      employer: 'Protocase Inc. II',
      title: '',
      address: {
        streetNumber: '46',
        streetName: 'Wabana Court',
        city: 'Sydney',
        province: 'NS',
        country: 'Canada',
        postalCode: 'B1P 0B9'
      },
      fromDate: '',
      toDate: '',
      isPresent: true,
      description: '',
      isRemote: false
    });
  }
});

watch(showEditModal, (newVal) => {
  if (!newVal) {
    addressSearch.value = '';
    searchResults.value = [];
  }
});
</script>
