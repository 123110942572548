<template>
    <div>
        <h2 class="text-xl font-bold mb-4">Criminal History</h2>
        <SectionInstructions sectionTitle="Criminal History" sectionId="criminal" />

        <!-- Criminal Record Question -->
        <div class="mb-6 p-4 bg-gray-50 rounded-lg">
            <label class="block text-sm font-medium text-gray-900">
                In the past five (5) years, have you been convicted of a criminal offence, in any country, for which
                you have not been granted a pardon / record suspension?
            </label>
            <div class="mt-2 space-x-4">
                <label class="inline-flex items-center">
                    <input type="radio" class="form-radio" value="yes"
                        v-model="modelValue.criminalHistory.hasConvictions" @change="handleConvictionChange" />
                    <span class="ml-2">Yes</span>
                </label>
                <label class="inline-flex items-center">
                    <input type="radio" class="form-radio" value="no"
                        v-model="modelValue.criminalHistory.hasConvictions" />
                    <span class="ml-2">No</span>
                </label>
            </div>
        </div>

        <div v-if="modelValue.criminalHistory.hasConvictions === 'yes'" class="p-4 bg-gray-50 rounded-lg">
            <!-- Convictions Table -->
            <div class="mb-6">
                <h3 class="text-lg font-medium mb-4">Criminal Convictions</h3>
                <div class="overflow-x-auto" v-if="modelValue.criminalHistory.convictions.length">
                    <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-50">
                            <tr>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                                    Charges</th>
                                <!-- police/service agency -->
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                                    Police Service/Agency</th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                                    Location</th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                                    Date</th>
                                <th scope="col"
                                    class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase">Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                            <tr v-for="(conviction, index) in modelValue.criminalHistory.convictions" :key="index"
                                class="hover:bg-gray-50">
                                <td class="px-6 py-4">
                                    <div class="text-sm text-gray-900">{{ conviction.charges }}</div>

                                </td>
                                <td class="px-6 py-4">
                                    <div class="text-sm text-gray-900">{{ conviction.policeService }}</div>
                                </td>
                                <td class="px-6 py-4">{{ formatLocation(conviction.location) }}</td>
                                <td class="px-6 py-4">{{ formatYearMonth(conviction.date) }}</td>
                                <td class="px-6 py-4 text-right space-x-3">
                                    <button @click="editConviction(index)"
                                        class="text-blue-600 hover:text-blue-900">Edit</button>
                                    <button @click="removeConviction(index)"
                                        class="text-red-600 hover:text-red-900">Remove</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <button @click="addConviction" class="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700">
                    Add Conviction
                </button>
            </div>
            <!-- Detentions Section -->
            <div class="mt-8">
                <h3 class="text-lg font-medium mb-4">Detentions or Arrests</h3>
                <div class="overflow-x-auto" v-if="modelValue.criminalHistory.detentions.length">
                    <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-50">
                            <tr>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                                    Occurrence</th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                                    Details</th>

                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                                    Location</th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                                    Date</th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                                    Status</th>
                                <th scope="col"
                                    class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase">Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                            <tr v-for="(detention, index) in modelValue.criminalHistory.detentions" :key="index"
                                class="hover:bg-gray-50">
                                <td class="px-6 py-4">
                                    <div class="text-sm text-gray-900">{{ detention.occurrence }}</div>

                                </td>
                                <td class="px-6 py-4">
                                    <div class="text-sm text-gray-900">{{ detention.details }}</div>
                                </td>
                                <td class="px-6 py-4">{{ formatLocation(detention.location) }}</td>
                                <td class="px-6 py-4">{{ formatYearMonth(detention.date) }}</td>
                                <td class="px-6 py-4">{{ detention.status }}</td>
                                <td class="px-6 py-4 text-right space-x-3">
                                    <button @click="editDetention(index)"
                                        class="text-blue-600 hover:text-blue-900">Edit</button>
                                    <button @click="removeDetention(index)"
                                        class="text-red-600 hover:text-red-900">Remove</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <button @click="addDetention" class="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700">
                    Add Detention
                </button>
            </div>
        </div>

        <!-- Conviction Modal -->
        <Modal v-model="showConvictionModal" @close="closeConvictionModal">
            <template #title>{{ editingIndex === -1 ? 'Add New Conviction' : 'Edit Conviction' }}</template>
            <template #content>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div class="col-span-2">
                        <label class="block text-sm font-medium text-gray-700">Charges <span
                                class="text-red-600">*</span></label>
                        <input v-model="editingConviction.charges" type="text"
                            class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3" required />
                    </div>

                    <div>
                        <label class="block text-sm font-medium text-gray-700">Police Service <span
                                class="text-red-600">*</span></label>
                        <input v-model="editingConviction.policeService" type="text"
                            class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3" required />
                    </div>

                    <div class="col-span-2 grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div>
                            <label class="block text-sm font-medium text-gray-700">City <span
                                    class="text-red-600">*</span></label>
                            <input v-model="editingConviction.location.city" type="text"
                                class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3" required />
                        </div>
                        <div>
                            <label class="block text-sm font-medium text-gray-700">Province/Territory <span
                                    class="text-red-600">*</span></label>
                            <input v-model="editingConviction.location.province" type="text"
                                class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3" required />
                        </div>
                        <div>
                            <label class="block text-sm font-medium text-gray-700">Country <span
                                    class="text-red-600">*</span></label>
                            <input v-model="editingConviction.location.country" type="text"
                                class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3" required />
                        </div>
                    </div>

                    <div>
                        <label class="block text-sm font-medium text-gray-700">Date <span
                                class="text-red-600">*</span></label>
                        <input v-model="editingConviction.date" type="month" :max="getCurrentYearMonth()"
                            class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3" required />
                    </div>

                    <div class="col-span-2">
                        <label class="block text-sm font-medium text-gray-700">Sentence <span
                                class="text-red-600">*</span></label>
                        <textarea v-model="editingConviction.sentence" rows="3"
                            class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3" required></textarea>
                    </div>
                </div>
            </template>
            <template #footer>
                <button @click="closeConvictionModal"
                    class="px-4 py-2 border rounded text-gray-600 hover:bg-gray-100">Cancel</button>
                <button @click="saveConviction"
                    class="ml-3 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700">Save</button>
            </template>
        </Modal>

        <!-- Detention Modal -->
        <Modal v-model="showDetentionModal" @close="closeDetentionModal">
            <template #title>{{ editingIndex === -1 ? 'Add New Detention' : 'Edit Detention' }}</template>
            <template #content>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div class="col-span-2">
                        <label class="block text-sm font-medium text-gray-700">Occurrence <span
                                class="text-red-600">*</span></label>
                        <input v-model="editingDetention.occurrence" type="text"
                            class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3" required />
                    </div>

                    <div>
                        <label class="block text-sm font-medium text-gray-700">Details <span
                                class="text-red-600">*</span></label>
                        <input v-model="editingDetention.details" type="text"
                            class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3" required />
                    </div>

                    <div class="col-span-2 grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div>
                            <label class="block text-sm font-medium text-gray-700">City <span
                                    class="text-red-600">*</span></label>
                            <input v-model="editingDetention.location.city" type="text"
                                class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3" required />
                        </div>
                        <div>
                            <label class="block text-sm font-medium text-gray-700">Province/Territory <span
                                    class="text-red-600">*</span></label>
                            <input v-model="editingDetention.location.province" type="text"
                                class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3" required />
                        </div>
                        <div>
                            <label class="block text-sm font-medium text-gray-700">Country <span
                                    class="text-red-600">*</span></label>
                            <input v-model="editingDetention.location.country" type="text"
                                class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3" required />
                        </div>
                    </div>

                    <div>
                        <label class="block text-sm font-medium text-gray-700">Date <span
                                class="text-red-600">*</span></label>
                        <input v-model="editingDetention.date" type="month" :max="getCurrentYearMonth()"
                            class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3" required />
                    </div>

                    <div class="col-span-2">
                        <label class="block text-sm font-medium text-gray-700">Status <span
                                class="text-red-600">*</span></label>
                        <textarea v-model="editingDetention.status" rows="3"
                            class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3" required></textarea>
                    </div>
                </div>
            </template>
            <template #footer>
                <button @click="closeDetentionModal"
                    class="px-4 py-2 border rounded text-gray-600 hover:bg-gray-100">Cancel</button>
                <button @click="saveDetention"
                    class="ml-3 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700">Save</button>
            </template>
        </Modal>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import Modal from '../../Modal.vue';
import SectionInstructions from '../../SectionInstructions.vue';

const props = defineProps({
    modelValue: {
        type: Object,
        required: true
    }
});

const emit = defineEmits(['update:modelValue']);
const showConvictionModal = ref(false);
const showDetentionModal = ref(false);
const editingConviction = ref({});
const editingDetention = ref({});
const editingIndex = ref(-1);


function formatLocation(location) {
    if (!location) return '';
    return `${location.city}, ${location.province}, ${location.country}`;
}

function formatYearMonth(date) {
    if (!date) return '';
    return date.substring(0, 7); // Returns YYYY-MM format
}

function getCurrentYearMonth() {
    return new Date().toISOString().substring(0, 7);
}

function addConviction() {
    editingConviction.value = {
        charges: '',
        policeService: '',
        location: {
            city: '',
            province: '',
            country: ''
        },
        date: '',
        sentence: ''
    };
    editingIndex.value = -1;
    showConvictionModal.value = true;
}

function editConviction(index) {
    editingConviction.value = { ...props.modelValue.criminalHistory.convictions[index] };
    editingIndex.value = index;
    showConvictionModal.value = true;
}

function removeConviction(index) {
    props.modelValue.criminalHistory.convictions.splice(index, 1);
    emit('update:modelValue', props.modelValue);
}

function saveConviction() {
    if (editingIndex.value === -1) {
        props.modelValue.criminalHistory.convictions.push({ ...editingConviction.value });
    } else {
        props.modelValue.criminalHistory.convictions[editingIndex.value] = { ...editingConviction.value };
    }

    // Sort convictions by date (most recent first)
    props.modelValue.criminalHistory.convictions.sort((a, b) =>
        new Date(b.date) - new Date(a.date)
    );

    emit('update:modelValue', props.modelValue);
    closeConvictionModal();
}

function closeConvictionModal() {
    showConvictionModal.value = false;
    editingConviction.value = {};
    editingIndex.value = -1;
}

function addDetention() {
    editingDetention.value = {
        occurrence: '',
        details: '',
        location: {
            city: '',
            province: '',
            country: ''
        },
        date: '',
        status: ''
    };
    editingIndex.value = -1;
    showDetentionModal.value = true;
}

function editDetention(index) {
    editingDetention.value = { ...props.modelValue.criminalHistory.detentions[index] };
    editingIndex.value = index;
    showDetentionModal.value = true;
}

function removeDetention(index) {
    props.modelValue.criminalHistory.detentions.splice(index, 1);
    emit('update:modelValue', props.modelValue);
}

function saveDetention() {
    if (editingIndex.value === -1) {
        props.modelValue.criminalHistory.detentions.push({ ...editingDetention.value });
    } else {
        props.modelValue.criminalHistory.detentions[editingIndex.value] = { ...editingDetention.value };
    }

    // Sort detentions by date (most recent first)
    props.modelValue.criminalHistory.detentions.sort((a, b) =>
        new Date(b.date) - new Date(a.date)
    );

    emit('update:modelValue', props.modelValue);
    closeDetentionModal();
}

function closeDetentionModal() {
    showDetentionModal.value = false;
    editingDetention.value = {};
    editingIndex.value = -1;
}
</script>
