<template>
  <div>
    <h2 class="text-xl font-bold mb-4">Personal Information</h2>
    <SectionInstructions sectionTitle="Personal Information" sectionId="personal" />
    <div class="space-y-4">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <!-- Surname -->
        <div>
          <label class="block text-sm font-medium text-gray-700">
            Surname <span class="text-red-600">*</span>
          </label>
          <input v-model="modelValue.personal.surname" type="text"
            placeholder="Enter your surname"
            class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3
            placeholder:text-gray-400 
            focus:ring-2 focus:ring-blue-500 focus:border-blue-500
            transition-colors duration-200"
            :class="{ 'border-red-500': fieldErrors?.surname }" required
            @change="$emit('update:modelValue', modelValue)" />
          <p v-if="fieldErrors?.surname" class="mt-1 text-sm text-red-600">
            Surname is required
          </p>
        </div>

        <!-- Given Names -->
        <div>
          <label class="block text-sm font-medium text-gray-700">
            Given Name(s) <span class="text-red-600">*</span>
          </label>
          <input v-model="modelValue.personal.givenNames" type="text"
            placeholder="Enter your given name(s)"
            class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3
            placeholder:text-gray-400
            focus:ring-2 focus:ring-blue-500 focus:border-blue-500
            transition-colors duration-200"
            :class="{ 'border-red-500': fieldErrors?.givenNames }" required
            @change="$emit('update:modelValue', modelValue)" />
          <p v-if="fieldErrors?.givenNames" class="mt-1 text-sm text-red-600">
            Given name(s) are required
          </p>
        </div>

        <!-- Surname at Birth -->
        <div>
          <label class="block text-sm font-medium text-gray-700">
            Surname at Birth
          </label>
          <input v-model="modelValue.personal.surnameAtBirth" type="text"
            placeholder="If different from current surname"
            class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3
            placeholder:text-gray-400
            focus:ring-2 focus:ring-blue-500 focus:border-blue-500
            transition-colors duration-200"
            @change="$emit('update:modelValue', modelValue)" />
        </div>

        <!-- Other Names -->
        <div>
          <label class="block text-sm font-medium text-gray-700">
            Other Names
          </label>
          <input v-model="modelValue.personal.otherNames" type="text"
            placeholder="Any other names you're known by"
            class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3
            placeholder:text-gray-400
            focus:ring-2 focus:ring-blue-500 focus:border-blue-500
            transition-colors duration-200"
            @change="$emit('update:modelValue', modelValue)" />
        </div>

        <!-- Gender -->
        <div>
          <label class="block text-sm font-medium text-gray-700">
            Gender <span class="text-red-600">*</span>
          </label>
          <select v-model="modelValue.personal.gender" 
            class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3
            focus:ring-2 focus:ring-blue-500 focus:border-blue-500
            transition-colors duration-200"
            :class="{ 'border-red-500': fieldErrors?.gender }" required
            @change="$emit('update:modelValue', modelValue)">
            <option value="">Select gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="another_gender">Other</option>
          </select>
          <p v-if="fieldErrors?.gender" class="mt-1 text-sm text-red-600">
            Gender is required
          </p>
        </div>

        <!-- Date of Birth -->
        <div>
          <label class="block text-sm font-medium text-gray-700">
            Date of Birth <span class="text-red-600">*</span>
          </label>
          <input v-model="modelValue.personal.dateOfBirth" type="date"
            class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3
            focus:ring-2 focus:ring-blue-500 focus:border-blue-500
            transition-colors duration-200"
            :class="{ 'border-red-500': fieldErrors?.dateOfBirth }" required
            @change="$emit('update:modelValue', modelValue)" />
          <p v-if="fieldErrors?.dateOfBirth" class="mt-1 text-sm text-red-600">
            Date of birth is required
          </p>
        </div>

        <!-- Marital Status -->
        <div>
          <label class="block text-sm font-medium text-gray-700">
            Marital Status <span class="text-red-600">*</span>
          </label>
          <select v-model="modelValue.personal.maritalStatus"
            class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3
            focus:ring-2 focus:ring-blue-500 focus:border-blue-500
            transition-colors duration-200"
            :class="{ 'border-red-500': fieldErrors?.maritalStatus }" required
            @change="$emit('update:modelValue', modelValue)">
            <option value="">Select status</option>
            <option value="single">Single</option>
            <option value="married">Married</option>
            <option value="common-law-partnership">Common-Law partnership</option>
            <option value="divorced">Divorced</option>
            <option value="widowed">Widowed</option>
          </select>
          <p v-if="fieldErrors?.maritalStatus" class="mt-1 text-sm text-red-600">
            Marital status is required
          </p>
        </div>
      </div>

      <!-- Name Changes Question -->
      <div class="mt-6">
        <label class="block text-sm font-medium text-gray-700 mb-2">
          Have you ever changed your name? <span class="text-red-600">*</span>
        </label>
        <div class="flex gap-4">
          <label class="inline-flex items-center">
            <input
              type="radio"
              name="nameChanges"
              value="yes"
              :checked="hasNameChanges === 'yes'"
              @change="handleNameChangesResponse('yes')"
              class="form-radio h-4 w-4 text-blue-600"
            >
            <span class="ml-2">Yes</span>
          </label>
          <label class="inline-flex items-center">
            <input
              type="radio"
              name="nameChanges"
              value="no"
              :checked="hasNameChanges === 'no'"
              @change="handleNameChangesResponse('no')"
              class="form-radio h-4 w-4 text-blue-600"
            >
            <span class="ml-2">No</span>
          </label>
        </div>
      </div>

      <!-- Name Changes Section -->
      <div v-if="hasNameChanges === 'yes'" class="mt-6">
        <h3 class="text-lg font-medium mb-2">Name Changes</h3>
        <div v-for="(nameChange, index) in modelValue.personal.nameChanges" :key="index"
          class="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4 p-4 bg-gray-50 rounded-lg">
          <div>
            <label class="block text-sm font-medium text-gray-700">
              From Name
            </label>
            <input v-model="nameChange.fromName" type="text"
              placeholder="Previous name"
              class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3
              placeholder:text-gray-400
              focus:ring-2 focus:ring-blue-500 focus:border-blue-500
              transition-colors duration-200"
              @change="$emit('update:modelValue', modelValue)" />
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700">
              To Name
            </label>
            <input v-model="nameChange.toName" type="text"
              class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3"
              @change="$emit('update:modelValue', modelValue)" />
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700">
              Date of Change
            </label>
            <input v-model="nameChange.dateOfChange" type="date"
              class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3"
              @change="$emit('update:modelValue', modelValue)" />
          </div>
          <div class="col-span-1 md:col-span-3 flex justify-end">
            <button type="button" @click="removeNameChange(index)" class="text-red-600 hover:text-red-800">
              Remove
            </button>
          </div>
        </div>
        <button 
          type="button" 
          @click="addNameChange" 
          class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium 
          rounded-md text-white bg-blue-600 hover:bg-blue-700 
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
          transition-colors duration-200"
          :class="{ 'opacity-50 cursor-not-allowed': isNameChangeLimitReached }"
          :disabled="isNameChangeLimitReached"
        >
          Add Name Change
        </button>
        <p v-if="isNameChangeLimitReached" class="mt-1 text-sm text-amber-600">
          Maximum of 2 name changes allowed
        </p>
      </div>

      <!-- Spouse Information -->
      <div v-if="shouldShowSpouseInfo" 
        class="mt-6 p-6 bg-gray-50 rounded-lg border border-gray-200">
        <h3 class="text-lg font-medium mb-2">{{ spouseSectionTitle }}</h3>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label class="block text-sm font-medium text-gray-700">
              {{ fieldLabels.surname }} <span class="text-red-600">*</span>
            </label>
            <input v-model="modelValue.personal.spouse.surname" type="text"
              class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3"
              :class="{ 'border-red-500': fieldErrors?.spouse?.surname }" required 
              @change="$emit('update:modelValue', modelValue)"
            />
            <p v-if="fieldErrors?.spouse?.surname" class="mt-1 text-sm text-red-600">
              {{ partnerType }}'s surname is required
            </p>
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700">
              {{ fieldLabels.givenNames }} <span class="text-red-600">*</span>
            </label>
            <input v-model="modelValue.personal.spouse.givenNames" type="text"
              class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3"
              :class="{ 'border-red-500': fieldErrors?.spouse?.givenNames }" required 
              @change="$emit('update:modelValue', modelValue)"
            />
            <p v-if="fieldErrors?.spouse?.givenNames" class="mt-1 text-sm text-red-600">
              {{ partnerType }}'s given name(s) are required
            </p>
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700">
              {{ fieldLabels.dateOfBirth }} <span class="text-red-600">*</span>
            </label>
            <input v-model="modelValue.personal.spouse.dateOfBirth" type="date"
              class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3"
              :class="{ 'border-red-500': fieldErrors?.spouse?.dateOfBirth }" required 
              @change="$emit('update:modelValue', modelValue)"
            />
            <p v-if="fieldErrors?.spouse?.dateOfBirth" class="mt-1 text-sm text-red-600">
              {{ partnerType }}'s date of birth is required
            </p>
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700">
              {{ fieldLabels.citizenship }} <span class="text-red-600">*</span>
            </label>
            <input v-model="modelValue.personal.spouse.citizenship" type="text"
              class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3"
              :class="{ 'border-red-500': fieldErrors?.spouse?.citizenship }" required 
              @change="$emit('update:modelValue', modelValue)"
            />
            <p v-if="fieldErrors?.spouse?.citizenship" class="mt-1 text-sm text-red-600">
              {{ partnerType }}'s citizenship is required
            </p>
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700">
              {{ fieldLabels.telephone }} <span class="text-red-600">*</span>
            </label>
            <input v-model="modelValue.personal.spouse.telephone" type="tel"
              class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3"
              :class="{ 'border-red-500': fieldErrors?.spouse?.telephone }" required 
              @change="$emit('update:modelValue', modelValue)"
            />
            <p v-if="fieldErrors?.spouse?.telephone" class="mt-1 text-sm text-red-600">
              {{ partnerType }}'s telephone is required
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import SectionInstructions from '../../SectionInstructions.vue';

const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  },
  fieldErrors: {
    type: Object,
    default: () => ({})
  }
});

const emit = defineEmits(['update:modelValue']);

const isNameChangeLimitReached = computed(() => {
  return props.modelValue.personal.nameChanges.length >= 2;
});

const hasNameChanges = ref('no');

const spouseSectionTitle = computed(() => {
  return props.modelValue.personal.maritalStatus === 'common-law-partnership' 
    ? 'Common-Law Partner Information'
    : 'Spouse Information';
});

const partnerType = computed(() => {
  return props.modelValue.personal.maritalStatus === 'common-law-partnership' ? 'Partner' : 'Spouse';
});

const fieldLabels = computed(() => ({
  surname: `${partnerType.value}'s Surname`,
  givenNames: `${partnerType.value}'s Given Name(s)`,
  dateOfBirth: `${partnerType.value}'s Date of Birth`,
  citizenship: `${partnerType.value}'s Country of Birth`,
  telephone: `${partnerType.value}'s Telephone`
}));

function addNameChange() {
  if (props.modelValue.personal.nameChanges.length < 2) {
    props.modelValue.personal.nameChanges.push({ fromName: "", toName: "", dateOfChange: "" });
    emit('update:modelValue', props.modelValue);
  }
}

function removeNameChange(index) {
  props.modelValue.personal.nameChanges.splice(index, 1);
  emit('update:modelValue', props.modelValue);
}

function handleNameChangesResponse(response) {
  hasNameChanges.value = response;
  if (response === 'yes' && !props.modelValue.personal.nameChanges) {
    props.modelValue.personal.nameChanges = [];
  } else if (response === 'no') {
    props.modelValue.personal.nameChanges = [];
  }
  emit('update:modelValue', props.modelValue);
}

const shouldShowSpouseInfo = computed(() => {
  return ['married', 'common-law-partnership'].includes(props.modelValue.personal.maritalStatus);
});
</script>