<template>
  <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-sm">
      <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
        Account Activation
      </h2>
    </div>

    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <div v-if="isLoading" class="text-center">
        <p class="text-gray-600">Verifying your email...</p>
      </div>

      <div v-else-if="error" class="rounded-md bg-red-50 p-4">
        <div class="flex">
          <div class="ml-3">
            <h3 class="text-sm font-medium text-red-800">Activation Failed</h3>
            <p class="mt-2 text-sm text-red-700">{{ error }}</p>
            <button 
              @click="handleResendActivation"
              :disabled="resendingActivation"
              class="mt-4 text-sm font-medium text-red-600 hover:text-red-500"
            >
              {{ resendingActivation ? 'Sending...' : 'Resend activation email' }}
            </button>
          </div>
        </div>
      </div>

      <div v-else-if="success" class="rounded-md bg-green-50 p-4">
        <div class="flex">
          <div class="ml-3">
            <h3 class="text-sm font-medium text-green-800">Success!</h3>
            <p class="mt-2 text-sm text-green-700">{{ success }}</p>
            <router-link 
              to="/login" 
              class="mt-4 block text-sm font-medium text-green-600 hover:text-green-500"
            >
              Proceed to login
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useInternalAuthStore } from '@/stores/internalAuth';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';

const route = useRoute();
const router = useRouter();
const authStore = useInternalAuthStore();

const isLoading = ref(true);
const error = ref('');
const success = ref('');
const resendingActivation = ref(false);

const handleActivation = async (email, token) => {
  try {
    console.log('Attempting activation with:', { email, token }); // Add debug logging
    const response = await authStore.verifyEmail({
      email: decodeURIComponent(email), // Make sure to decode the email
      token: token.toUpperCase() // Ensure token is uppercase
    });
    console.log('Activation response:', response); // Add debug logging
    
    if (response.status === 'ok') {
      success.value = 'Your account has been successfully activated. You can now log in.';
      setTimeout(() => {
        router.push('/login');
      }, 3000);
    }
  } catch (err) {
    console.error('Activation error:', err.response?.data); // Add error logging
    error.value = err.response?.data?.error || 'Failed to activate account. Please try again.';
  } finally {
    isLoading.value = false;
  }
};

const handleResendActivation = async () => {
  if (resendingActivation.value) return;
  
  try {
    resendingActivation.value = true;
    const email = route.query.email;
    await authStore.resendActivation({ email });
    success.value = 'A new activation email has been sent. Please check your inbox.';
    error.value = '';
  } catch (err) {
    error.value = 'Failed to resend activation email. Please try again.';
  } finally {
    resendingActivation.value = false;
  }
};

onMounted(() => {
  const { email, token } = route.query;
  if (!email || !token) {
    error.value = 'Invalid activation link';
    isLoading.value = false;
    return;
  }
  handleActivation(email, token);
});
</script>
