import { validateEmploymentHistory, validateHistoryGaps } from './formValidation';
import { validateCriminalHistory } from './criminalHistoryValidation';


import {
    validateDates,
    validateAtLeastOnePhone,
    phoneRegex,
    emailRegex
} from './helpers';

import {
    REASONABLE_AGE_MIN,
    REASONABLE_AGE_MAX,
    MIN_ADDRESS_HISTORY_YEARS,
    MIN_REFERENCES,
    MAX_REFERENCES,
    MIN_REFERENCE_YEARS,
    REQUIRED_DOCUMENTS,
    MAX_EMPLOYMENT_GAP_DAYS
} from './constants';

/**
 * Standardized validation result interface
 * @typedef {Object} ValidationResult
 * @property {boolean} isValid - Whether the validation passed
 * @property {string|string[]|undefined} errors - Error message(s) if validation failed
 */

/**
 * Validates citizenship section of the form
 * @returns {ValidationResult}
 */
export function validateCitizenshipSection(citizenship, applicationType) {
    const errors = [];

    // Check if citizenship object exists
    if (!citizenship?.citizenships?.length) {
        return { isValid: false, error: "Primary citizenship country is required" };
    }

    // Validate primary citizenship
    if (!citizenship.citizenships[0]?.country?.trim()) {
        errors.push("Primary citizenship country is required");
    }

    // Check citizenship count
    if (citizenship.citizenships.length > 3) {
        errors.push("Maximum of 3 citizenships allowed");
    }

    // Validate place of birth
    if (!validatePlaceOfBirth(citizenship)) {
        errors.push("Complete place of birth information is required");
    }

    // Additional validation for international applicants
    if (applicationType === 'international') {
        if (!citizenship.dateOfEntry) {
            errors.push("Date of entry into Canada is required for international applicants");
        }
        if (!citizenship.portOfEntry?.trim()) {
            errors.push("Port of entry is required for international applicants");
        }
        if (citizenship.hasValidForeignPassport === undefined) {
            errors.push("Please indicate if you have a valid foreign passport");
        }
        if (citizenship.hasValidForeignPassport && (!citizenship.foreignPassports || citizenship.foreignPassports.length === 0)) {
            errors.push("Please provide foreign passport details");
        }
    }

    return {
        isValid: errors.length === 0,
        errors: errors.length > 0 ? errors : undefined
    };
}

/**
 * Validates place of birth information based on application type
 * @param {Object} citizenship - The citizenship data object
 * @returns {boolean}
 */
export function validatePlaceOfBirth(citizenship) {
    if (!citizenship) return false;

    if (citizenship.bornInCanada !== null) {
        // Canadian birth validation
        if (citizenship.bornInCanada) {
            return !!(
                citizenship.cityOfBirth?.trim() &&
                citizenship.provinceOfBirth?.trim()
            );
        } else {
            // Born outside Canada
            return !!(
                citizenship.countryOfBirth?.trim() &&
                citizenship.cityOfBirth?.trim()
            );
        }
    }

    // If bornInCanada is not set, check for international format
    return !!(citizenship.countryOfBirth?.trim());
}

/**
 * Validates travel history entries
 * @returns {ValidationResult}
 */
export function validateTravelHistory(travelHistory) {
    if (!travelHistory) {
        return { isValid: true }; // Travel history is optional
    }

    const errors = [];

    travelHistory.forEach((travel, index) => {
        // Skip empty entries
        if (!travel.country && !travel.fromDate && !travel.toDate) {
            return;
        }

        if (!travel.country?.trim()) {
            errors.push(`Travel entry ${index + 1}: Country is required`);
        }

        if (!travel.fromDate) {
            errors.push(`Travel entry ${index + 1}: From date is required`);
        }

        if (!travel.toDate) {
            errors.push(`Travel entry ${index + 1}: To date is required`);
        }

        if (travel.fromDate && travel.toDate) {
            if (!validateDates(travel.fromDate, travel.toDate, false)) {
                errors.push(`Travel entry ${index + 1}: Invalid date range`);
            }
        }

        if (travel.weeksSpent) {
            const weeks = parseInt(travel.weeksSpent);
            if (isNaN(weeks) || weeks <= 0) {
                errors.push(`Travel entry ${index + 1}: Weeks spent must be a positive number`);
            }
        }
    });

    return {
        isValid: errors.length === 0,
        errors: errors.length > 0 ? errors : undefined
    };
}

/**
 * Validates residential history
 * @returns {ValidationResult}
 */
export function validateResidentialHistory(residentialHistory) {
    if (!residentialHistory?.length) {
        return { isValid: false, error: "Residential history is required" };
    }

    const errors = [];
    let hasCurrentAddress = false;
    let addressTimespan = 0;

    residentialHistory.forEach((address, index) => {
        if (!validateAddress(address)) {
            errors.push(`Address ${index + 1}: All address fields are required`);
        }

        if (!address.fromDate) {
            errors.push(`Address ${index + 1}: From date is required`);
        }

        if (!address.toDate && !address.isPresent) {
            errors.push(`Address ${index + 1}: To date is required if not present address`);
        }

        if (address.isPresent) {
            hasCurrentAddress = true;
        }

        addressTimespan += calculateAddressTimespan(address);
    });

    if (!hasCurrentAddress) {
        errors.push("Please mark your current address by checking the 'Present' checkbox");
    }

    if (addressTimespan < MIN_ADDRESS_HISTORY_YEARS) {
        errors.push(`Your residential history must cover at least the last ${MIN_ADDRESS_HISTORY_YEARS} years`);
    }

    return {
        isValid: errors.length === 0,
        errors: errors.length > 0 ? errors : undefined
    };
}


/**
 * Validates references section
 * @returns {ValidationResult}
 */
export function validateReferences(references) {
    if (!references?.length) {
        return { isValid: false, error: "References are required" };
    }

    const errors = [];

    // Check minimum and maximum reference count
    if (references.length < MIN_REFERENCES) {
        errors.push(`Minimum ${MIN_REFERENCES} references are required`);
    } else if (references.length > MAX_REFERENCES) {
        errors.push(`Maximum ${MAX_REFERENCES} references are allowed`);
    }

    // Validate each reference
    references.forEach((ref, index) => {
        if (!validateReferenceFields(ref)) {
            errors.push(`Reference ${index + 1}: All required fields must be completed`);
        }

        // Validate phone number format
        if (ref.telephone && !phoneRegex.test(ref.telephone)) {
            errors.push(`Reference ${index + 1}: Invalid phone number format`);
        }

        // Validate email if provided
        if (ref.email && !emailRegex.test(ref.email)) {
            errors.push(`Reference ${index + 1}: Invalid email format`);
        }

        // Validate known since date
        if (!validateReferenceKnownPeriod(ref, index, errors)) {
            errors.push(`Reference ${index + 1}: Must have known you for at least ${MIN_REFERENCE_YEARS} years`);
        }
    });

    // Validate reference types distribution
    if (!validateReferenceTypes(references, errors)) {
        errors.push("You must provide at least one personal and one professional reference");
    }

    return {
        isValid: errors.length === 0,
        errors: errors.length > 0 ? errors : undefined
    };
}

/**
 * Validates security clearance section
 * @returns {ValidationResult}
 */
export function validateSecurityClearance(clearance) {

    const errors = [];

    // if clearance.level is not provided, ask user to choose one 
    if (!clearance.level) {
        errors.push("Please pick an option for security clearance level.");
    } else if (clearance.level === 'none') {
        return { isValid: true };
    } else if (clearance.level !== 'none') {
        if (!clearance.issuingCountry?.trim()) {
            errors.push("Issuing country is required");
        }

        if (!clearance.issuingDepartment?.trim()) {
            errors.push("Issuing department is required");
        }

        if (!clearance.expiryDate) {
            errors.push("Expiry date is required");
        } else if (new Date(clearance.expiryDate) < new Date()) {
            errors.push("Security clearance has expired");
        }
    }



    return {
        isValid: errors.length === 0,
        errors: errors.length > 0 ? errors : undefined
    };
}

// Add stepValidation object to map step numbers to their validation functions
export const stepValidation = {
    0: validateApplicationType,
    1: validatePersonalInfo,
    2: validateContactInfo,
    3: (formData) => validateCitizenshipSection(formData.citizenship, formData.applicationType),
    4: (formData) => validateResidentialHistory(formData.residentialHistory),
    5: (formData) => {
        const errors = [];
        const isValid = validateEmploymentHistory(formData.employmentHistory, errors);
        return {
            isValid,
            errors: errors.length > 0 ? errors : undefined
        };
    },
    6: (formData) => validateCriminalHistory(formData.criminalHistory),
    7: (formData) => validateTravelHistory(formData.travelHistory),
    8: (formData) => validateReferences(formData.references),
    9: (formData) => validateSecurityClearance(formData.securityClearance),
    10: validateSecurityStatements,
    11: validateSupportingDocuments,
    12: validateFullForm // Final review step
};

// Add new validation functions for each section
export function validateApplicationType(formData) {
    if (!formData.applicationType) {
        return { isValid: false, error: "Please select an application type" };
    }
    return { isValid: true };
}

export function validateLanguagePreference(formData) {
    if (!formData.preferredLanguage) {
        return { isValid: false, error: "Please select your preferred language" };
    }
    return { isValid: true };
}

export function validatePersonalInfo(formData) {
    const required = ['surname', 'givenNames', 'dateOfBirth', 'gender', 'maritalStatus'];
    const missing = required.filter(field => !formData.personal[field]);

    if (missing.length > 0) {
        const errors = missing.map(field =>
            `${field.replace(/([A-Z])/g, ' $1').toLowerCase()} is required`
        );
        return { isValid: false, errors };
    }
    return { isValid: true };
}

export function validateContactInfo(formData) {
    if (!validateAtLeastOnePhone(formData.contact)) {
        return { isValid: false, error: "At least one phone number is required" };
    }

    if (!formData.contact.personalEmail) {
        return { isValid: false, error: "Please provide personal email" };
    }
    return { isValid: true };
}



export function validateSecurityStatements(formData) {
    const statements = formData.statements;
    for (const [key, statement] of Object.entries(statements)) {
        if (statement.value && !statement.details) {
            return { isValid: false, error: "Please provide details for all selected statements" };
        }
    }
    return { isValid: true };
}

export function validateSupportingDocuments(formData) {
    const requiredDocs = formData.applicationType === 'international'
        ? ['identificationDocuments', 'workPermit', 'criminalRecordCheck']
        : ['identificationDocuments'];

    const missing = requiredDocs.filter(doc =>
        !formData.supportingDocuments[doc] ||
        formData.supportingDocuments[doc].length === 0
    );

    if (missing.length > 0) {
        const errors = missing.map(doc =>
            `${doc.replace(/([A-Z])/g, ' $1').toLowerCase()} are required`
        );
        return { isValid: false, errors };
    }
    return { isValid: true };
}

// Update validateStep function to properly handle employment section
export async function validateStep(step, formData, v$) {
    const validator = stepValidation[step];
    if (!validator) return { isValid: true };

    // Special case for employment history only
    if (step === 5) {
        const errors = [];
        const isValid = validateEmploymentHistory(formData.employmentHistory, errors);
        if (!isValid) {
            return { isValid: false, errors };
        }
        return { isValid: true };
    }

    // For all other steps, use the validator from stepValidation
    return validator(formData);
}

// Helper functions
function validateAddress(address) {
    return !!(
        address.streetNumber &&
        address.streetName &&
        address.city &&
        address.provinceTerritory &&
        address.country &&
        address.postalCode
    );
}

function calculateAddressTimespan(address) {
    if (!address.fromDate) return 0;

    const fromDate = new Date(address.fromDate);
    const toDate = address.isPresent ? new Date() : new Date(address.toDate);
    return (toDate - fromDate) / (1000 * 60 * 60 * 24 * 365); // in years
}

function validateReferenceFields(ref) {
    return !!(ref.surname && ref.givenName && ref.relationship && ref.telephone);
}

function validateReferenceKnownPeriod(ref, index, errors) {
    if (ref.knownSince) {
        const knownSinceDate = new Date(ref.knownSince);
        const today = new Date();
        const diffYears = (today - knownSinceDate) / (1000 * 60 * 60 * 24 * 365);

        if (diffYears < MIN_REFERENCE_YEARS) {
            errors.push(`Reference ${index + 1} should have known you for at least ${MIN_REFERENCE_YEARS} years`);
            return false;
        }
    }
    return true;
}

function validateReferenceTypes(references, errors) {
    const personalRefs = references.filter(r => r.type === 'personal').length;
    const professionalRefs = references.filter(r => r.type === 'professional').length;

    if (personalRefs === 0) {
        errors.push("At least one personal reference is required");
        return false;
    }

    if (professionalRefs === 0) {
        errors.push("At least one professional reference is required");
        return false;
    }

    return true;
}

function validateVisaInformation(personal, errors) {
    const requirements = [
        { field: 'visaType', message: 'Visa type is required for international workers' },
        { field: 'visaNumber', message: 'Visa number is required for international workers' },
        { field: 'visaExpiryDate', message: 'Visa expiry date is required for international workers' }
    ];

    let isValid = true;
    for (const req of requirements) {
        if (!personal[req.field]) {
            errors.push(req.message);
            isValid = false;
        }
    }
    return isValid;
}

function validateRequiredDocuments(documents, applicationType, errors) {
    const requiredDocs = REQUIRED_DOCUMENTS[applicationType];
    if (!requiredDocs) return true;

    let isValid = true;
    requiredDocs.forEach(docType => {
        if (!documents[docType]?.length) {
            const formattedName = docType.replace(/([A-Z])/g, ' $1').toLowerCase().trim();
            errors.push(
                docType === 'identificationDocuments' ?
                    'Proof of citizenship/identification documents are required' :
                    `${formattedName} are required`
            );
            isValid = false;
        }
    });

    return isValid;
}

/**
 * Validates the entire form for the final review step
 */
export async function validateFullForm(formData, v$) {
    const errors = [];

    // Run all section validations
    const sections = [
        { validator: validateApplicationType, data: formData },
        { validator: validateLanguagePreference, data: formData },
        { validator: validatePersonalInfo, data: formData },
        { validator: validateContactInfo, data: formData },
        { validator: (data) => validateCitizenshipSection(data.citizenship, data.applicationType), data: formData },
        { validator: (data) => validateResidentialHistory(data.residentialHistory), data: formData },
        { validator: (data) => validateEmploymentHistory(data.employmentHistory), data: formData },
        { validator: validateCriminalHistory, data: formData },
        { validator: (data) => validateTravelHistory(data.travelHistory), data: formData },
        { validator: (data) => validateReferences(data.references), data: formData },
        { validator: (data) => validateSecurityClearance(data.securityClearance), data: formData },
        { validator: validateSecurityStatements, data: formData },
        { validator: validateSupportingDocuments, data: formData }
    ];

    // Run all section validations
    for (const section of sections) {
        const result = section.validator(section.data);
        if (!result.isValid && result.errors) {
            errors.push(...(Array.isArray(result.errors) ? result.errors : [result.errors]));
        }
    }

    // Run additional validations for international applicants
    if (formData.applicationType === 'international') {
        const internationalResult = validateInternationalRequirements(formData);
        if (!internationalResult.isValid && internationalResult.errors) {
            errors.push(...(Array.isArray(internationalResult.errors) ? internationalResult.errors : [internationalResult.errors]));
        }
    }

    // Validate form using vuelidate if provided
    if (v$ && typeof v$.$validate === 'function') {
        const isValid = await v$.$validate();
        if (!isValid) {
            v$.$errors.forEach(error => {
                errors.push(error.$message);
            });
        }
    }

    return {
        isValid: errors.length === 0,
        errors: errors
    };
}

/**
 * Validates international applicant requirements
 * @returns {ValidationResult}
 */
export function validateInternationalRequirements(formData) {
    const errors = [];

    // Validate visa information
    if (!formData.personal.visaType?.trim()) {
        errors.push("Visa type is required for international applicants");
    }
    if (!formData.personal.visaNumber?.trim()) {
        errors.push("Visa number is required for international applicants");
    }
    if (!formData.personal.visaExpiryDate) {
        errors.push("Visa expiry date is required for international applicants");
    } else if (new Date(formData.personal.visaExpiryDate) < new Date()) {
        errors.push("Visa has expired");
    }

    // Validate citizenship and immigration details
    if (!formData.citizenship.dateOfEntry) {
        errors.push("Date of entry to Canada is required");
    }
    if (!formData.citizenship.portOfEntry?.trim()) {
        errors.push("Port of entry is required");
    }
    if (!formData.citizenship.countryOfEmigration?.trim()) {
        errors.push("Country of emigration is required");
    }

    // Validate required documents for international applicants
    const requiredDocs = ['identificationDocuments', 'workPermit', 'criminalRecord'];
    requiredDocs.forEach(docType => {
        if (!formData.supportingDocuments[docType]?.length) {
            const formattedName = docType.replace(/([A-Z])/g, ' $1').toLowerCase().trim();
            errors.push(`${formattedName} are required for international applicants`);
        }
    });

    return {
        isValid: errors.length === 0,
        errors: errors.length > 0 ? errors : undefined
    };
}