export function validateReferences(references = [], personalInfo = {}, residentialHistory = [], errors = []) {
  // Count reference types
  const personalRefs = references.filter(r => r.type === 'personal').length;
  const professionalRefs = references.filter(r => r.type === 'professional').length;

  // Base validations
  if (references.length < 3) {
    errors.push('Minimum of 3 references required');
    return false;
  }

  if (references.length > 5) {
    errors.push('Maximum of 5 references allowed');
    return false;
  }

  if (personalRefs < 1) {
    errors.push('At least one personal reference is required');
    return false;
  }

  if (professionalRefs < 1) {
    errors.push('At least one professional reference is required');
    return false;
  }

  // Validate each reference
  for (const ref of references) {
    // Validate 5-year relationship
    const knownSinceDate = new Date(ref.knownSince);
    const fiveYearsAgo = new Date();
    fiveYearsAgo.setFullYear(fiveYearsAgo.getFullYear() - 5);

    if (knownSinceDate > fiveYearsAgo) {
      errors.push('References must have known you for at least 5 years');
      return false;
    }

    // Check if reference is family member
    if (
      ref.surname === personalInfo.surname ||
      (personalInfo.spouse && ref.surname === personalInfo.spouse.surname)
    ) {
      if (ref.address === residentialHistory[0]?.address) {
        errors.push('References cannot be family members or co-habitants');
        return false;
      }
    }

    // Validate required fields
    const requiredFields = {
      surname: "Surname",
      givenName: "Given name",
      relationship: "Relationship",
      knownSince: "Known since date",
      telephone: "Telephone number", 
      email: "Email address",
      type: "Reference type"
    };

    for (const [field, label] of Object.entries(requiredFields)) {
      if (!ref[field]?.trim()) {
        errors.push(`${label} is required for reference ${index + 1}`);
        return false;
      }
    }
  }

  return true;
}
