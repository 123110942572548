<template>
  <div class="file-upload-section border rounded-md p-4 bg-gray-50">
    <div class="flex justify-between items-start mb-3">
      <div>
        <h3 class="font-medium text-gray-900">{{ title }}
          <span v-if="required" class="text-red-600">*</span>
        </h3>
        <p class="text-sm text-gray-500 mb-2">{{ description }}</p>
      </div>
      <span class="text-sm text-gray-500">{{ uploadedFiles.length }}/{{ maxFiles }}</span>
      <button v-if="hasFilesToUpload" @click="uploadAllFiles" type="button"
        class="inline-flex cursor-pointer items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600">
        <span v-if="isUploading">
          <svg class="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
            </path>
          </svg>
          Uploading...
        </span>
        <span v-else>Upload All</span>
      </button>
    </div>

    <div v-if="fileUploadErrors.length > 0" class="mb-4 bg-red-50 border-l-4 border-red-500 p-4 rounded">
      <div class="flex items-start">
        <div class="flex-shrink-0">
          <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 001.414 1.414L10 11.414l1.293 1.293a1 1 001.414-1.414L11.414 10l1.293-1.293a1 1 00-1.414-1.414L10 8.586 8.707 7.293z"
              clip-rule="evenodd" />
          </svg>
        </div>
        <div class="ml-3">
          <p v-for="(error, index) in fileUploadErrors" :key="index" class="text-sm text-red-700">{{ error }}</p>
        </div>
      </div>
    </div>

    <div v-if="uploadedFiles.length > 0" class="mb-4">
      <ul class="divide-y divide-gray-200">
        <li v-for="(file, index) in uploadedFiles" :key="index" class="py-3">
          <div class="flex items-center justify-between">
            <div class="flex items-center flex-1 min-w-0">
              <DocumentIcon class="h-5 w-5 text-gray-400 mr-2 flex-shrink-0" />
              <div class="flex-1 min-w-0">
                <p class="text-sm font-medium text-gray-900 truncate" :title="file.name">{{ file.name }}</p>
                <p class="text-xs text-gray-500">{{ formatFileSize(file.file?.size) }}</p>
              </div>
              <div class="ml-2 flex-shrink-0">
                <span v-if="file.uploading" class="text-xs text-blue-600">Uploading...</span>
                <span v-else-if="file.id" class="text-xs text-green-600">Uploaded</span>
                <span v-else-if="file.error" class="text-xs text-red-600">Failed</span>
              </div>
            </div>
            <button @click="removeFile(index)"
              class="ml-4 text-red-600 hover:text-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              type="button">
              <TrashIcon class="h-4 w-4" />
            </button>
          </div>
          <div v-if="file.progress > 0 && file.progress < 100" class="mt-1 relative">
            <div class="h-1 bg-gray-200 rounded-full">
              <div class="absolute top-0 h-1 bg-blue-600 rounded-full" :style="{ width: `${file.progress}%` }"></div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div v-if="uploadedFiles.length < maxFiles">
      <label ref="fileDropArea"
        class="block w-full cursor-pointer border-2 border-dashed border-gray-300 rounded-lg p-6 text-center hover:border-gray-400">
        <div class="flex flex-col items-center">
          <CloudArrowUpIcon class="mx-auto h-12 w-12 text-gray-400" />
          <p class="mt-1 text-sm text-gray-600">
            <span class="font-medium text-blue-600">Upload files</span> or drag and drop
          </p>
          <p class="text-xs text-gray-500">{{ accept.replace(/\./g, '').toUpperCase() }} up to 10MB</p>
        </div>
        <input type="file" @change="handleFileSelect" class="hidden" :accept="accept" :multiple="multiple" />
      </label>
    </div>

    <!-- Show loading indicator -->
    <div v-if="isLoadingFiles" class="flex justify-center py-4">
      <svg class="animate-spin h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div>

    <!-- Show existing files -->
    <div v-else-if="userFiles.length > 0" class="space-y-2">
      <div v-for="file in userFiles" :key="file.path" class="flex items-center justify-between p-2 bg-white rounded-lg shadow-sm">
        <div class="flex items-center space-x-2">
          <DocumentIcon class="h-5 w-5 text-gray-400" />
          <span class="text-sm font-medium text-gray-700">{{ file.name }}</span>
        </div>
        <button @click="deleteFile(file.path)" class="text-red-600 hover:text-red-800">
          <TrashIcon class="h-4 w-4" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue';
import { useInternalAuthStore } from '../stores/internalAuth';
import { useSecurityAssessmentStore } from '../stores/securityAssessment';
import { CloudArrowUpIcon, TrashIcon, DocumentIcon } from '@heroicons/vue/24/outline';

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => []
  },
  title: {
    type: String,
    required: true
  },
  description: {
    type: String,
    default: ''
  },
  required: {
    type: Boolean,
    default: false
  },
  accept: {
    type: String,
    default: '.pdf,.jpg,.jpeg,.png'
  },
  multiple: {
    type: Boolean,
    default: false
  },
  maxFiles: {
    type: Number,
    default: 5
  },
  assessmentId: {
    type: String,
    default: null
  },
  documentType: {
    type: String,
    required: true
  },
  userId: {
    type: String,
    required: true
  },
  category: {
    type: String,
    default: 'general'
  }
});

// Optionally, you could add an "autoUpload" prop if needed.
// const autoUpload = defineProps({ autoUpload: { type: Boolean, default: false } });

const emit = defineEmits(['update:modelValue']);

const authStore = useInternalAuthStore();
const securityStore = useSecurityAssessmentStore();
const uploadedFiles = ref(props.modelValue || []);
const fileUploadErrors = ref([]);
const fileDropArea = ref(null);
const isUploading = ref(false);
const isLoadingFiles = ref(false);
const userFiles = ref([]);

// Watch for external changes to modelValue
watch(() => props.modelValue, (newVal) => {
  uploadedFiles.value = newVal || [];

});

// Watch for internal changes to uploadedFiles and emit changes
watch(uploadedFiles, (newVal) => {
  emit('update:modelValue', newVal);
}, { deep: true });

const hasFilesToUpload = computed(() => {
  return uploadedFiles.value.some(file => !file.id && !file.uploading);
});

// --- Drag and Drop Handlers and cleanup ---
const eventHandlers = []; // to store event details for removal

const preventDefaults = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

const highlight = () => {
  fileDropArea.value?.classList.add('border-blue-500');
};

const unhighlight = () => {
  fileDropArea.value?.classList.remove('border-blue-500');
};

const handleDrop = (e) => {
  const dt = e.dataTransfer;
  const files = dt.files;
  handleFiles(files);
};

const setupDragAndDrop = () => {
  const dropArea = fileDropArea.value;
  if (!dropArea) return;

  const events = ['dragenter', 'dragover', 'dragleave', 'drop'];
  events.forEach(eventName => {
    dropArea.addEventListener(eventName, preventDefaults, false);
    eventHandlers.push({ eventName, handler: preventDefaults });
  });

  ['dragenter', 'dragover'].forEach(eventName => {
    dropArea.addEventListener(eventName, highlight, false);
    eventHandlers.push({ eventName, handler: highlight });
  });

  ['dragleave', 'drop'].forEach(eventName => {
    dropArea.addEventListener(eventName, unhighlight, false);
    eventHandlers.push({ eventName, handler: unhighlight });
  });

  dropArea.addEventListener('drop', handleDrop, false);
  eventHandlers.push({ eventName: 'drop', handler: handleDrop });
};

onMounted(async () => {
  setupDragAndDrop();
  await loadUserFiles();
  
  // Initialize empty array for uploads if needed
  if (!props.modelValue?.length) {
    emit('update:modelValue', []);
  }
});

onBeforeUnmount(() => {
  const dropArea = fileDropArea.value;
  if (dropArea) {
    eventHandlers.forEach(({ eventName, handler }) => {
      dropArea.removeEventListener(eventName, handler, false);
    });
  }
});

// --- File Handling and Validation ---
const handleFiles = (files) => {
  if (!files.length) return;
  fileUploadErrors.value = []; // Clear previous errors

  // Process each file
  Array.from(files).forEach(file => {
    // Check if adding this file would exceed maxFiles
    if (uploadedFiles.value.length >= props.maxFiles) {
      fileUploadErrors.value.push(`You can only upload up to ${props.maxFiles} files`);
      return;
    }

    // Prevent duplicate files
    const isDuplicate = uploadedFiles.value.some(existing => 
      existing.name === file.name && existing.file?.size === file.size
    );
    if (isDuplicate) {
      fileUploadErrors.value.push(`${file.name} has already been added`);
      return;
    }

    // Validate file size (10MB limit)
    if (file.size > 10 * 1024 * 1024) {
      fileUploadErrors.value.push(`${file.name} exceeds the 10MB size limit`);
      return;
    }

    // Validate file type
    const fileExt = `.${file.name.split('.').pop().toLowerCase()}`;
    const acceptedTypes = props.accept.split(',').map(type => type.trim().toLowerCase());
    if (!acceptedTypes.includes(fileExt)) {
      fileUploadErrors.value.push(`${file.name} has an invalid file type. Accepted: ${props.accept}`);
      return;
    }

    // Add file to upload queue
    uploadedFiles.value.push({
      file,
      name: file.name,
      progress: 0,
      uploading: false,
      id: null,
      error: null
    });
  });
};

const handleFileSelect = (event) => {
  const files = event.target.files;
  handleFiles(files);
  // Reset file input
  event.target.value = '';
};

const removeFile = (index) => {
  uploadedFiles.value.splice(index, 1);
};

const uploadFile = async (fileObj, index) => {
  if (fileObj.id || fileObj.uploading) return;

  try {
    uploadedFiles.value[index] = {
      ...fileObj,
      uploading: true,
      progress: 0,
      error: null
    };

    const response = await securityStore.uploadDocument(
      props.assessmentId,
      {
        file: fileObj.file,
        type: props.documentType,
        description: props.title
      }
    );

    uploadedFiles.value[index] = {
      ...fileObj,
      uploading: false,
      progress: 100,
      id: response.fileId,
      url: response.url,
      path: response.path,
      type: props.documentType,
      error: null
    };

  } catch (error) {
    uploadedFiles.value[index] = {
      ...fileObj,
      uploading: false,
      progress: 0,
      error: error.message || 'Upload failed'
    };
    fileUploadErrors.value.push(`Failed to upload ${fileObj.name}: ${error.message || 'Unknown error'}`);
  }
};

const uploadAllFiles = async () => {
  fileUploadErrors.value = [];
  isUploading.value = true;

  try {
    const filesToUpload = uploadedFiles.value.filter(file => !file.id && !file.uploading);
    
    for (const fileObj of filesToUpload) {
      const fileIndex = uploadedFiles.value.findIndex(f => f === fileObj);
      if (fileIndex !== -1) {
        await uploadFile(fileObj, fileIndex);
      }
    }
  } catch (error) {
    console.error('Batch upload failed:', error);
    fileUploadErrors.value.push('One or more files failed to upload');
  } finally {
    isUploading.value = false;
  }
};

const formatFileSize = (bytes) => {
  if (!bytes) return '';
  const units = ['B', 'KB', 'MB', 'GB'];
  let size = bytes;
  let unitIndex = 0;
  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }
  return `${size.toFixed(1)} ${units[unitIndex]}`;
};

// Load user's files
async function loadUserFiles() {
  isLoadingFiles.value = true;
  try {
    const files = await securityStore.listUserFiles(props.category);
    userFiles.value = files;
  } catch (error) {
    console.error('Failed to load files:', error);
  } finally {
    isLoadingFiles.value = false;
  }
}

// Delete a file
async function deleteFile(filePath) {
  try {
    await securityStore.deleteUserFile(filePath);
    // Refresh file list
    await loadUserFiles();
  } catch (error) {
    console.error('Failed to delete file:', error);
    fileUploadErrors.value.push(`Failed to delete file: ${error.message}`);
  }
}
</script>
