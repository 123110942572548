export function validateTravelHistory(travelRecords = [], errors = []) {
  if (!travelRecords || travelRecords.length === 0) {
    return true; // Travel history is optional
  }

  for (const record of travelRecords) {
    // Validate required fields
    if (!record.country) {
      errors.push('Country is required for each travel record');
      return false;
    }

    if (!record.fromDate || !record.toDate) {
      errors.push('Travel dates are required');
      return false;
    }

    // Validate date format and logic
    const fromDate = new Date(record.fromDate);
    const toDate = new Date(record.toDate);

    if (isNaN(fromDate.getTime()) || isNaN(toDate.getTime())) {
      errors.push('Invalid travel date format');
      return false;
    }

    if (fromDate > toDate) {
      errors.push('Travel from date cannot be after to date');
      return false;
    }

    // Validate weeks spent
    if (record.weeksSpent) {
      const weeksSpent = parseInt(record.weeksSpent);
      if (isNaN(weeksSpent) || weeksSpent < 0) {
        errors.push('Weeks spent must be a positive number');
        return false;
      }

      // Calculate actual weeks between dates
      const actualWeeks = Math.ceil((toDate - fromDate) / (7 * 24 * 60 * 60 * 1000));
      if (weeksSpent > actualWeeks) {
        errors.push('Weeks spent cannot exceed the actual travel duration');
        return false;
      }
    }

    // Validate purpose
    if (!record.purpose?.trim()) {
      errors.push('Purpose of travel is required');
      return false;
    }
  }

  return true;
}
