<template>
  <div class="bg-gray-50/50">
    <h2 class="text-xl md:text-2xl font-bold mb-4 text-gray-800">Citizenship & Origin</h2>
    <SectionInstructions sectionTitle="Citizenship & Origin" sectionId="citizenship" />
    <div class="space-y-6 md:space-y-8">
      <!-- Multiple Citizenships -->
      <div class=" ">
        <label class="block text-sm font-medium text-gray-700 mb-2">
          List all citizenships held <span class="text-red-600">*</span>
        </label>
        <div class="grid grid-cols-1 gap-3">
          <div v-for="(citizenship, index) in modelValue.citizenship.citizenships" :key="index" class="flex gap-2">
           
            <input v-model="citizenship.country" type="text" required
              class="block w-full border rounded-md shadow-sm py-2 px-3" placeholder="Enter citizenship"
              @change="$emit('update:modelValue', modelValue)" />
            <button v-if="index > 0" @click="removeCitizenship(index)" class="p-2 text-red-600 hover:text-red-800">
              <XMarkIcon class="h-5 w-5" />
            </button>
          </div>
          <button v-if="modelValue.citizenship.citizenships.length < 5" @click="addCitizenship"
            class="flex items-center gap-2 text-blue-600 hover:text-blue-800 px-3 py-2">
            <PlusIcon class="h-5 w-5" />
            Add More
          </button>
        </div>
      </div>

      <!-- Canadian Application Type -->
      <div v-if="modelValue.applicationType === 'canadian'" class="space-y-4 md:space-y-6">

        <!-- Permanent Resident Status -->
        <div class="">
          <label class="block text-sm md:text-base font-medium text-gray-700 mb-3">
            If you hold permanent resident status in any country, list the country:
          </label>
          <input v-model="modelValue.citizenship.permanentResidentCountry" type="text"
            class="mt-1 block w-full border rounded-lg shadow-sm py-3 px-4 text-base"
            @change="$emit('update:modelValue', modelValue)" />
        </div>

        <!-- Birth Information -->
        <div class="space-y-4">
          <div class="  ">
            <label class="block text-sm md:text-base font-medium text-gray-700 mb-3">
              Were you born in Canada? <span class="text-red-600">*</span>
            </label>
            <div class="space-y-2 md:space-x-6 md:space-y-0">
              <label class="flex items-center md:inline-flex p-2 cursor-pointer">
                <input type="radio" v-model="modelValue.citizenship.bornInCanada" :value="true"
                  class="form-radio h-5 w-5 text-blue-600 focus:ring-blue-500"
                  @change="$emit('update:modelValue', modelValue)" />
                <span class="ml-3 text-base text-gray-700">Yes</span>
              </label>
              <label class="flex items-center md:inline-flex p-2 cursor-pointer">
                <input type="radio" v-model="modelValue.citizenship.bornInCanada" :value="false"
                  class="form-radio h-5 w-5 text-blue-600 focus:ring-blue-500"
                  @change="$emit('update:modelValue', modelValue)" />
                <span class="ml-3 text-base text-gray-700">No</span>
              </label>
            </div>
          </div>

          <!-- Birth Details -->
          <div v-if="modelValue.citizenship.bornInCanada !== null"
            class="  ">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
              <div class="space-y-2">
                <label class="block text-sm md:text-base font-medium text-gray-700">
                  City of birth <span class="text-red-600">*</span>
                </label>
                <input v-model="modelValue.citizenship.cityOfBirth" type="text" required
                  class="block w-full border rounded-lg shadow-sm py-3 px-4 text-base"
                  @change="$emit('update:modelValue', modelValue)" />
              </div>

              <div class="space-y-2">
                <label class="block text-sm md:text-base font-medium text-gray-700">
                  Province / Territory of birth <span class="text-red-600">*</span>
                </label>
                <input v-model="modelValue.citizenship.provinceOfBirth" type="text" required
                  class="block w-full border rounded-lg shadow-sm py-3 px-4 text-base"
                  @change="$emit('update:modelValue', modelValue)" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- International Application Type -->
      <div v-else class="space-y-4 md:space-y-6">
        <div class="">
          <!-- Permanent Resident Status -->
          <div>
            <label class="block text-sm font-medium text-gray-700">
              If you hold permanent resident status in any country, list the country:
            </label>
            <input v-model="modelValue.citizenship.permanentResidentCountry" type="text"
              class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3"
              @change="$emit('update:modelValue', modelValue)" />
          </div>

          <!-- Birth Information -->
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label class="block text-sm font-medium text-gray-700">
                Municipality and country of birth <span class="text-red-600">*</span>
              </label>
              <input v-model="modelValue.citizenship.countryOfBirth" type="text" required
                class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3"
                @change="$emit('update:modelValue', modelValue)" />
            </div>


          </div>

          <!-- Entry Information -->
          <div class="bg-gray-50 space-y-4 mt-4">
            <div>
              <label class="block text-sm font-medium text-gray-700">
                Country of departure <span class="text-red-600">*</span>
              </label>
              <input v-model="modelValue.citizenship.countryOfEmigration" type="text" required
                class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3"
                @change="$emit('update:modelValue', modelValue)" />
            </div>

            <div>
              <label class="block text-sm font-medium text-gray-700">
                Port of entry <span class="text-red-600">*</span>
              </label>
              <input v-model="modelValue.citizenship.portOfEntry" type="text" required
                class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3"
                @change="$emit('update:modelValue', modelValue)" />
            </div>

            <div>
              <label class="block text-sm font-medium text-gray-700">
                First date of entry into Canada <span class="text-red-600">*</span>
              </label>
              <input v-model="modelValue.citizenship.dateOfEntry" type="date" required
                class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3"
                @change="$emit('update:modelValue', modelValue)" />
            </div>
          </div>

          <!-- List of valid passports -->
          <div class="mt-4">
            <label class="block text-sm font-medium text-gray-700 mb-2">
              List all valid passports held <span class="text-red-600">*</span>
            </label>
            <div class="grid grid-cols-1 gap-3">
              <div v-for="(passport, index) in modelValue.citizenship.foreignPassports" :key="index"
                class="flex flex-col gap-3">
                <div class="flex items-center gap-2">
                  <span class="text-sm font-medium w-6">{{ String.fromCharCode(97 + index) }}.</span>
                  <input v-model="passport.number" type="text" required
                    class="block w-full border rounded-md shadow-sm py-2 px-3" placeholder="Passport Number"
                    @change="$emit('update:modelValue', modelValue)" />
                  <button v-if="index > 0" @click="removePassport(index)" class="p-2 text-red-600 hover:text-red-800">
                    <XMarkIcon class="h-5 w-5" />
                  </button>
                </div>
                <div class="ml-8 grid grid-cols-1 md:grid-cols-2 gap-3">
                 
                  <input v-model="passport.country" type="text" required
                    class="block w-full border rounded-md shadow-sm py-2 px-3" placeholder="Country of Issuance"
                    @change="$emit('update:modelValue', modelValue)" />
                  <input v-model="passport.number" type="text" required
                    class="block w-full border rounded-md shadow-sm py-2 px-3" placeholder="Passport Number"
                    @change="$emit('update:modelValue', modelValue)" />
                  <input v-model="passport.dateOfIssuance" type="date" required
                    class="block w-full border rounded-md shadow-sm py-2 px-3" placeholder="Date of Issuance"
                    @change="$emit('update:modelValue', modelValue)" />
                  <input v-model="passport.nameOnPassport" type="text" required
                    class="block w-full border rounded-md shadow-sm py-2 px-3" placeholder="Name as shown on passport"
                    @change="$emit('update:modelValue', modelValue)" />
                  <input v-model="passport.expiryDate" type="date" required
                    class="block w-full border rounded-md shadow-sm py-2 px-3" placeholder="Expiry Date"
                    @change="$emit('update:modelValue', modelValue)" />
                </div>
              </div>
              <button v-if="modelValue.citizenship.foreignPassports.length < 5" @click="addPassport"
                class="flex items-center gap-2 text-blue-600 hover:text-blue-800 px-3 py-2">
                <PlusIcon class="h-5 w-5" />
                Add More
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
input[type="text"],
input[type="date"] {
  @apply  border-gray-300 focus:border-blue-500 focus:ring-blue-500 transition-colors duration-200;
}

input[type="text"]::placeholder {
  @apply text-gray-400;
}

.form-radio {
  @apply border-gray-300 focus:ring-2 focus:ring-offset-2 transition-all duration-200;
}

. {
  @apply transition-all duration-200 ease-in-out hover:bg-gray-50;
}

/* Mobile optimizations */
@media (max-width: 768px) {
  input[type="text"],
  input[type="date"] {
    @apply text-base py-3; /* Larger touch targets on mobile */
  }

  .form-radio {
    @apply h-5 w-5; /* Larger radio buttons on mobile */
  }
}

/* Smooth transitions */
.space-y-6 > * {
  @apply transition-all duration-200;
}
</style>

<script setup>
import SectionInstructions from '../../SectionInstructions.vue';
import { PlusIcon, XMarkIcon } from '@heroicons/vue/24/outline';

const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  }
});

const emit = defineEmits(['update:modelValue']);

// Initialize citizenships array if it doesn't exist
if (!props.modelValue.citizenship.citizenships) {
  props.modelValue.citizenship.citizenships = [{ country: '' }];
}

// Initialize foreignPassports array if it doesn't exist
if (!props.modelValue.citizenship.foreignPassports) {
  props.modelValue.citizenship.foreignPassports = [{
    number: '',
    nameOnPassport: '',
    country: '',
    dateOfIssuance: '',
    expiryDate: ''
  }];
}

const addCitizenship = () => {
  if (props.modelValue.citizenship.citizenships.length < 5) {
    props.modelValue.citizenship.citizenships.push({ country: '' });
    emit('update:modelValue', props.modelValue);
  }
};

const removeCitizenship = (index) => {
  props.modelValue.citizenship.citizenships.splice(index, 1);
  emit('update:modelValue', props.modelValue);
};

const addPassport = () => {
  if (props.modelValue.citizenship.foreignPassports.length < 5) {
    props.modelValue.citizenship.foreignPassports.push({
      number: '',
      nameOnPassport: '',
      country: '',
      dateOfIssuance: '',
      expiryDate: ''
    });
    emit('update:modelValue', props.modelValue);
  }
};

const removePassport = (index) => {
  props.modelValue.citizenship.foreignPassports.splice(index, 1);
  emit('update:modelValue', props.modelValue);
};
</script>