<template>
  <div class="max-w-7xl mx-auto p-4 md:p-6 bg-white rounded-md shadow-lg">
    <div class="flex justify-between items-center mb-6">
      <h2 class="text-2xl font-semibold">Policy Acceptance Status</h2>
      <div v-if="isLoading" class="text-gray-500">Loading...</div>
    </div>

    <div class="mb-6 flex flex-col md:flex-row gap-2">
      <button @click="currentFilter = 'all'" :class="{
        'px-4 py-2 rounded-md': true,
        'bg-blue-600 text-white': currentFilter === 'all',
        'bg-gray-200 text-gray-700': currentFilter !== 'all'
      }">
        All Users
      </button>
      <button @click="currentFilter = 'accepted'" :class="{
        'px-4 py-2 rounded-md': true,
        'bg-blue-600 text-white': currentFilter === 'accepted',
        'bg-gray-200 text-gray-700': currentFilter !== 'accepted'
      }">
        Accepted
      </button>
      <button @click="currentFilter = 'not_accepted'" :class="{
        'px-4 py-2 rounded-md': true,
        'bg-blue-600 text-white': currentFilter === 'not_accepted',
        'bg-gray-200 text-gray-700': currentFilter !== 'not_accepted'
      }">
        Not Accepted
      </button>
    </div>

    <div v-if="error" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
      {{ error }}
    </div>

    <div class="overflow-x-auto">
      <table class="min-w-full bg-white">
        <thead>
          <tr>
            <th class="py-3 px-4 border-b border-gray-200 bg-gray-100 text-left font-semibold text-gray-700">Name</th>
            <th class="py-3 px-4 border-b border-gray-200 bg-gray-100 text-left font-semibold text-gray-700">Email</th>
            <th class="py-3 px-4 border-b border-gray-200 bg-gray-100 text-left font-semibold text-gray-700">Role</th>
            <th class="py-3 px-4 border-b border-gray-200 bg-gray-100 text-left font-semibold text-gray-700">Policy
              Accepted</th>
            <th class="py-3 px-4 border-b border-gray-200 bg-gray-100 text-left font-semibold text-gray-700">Accepted At
            </th>
            <th class="py-3 px-4 border-b border-gray-200 bg-gray-100 text-left font-semibold text-gray-700">Kurtz
              Status</th>
            <th class="py-3 px-4 border-b border-gray-200 bg-gray-100 text-left font-semibold text-gray-700">Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in filteredUsers" :key="user.id" class="hover:bg-gray-50">
            <td class="py-3 px-4 border-b border-gray-200">
              <div class="flex flex-col">
                <span class="font-medium">{{ user.firstName }} {{ user.lastName }}</span>
                <span class="text-xs text-gray-500">ID: {{ user.id }}</span>
              </div>
            </td>
            <td class="py-3 px-4 border-b border-gray-200">
              <span class="text-sm text-gray-600">{{ user.email }}</span>
            </td>
            <td class="py-3 px-4 border-b border-gray-200">
              <span :class="{
                'px-2 py-1 rounded-full text-xs font-medium': true,
                'bg-purple-100 text-purple-800': user.role === 'admin',
                'bg-blue-100 text-blue-800': user.role === 'employee',
                'bg-green-100 text-green-800': user.role === 'owner'
              }">
                {{ capitalizeFirst(user.role) }}
              </span>
            </td>
            <td class="py-3 px-4 border-b border-gray-200">
              <div class="flex flex-col">
                <span :class="{
                  'px-2 py-1 rounded text-sm font-medium': true,
                  'bg-green-100 text-green-800': user.policyAccepted,
                  'bg-red-100 text-red-800': !user.policyAccepted
                }">
                  {{ user.policyAccepted ? 'Yes' : 'No' }}
                </span>
              </div>
            </td>
            <td class="py-3 px-4 border-b border-gray-200">
              <div class="flex flex-col">
                <span class="text-sm">{{ formatDate(user.policyAcceptedAt) }}</span>
                <span class="text-xs text-gray-500">Version: {{ user.policyVersion || 'N/A' }}</span>
              </div>
            </td>
            <td class="py-3 px-4 border-b border-gray-200">
              <span :class="{
                'px-2 py-1 rounded-full text-xs font-medium': true,
                'bg-green-100 text-green-800': user.kurtzStatus === 'completed',
                'bg-yellow-100 text-yellow-800': user.kurtzStatus === 'pending',
                'bg-gray-100 text-gray-800': !user.kurtzStatus
              }">
                {{ user.kurtzStatus || 'Not Started' }}
              </span>
            </td>
            <td class="py-3 px-4 border-b border-gray-200">
          
              <div class="flex space-x-2">
                <button v-if="!user.policyAccepted" @click="sendMagicLink(user)" :disabled="user.isLoading"
                  class="text-blue-600 hover:text-blue-900 disabled:opacity-50">
                  <span v-if="user.isLoading">Sending...</span>
                  <span v-else>Send Magic Link</span>
                </button>
                <router-link v-if="user.kurtzResultId" :to="`/kurtz-results/${user.kurtzResultId}`"
                  class="text-purple-600 hover:text-purple-900">
                  View Kurtz Result
                </router-link>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useInternalAuthStore } from '@/stores/internalAuth';
import { useRouter } from 'vue-router';

const authStore = useInternalAuthStore();
const router = useRouter();

const currentFilter = ref('all');
const users = ref([]);
const isLoading = ref(false);
const error = ref(null);

const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  return new Date(dateString).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
};

const sendMagicLink = async (user) => {
  try {
    user.isLoading = true;
    await authStore.sendMagicURL(user.email);
    alert(`Magic link sent to ${user.email}`);
  } catch (err) {
    console.error('Failed to send magic link:', err);
    alert('Failed to send magic link. Please try again.');
  } finally {
    user.isLoading = false;
  }
};

const fetchUsers = async () => {
  isLoading.value = true;
  error.value = null;
  try {
    const data = await authStore.fetchEmployeePolicyStatus();
    if (data.status !== 'ok') {
      error.value = data.error || 'Error fetching data';
    } else {
      // Add isLoading property to each user
      users.value = data.employees.map(user => ({
        ...user,
        isLoading: false,
        kurtzStatus: user.kurtzStatus || 'pending',
        kurtzResultId: user.kurtzResultId
      }));
    }
  } catch (err) {
    error.value = 'Failed to load employee acceptance status';
  } finally {
    isLoading.value = false;
  }
};

const filteredUsers = computed(() => {
  switch (currentFilter.value) {
    case 'accepted':
      return users.value.filter(u => u.policyAccepted);
    case 'not_accepted':
      return users.value.filter(u => !u.policyAccepted);
    default:
      return users.value;
  }
});

const capitalizeFirst = (str) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

onMounted(async () => {
  if (!authStore.isAdminUser) {
    router.push('/');
    return;
  }
  await fetchUsers();
});
</script>

<style scoped>
.table {
  @apply min-w-full bg-white;
}

.table th,
.table td {
  @apply py-2 px-4 border-b border-gray-200;
}

.table th {
  @apply bg-gray-100 text-left text-sm leading-4 font-medium text-gray-700 uppercase tracking-wider;
}

.table td {
  @apply text-sm;
}
</style>