<template>
  <div>
    <h2 class="text-xl font-bold mb-4">Residential History</h2>

    <!-- Improved Instructions -->
    <div class="bg-blue-50 p-4 rounded-lg mb-6 border border-blue-200">
      <h3 class="font-bold text-blue-800 mb-2">Requirements:</h3>
      <ul class="list-disc pl-5 text-blue-800">
        <li>List all addresses for the past 5 years with no gaps</li>
        <li>Start with your current address</li>
        <li>Include apartment/unit numbers if applicable</li>
        <li>If you had multiple addresses at once, include all with overlapping dates</li>
      </ul>
    </div>

    <!-- Responsive View -->
    <div class="mb-6">
      <!-- Mobile Card View -->
      <div class="md:hidden">
        <div v-for="(residence, index) in modelValue.residentialHistory" :key="index"
          class="mb-4 bg-white rounded-lg shadow border"
          :class="{ 'border-green-200 bg-green-50': residence.isPresent }">
          <div class="p-4">
            <div class="flex justify-between items-start mb-3">
              <div class="flex-1">
                <div class="text-sm font-medium text-gray-900 whitespace-pre-wrap">
                  {{ getFullAddress(residence) }}
                </div>
              </div>
              <span v-if="residence.isPresent"
                class="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                Current
              </span>
            </div>

            <div class="flex justify-between text-sm text-gray-600 mb-3">
              <div>
                <div>From: {{ formatMonth(residence.fromDate) }}</div>
                <div>To: {{ residence.isPresent ? 'Present' : formatMonth(residence.toDate) }}</div>
              </div>
            </div>

            <div class="flex justify-end space-x-3 border-t pt-3">
              <button @click="editAddress(index)" class="text-blue-600 hover:text-blue-900 text-sm font-medium">
                Edit
              </button>
              <button @click="removeAddress(index)" v-if="modelValue.residentialHistory.length > 1"
                class="text-red-600 hover:text-red-900 text-sm font-medium">
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Desktop Table View -->
      <div class="hidden md:block overflow-x-auto">
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Full Address</th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                From</th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">To
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status</th>
              <th scope="col" class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions</th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="(residence, index) in modelValue.residentialHistory" :key="index"
              :class="{ 'bg-green-50': residence.isPresent, 'hover:bg-gray-50': !residence.isPresent }">
              <td class="px-6 py-4">
                <div class="text-sm text-gray-900 whitespace-pre-wrap">{{ getFullAddress(residence) }}</div>
                <div v-if="residence.isPresent && (!residence.proofFiles || residence.proofFiles.length === 0)"
                  class="text-xs text-red-600 mt-1">
                  Proof of address required
                </div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="text-sm text-gray-900">{{ formatMonth(residence.fromDate) }}</div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="text-sm text-gray-900">{{ residence.isPresent ? 'Present' : formatMonth(residence.toDate) }}
                </div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <span v-if="residence.isPresent"
                  class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                  Current
                </span>
                <span v-else
                  class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                  Previous
                </span>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <button @click="editAddress(index)" class="text-blue-600 hover:text-blue-900 mr-3">
                  Edit
                </button>
                <button @click="removeAddress(index)" v-if="modelValue.residentialHistory.length > 1"
                  class="text-red-600 hover:text-red-900">
                  Remove
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <button type="button" @click="addAddress"
        class="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700">
        Add Address
      </button>

      <!-- Validation Messages -->
      <div v-if="validationErrors.length > 0" class="mt-4 p-4 bg-red-50 border border-red-200 rounded">
        <h4 class="font-medium text-red-700 mb-2">Please correct the following issues:</h4>
        <ul class="list-disc pl-5 text-red-700 text-sm">
          <li v-for="(error, i) in validationErrors" :key="i">{{ error }}</li>
        </ul>
      </div>
    </div>

    <!-- Edit Modal -->
    <div v-if="showEditModal"
      class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-50"
      @click.self="closeEditModal">
      <div class="relative bg-white md:rounded-lg shadow-xl w-full md:max-w-2xl h-full md:h-auto md:mx-4" @click.stop>
        <!-- Modal Header -->
        <div class="sticky top-0 bg-white z-10 px-4 py-3 md:p-4 border-b flex items-center justify-between">
          <h3 class="text-lg font-medium leading-6">
            {{ editingResidence.isPresent ? 'Edit Current Address' : 'Edit Previous Address' }}
          </h3>
          <button @click.prevent="closeEditModal" type="button" class="text-gray-400 hover:text-gray-600 p-2 -mr-2">
            <span class="sr-only">Close</span>
            <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <!-- Modal Body -->
        <div class="p-4 md:p-6 overflow-y-auto" style="height: calc(100% - 136px);">
          <!-- Address Search -->
          <div class="mb-6">
            <label class="block text-sm font-medium text-gray-700 mb-1">
              Search Address
            </label>
            <div class="relative">
              <input type="text" v-model="addressSearch" @input="handleAddressSearch"
                placeholder="Start typing to search address..."
                class="block w-full border rounded-md shadow-sm py-3 md:py-2 px-3 text-base md:text-sm" />
              <!-- Loading indicator -->
              <div v-if="isLoading" class="absolute right-2 top-2">
                <svg class="animate-spin h-5 w-5 text-gray-400" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"
                    fill="none" />
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z" />
                </svg>
              </div>

              <!-- Search Results -->
              <div v-if="searchResults.length > 0"
                class="absolute z-10 mt-1 w-full bg-white shadow-lg rounded-md border border-gray-200 max-h-60 overflow-auto">
                <ul class="py-1">
                  <li v-for="(result, index) in searchResults" :key="index" @click="selectAddress(result)"
                    class="px-3 py-2 hover:bg-gray-100 cursor-pointer text-sm">
                    {{ result.fullAddress }}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="space-y-4 md:grid md:grid-cols-2 md:gap-4 md:space-y-0">
            <!-- Address fields - update input classes for better touch targets -->
            <div>
              <label class="block text-sm font-medium text-gray-700 mb-1">
                Apartment/Unit Number (if applicable)
              </label>
              <input v-model="editingResidence.apartmentNumber" type="text"
                class="block w-full border rounded-md shadow-sm py-3 md:py-2 px-3 text-base md:text-sm" />
            </div>

            <div>
              <label class="block text-sm font-medium text-gray-700 mb-1">
                Street Number <span class="text-red-600">*</span>
              </label>
              <input v-model="editingResidence.streetNumber" type="text"
                class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3" required />
            </div>

            <div>
              <label class="block text-sm font-medium text-gray-700 mb-1">
                Street Name <span class="text-red-600">*</span>
              </label>
              <input v-model="editingResidence.streetName" type="text"
                class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3" required />
            </div>

            <div>
              <label class="block text-sm font-medium text-gray-700 mb-1">
                City <span class="text-red-600">*</span>
              </label>
              <input v-model="editingResidence.city" type="text"
                class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3" required />
            </div>

            <div>
              <label class="block text-sm font-medium text-gray-700 mb-1">
                Province/Territory <span class="text-red-600">*</span>
              </label>
              <input v-model="editingResidence.provinceTerritory" type="text"
                class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3" required />
            </div>

            <div>
              <label class="block text-sm font-medium text-gray-700 mb-1">
                Country <span class="text-red-600">*</span>
              </label>
              <input v-model="editingResidence.country" type="text"
                class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3" required />
            </div>

            <div>
              <label class="block text-sm font-medium text-gray-700 mb-1">
                Postal Code <span class="text-red-600">*</span>
              </label>
              <input v-model="editingResidence.postalCode" type="text"
                class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3" required />
            </div>

            <div>
              <label class="block text-sm font-medium text-gray-700 mb-1">
                From Date (Month/Year) <span class="text-red-600">*</span>
              </label>
              <input v-model="editingResidence.fromDate" type="month" :max="getCurrentYearMonth()"
                class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3" required />
            </div>

            <div>
              <label class="block text-sm font-medium text-gray-700 mb-1">
                To Date (Month/Year) <span class="text-red-600">*</span>
              </label>
              <div class="flex items-center space-x-4">
                <input v-if="!editingResidence.isPresent" v-model="editingResidence.toDate" type="month"
                  :min="editingResidence.fromDate" :max="getCurrentYearMonth()"
                  class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3" required />
                <label class="inline-flex items-center mt-1">
                  <input type="checkbox" v-model="editingResidence.isPresent" class="form-checkbox" />
                  <span class="ml-2 text-sm text-gray-600">Present</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal Footer -->
        <div class="sticky bottom-0 bg-white border-t px-4 py-3 md:p-4 flex flex-col md:flex-row gap-2 md:justify-end">
          <button type="button" @click.prevent="closeEditModal"
            class="w-full md:w-auto px-4 py-3 md:py-2 border rounded text-gray-600 hover:bg-gray-100 text-base md:text-sm">
            Cancel
          </button>
          <button type="button" @click.prevent="saveEditedAddress"
            class="w-full md:w-auto px-4 py-3 md:py-2 bg-blue-600 text-white rounded hover:bg-blue-700 text-base md:text-sm">
            Save Changes
          </button>
        </div>
      </div>
    </div>

    <!-- Overlap Confirmation Modal -->
    <div v-if="showOverlapConfirm"
      class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-50"
      @click.self="cancelSave">
      <div class="relative bg-white md:rounded-lg shadow-xl w-full md:max-w-2xl h-full md:h-auto md:mx-4" @click.stop>
        <!-- Modal Header -->
        <div class="sticky top-0 bg-white z-10 px-4 py-3 md:p-4 border-b flex items-center justify-between">
          <h3 class="text-lg font-medium leading-6">
            Overlapping Dates Found
          </h3>
          <button @click.prevent="cancelSave" type="button" class="text-gray-400 hover:text-gray-600 p-2 -mr-2">
            <span class="sr-only">Close</span>
            <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <!-- Modal Body -->
        <div class="p-4 md:p-6 overflow-y-auto" style="height: calc(100% - 136px);">
          <p class="text-sm text-gray-700 mb-4">
            The following addresses have overlapping dates with the one you are trying to save:
          </p>
          <ul class="list-disc pl-5 text-gray-700 text-sm">
            <li v-for="(overlap, index) in overlapResidences" :key="index">
              {{ overlap.address }} ({{ overlap.dates }})
            </li>
          </ul>
        </div>

        <!-- Modal Footer -->
        <div class="sticky bottom-0 bg-white border-t px-4 py-3 md:p-4 flex flex-col md:flex-row gap-2 md:justify-end">
          <button type="button" @click.prevent="cancelSave"
            class="w-full md:w-auto px-4 py-3 md:py-2 border rounded text-gray-600 hover:bg-gray-100 text-base md:text-sm">
            Cancel
          </button>
          <button type="button" @click.prevent="proceedWithSave"
            class="w-full md:w-auto px-4 py-3 md:py-2 bg-blue-600 text-white rounded hover:bg-blue-700 text-base md:text-sm">
            Proceed Anyway
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';

import { validateResidentialDates } from '../../../utils/validation/residentialValidation';
import { useInternalAuthStore } from '../../../stores/internalAuth';
import { useAddressSearch } from '../../../composables/useAddressSearch';

const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  },
  fieldErrors: {
    type: Object,
    default: () => ({})
  }
});

const emit = defineEmits(['update:modelValue']);
const validationErrors = ref([]);
const authStore = useInternalAuthStore();
const showEditModal = ref(false);
const editingResidence = ref({});
const editingIndex = ref(-1);
const showOverlapConfirm = ref(false);
const overlapResidences = ref([]);

// Address search setup
const { searchResults, isLoading, error, searchAddressMapbox } = useAddressSearch();
const addressSearch = ref('');

// Debounced search function
let searchTimeout;
function handleAddressSearch(event) {
  clearTimeout(searchTimeout);
  searchTimeout = setTimeout(() => {
    searchAddressMapbox(event.target.value);
  }, 300);
}

function selectAddress(address) {
  // Update the editing residence with selected address
  editingResidence.value = {
    ...editingResidence.value,
    streetNumber: address.streetNumber || '',
    streetName: address.streetName || '',
    city: address.city || '',
    provinceTerritory: address.provinceTerritory || '',
    country: address.country || '',
    postalCode: address.postalCode || ''
  };

  // Clear search
  addressSearch.value = '';
  searchResults.value = [];
}

// Clear search when modal closes
watch(showEditModal, (newVal) => {
  if (!newVal) {
    addressSearch.value = '';
    searchResults.value = [];
  }
});

watch(() => props.modelValue.residentialHistory, validateAllDates, { deep: true });

function getFullAddress(residence) {
  const parts = [];
  if (residence.apartmentNumber) parts.push(`Unit ${residence.apartmentNumber}`);
  if (residence.streetNumber) parts.push(residence.streetNumber);
  if (residence.streetName) parts.push(residence.streetName);
  if (residence.city) parts.push(residence.city);
  if (residence.provinceTerritory) parts.push(residence.provinceTerritory);
  if (residence.country) parts.push(residence.country);
  if (residence.postalCode) parts.push(residence.postalCode);

  return parts.join(', ');
}

function formatMonth(yearMonth) {
  if (!yearMonth) return '';

  try {
    // Ensure we're using UTC to avoid timezone issues
    const [year, month] = yearMonth.split('-');
    const date = new Date(Date.UTC(parseInt(year), parseInt(month) - 1, 1));

    return new Intl.DateTimeFormat('en', {
      year: 'numeric',
      month: 'long',
      timeZone: 'UTC' // Ensure UTC timezone
    }).format(date);
  } catch (e) {
    return yearMonth;
  }
}

function getCurrentYearMonth() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  return `${year}-${month}`;
}

function addAddress() {
  const newResidence = {
    apartmentNumber: "",
    streetNumber: "",
    streetName: "",
    city: "",
    provinceTerritory: "",
    country: "",
    postalCode: "",
    fromDate: "",
    toDate: "",
    isPresent: false,
    proofFiles: [],
  };
  editingIndex.value = -1; // Set to -1 to indicate new address
  editingResidence.value = newResidence;
  showEditModal.value = true;
}

function editAddress(index) {
  // Create a fresh copy of the residence at the specific index
  const residenceToEdit = props.modelValue.residentialHistory[index];
  editingIndex.value = index;
  editingResidence.value = JSON.parse(JSON.stringify(residenceToEdit));
  showEditModal.value = true;
}

function closeEditModal() {
  showEditModal.value = false;
  editingResidence.value = {};
  editingIndex.value = -1; // Reset the editing index
  addressSearch.value = ''; // Clear search
  searchResults.value = []; // Clear results
}

// Function to check date overlaps
function checkDateOverlap(residence1, residence2) {
  const from1 = new Date(residence1.fromDate);
  const to1 = residence1.isPresent ? new Date() : new Date(residence1.toDate);
  const from2 = new Date(residence2.fromDate);
  const to2 = residence2.isPresent ? new Date() : new Date(residence2.toDate);

  return from1 <= to2 && from2 <= to1;
}

function findOverlappingResidences(newResidence, currentIndex) {
  return props.modelValue.residentialHistory
    .map((residence, index) => ({ residence, index }))
    .filter(({ residence, index }) => 
      index !== currentIndex && 
      checkDateOverlap(newResidence, residence)
    );
}

async function saveEditedAddress() {
  const overlaps = findOverlappingResidences(editingResidence.value, editingIndex.value);
  
  if (overlaps.length > 0) {
    overlapResidences.value = overlaps.map(({ residence }) => ({
      address: getFullAddress(residence),
      dates: `${formatMonth(residence.fromDate)} to ${residence.isPresent ? 'Present' : formatMonth(residence.toDate)}`
    }));
    showOverlapConfirm.value = true;
    return;
  }
  
  proceedWithSave();
}

function proceedWithSave() {
  if (editingIndex.value === -1) {
    // Adding new address
    props.modelValue.residentialHistory.push(editingResidence.value);
  } else {
    // Editing existing address
    props.modelValue.residentialHistory[editingIndex.value] = editingResidence.value;
  }

  if (editingResidence.value.isPresent) {
    props.modelValue.residentialHistory.forEach((r, i) => {
      if (i !== editingIndex.value) r.isPresent = false;
    });
  }

  sortResidentialHistory();
  validateAllDates();
  emit('update:modelValue', props.modelValue);

  if (editingResidence.value.proofFiles?.some(file => !file.id && !file.uploading)) {
    uploadProofFiles(editingIndex.value);
  }

  closeEditModal();
  showOverlapConfirm.value = false;
}

function cancelSave() {
  showOverlapConfirm.value = false;
  closeEditModal();
}

function removeAddress(index) {
  if (props.modelValue.residentialHistory.length > 1) {
    props.modelValue.residentialHistory.splice(index, 1);
    sortResidentialHistory();
    emit('update:modelValue', props.modelValue);
    validateAllDates();
  }
}

function validateAllDates() {
  validationErrors.value = [];
  const errors = [];
  validateResidentialDates(props.modelValue.residentialHistory, errors);
  validationErrors.value = errors.filter(error => !error.includes('proof of current address'));
}

function sortResidentialHistory() {
  if (!props.modelValue.residentialHistory || props.modelValue.residentialHistory.length <= 1) {
    return;
  }

  const currentIndex = props.modelValue.residentialHistory.findIndex(r => r.isPresent);
  const sortedAddresses = [...props.modelValue.residentialHistory];
  let currentAddress = null;

  if (currentIndex >= 0) {
    currentAddress = sortedAddresses.splice(currentIndex, 1)[0];
  }

  // Sort by fromDate strings (YYYY-MM format)
  sortedAddresses.sort((a, b) => {
    if (!a.fromDate) return 1;
    if (!b.fromDate) return -1;
    return b.fromDate.localeCompare(a.fromDate);
  });

  if (currentAddress) {
    sortedAddresses.unshift(currentAddress);
  }

  props.modelValue.residentialHistory = sortedAddresses;
  emit('update:modelValue', props.modelValue);
}

if (props.modelValue.residentialHistory?.length > 0) {
  sortResidentialHistory();
}
</script>
