<template>
  <section aria-labelledby="employee-tasks-title">
    <div class="bg-white shadow rounded-lg">
      <div class="px-4 py-5 sm:px-6">
        <h2 id="employee-tasks-title" class="text-lg font-medium text-gray-900">
          Employee Tasks
        </h2>
      </div>
      <div class="border-t border-gray-200">
        <ul role="list" class="divide-y divide-gray-200">
          <li v-for="task in employeeTasks" 
              :key="task.name"
              class="px-4 py-4 sm:px-6 hover:bg-gray-50">
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <component :is="task.icon" 
                          class="h-6 w-6 text-gray-400"
                          aria-hidden="true" />
                <p class="ml-4 text-sm font-medium text-gray-900">
                  {{ task.name }}
                </p>
              </div>
              <router-link :to="task.to"
                          class="ml-2 flex-shrink-0 text-sm font-medium text-indigo-600 hover:text-indigo-500">
                View<span class="sr-only">, {{ task.name }}</span>
              </router-link>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue';
import { DocumentTextIcon , ShieldCheckIcon } from '@heroicons/vue/24/outline';
import { useInternalAuthStore } from '@/stores/internalAuth';

const authStore = useInternalAuthStore();

const employeeTasks = computed(() => [
  {
    name: 'Company Policy',
    to: '/accept-policy',
    icon: DocumentTextIcon,
    completed: authStore.user?.policyAccepted
  },
  {
    name: 'Security Assessment',
    to: '/security-assessment',
    icon: ShieldCheckIcon,
    completed: false
  },

]);
</script>
