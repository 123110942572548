import { defineStore } from 'pinia'
import { useRouter } from 'vue-router';
import { getCurrentInstance } from 'vue';
import { axiosInstance } from '@/lib/axios';

const APP_URL = import.meta.env.VITE_APP_URL;

// Remove duplicate axios configuration and use imported instance

const PUBLIC_ROUTES = [
    '/login',
    '/register',
    '/forgot-password',
    '/reset-password',
    '/verify-email',
    '/verify-invitation',
    '/magic-link',
];

export const useInternalAuthStore = defineStore({
    id: 'internalAuth',
    state: () => ({
        error: null,
        success: null,
        isLoading: false,
        isAuthenticated: false,
        user: null,
        redirectPath: null,
        email: null,
        password: null,
        registrationEnabled: true,
        teamMembers: [],
        userType: '',
        teamId: '66faaabeea32d9d7d759',
        userProfile: null,
        kurtzProgress: null,
        kurtzResults: {
            descriptors: [],
            errorTracking: {
                descriptorsSelection: 0,
                adjectiveChecklist: 0,
                colorRanking: 0,
                wordSquare: 0,
                qualities: 0,
                organizationalValues: 0
            },
            attempts: 1
        },
        adminKurtzResults: [], // Add new state for admin results view
        isTestCompleted: false,
        needsActivation: false,
        pendingActivationEmail: null,
        securityAssessment: {
            draft: null,
            status: null,
            submitted: false,
            lastSaved: null,
            errors: []
        },
        kurtzAllResults: [],
        kurtzAudit: [],
    }),
    getters: {
        userRole: (state) => state.user?.role || '',
        isLoggedIn: (state) => state.isAuthenticated,
        hasError: (state) => !!state.error,
        isAdminUser: (state) => ['admin', 'owner'].includes(state.user?.role),
        isCandidateUser: (state) => state.user?.role === 'candidate',
        isEmployeeUser: (state) => state.user?.role === 'employee',
        hasPolicyTasks: (state) => state.user?.role === 'employee' && !state.user?.policyAccepted,
        hasSecurityAssessment: (state) => !!state.securityAssessment.draft,
        isSecurityAssessmentSubmitted: (state) => state.securityAssessment.submitted,
    },
    actions: {
        initializeErrorTracking() {
            // Always create a fresh errorTracking object with numeric values
            if (!this.kurtzResults) {
                this.kurtzResults = {
                    descriptors: [],
                    errorTracking: {
                        descriptorsSelection: 0,
                        adjectiveChecklist: 0,
                        colorRanking: 0,
                        wordSquare: 0,
                        qualities: 0,
                        organizationalValues: 0
                    },
                    attempts: 1
                };
            } else if (!this.kurtzResults.errorTracking) {
                // If kurtzResults exists but errorTracking doesn't, initialize it
                this.kurtzResults.errorTracking = {
                    descriptorsSelection: 0,
                    adjectiveChecklist: 0,
                    colorRanking: 0,
                    wordSquare: 0,
                    qualities: 0,
                    organizationalValues: 0
                };
            } else {
                // Ensure all properties are numbers
                const keys = ['descriptorsSelection', 'adjectiveChecklist', 'colorRanking',
                    'wordSquare', 'qualities', 'organizationalValues'];
                keys.forEach(key => {
                    if (typeof this.kurtzResults.errorTracking[key] !== 'number') {
                        this.kurtzResults.errorTracking[key] = 0;
                    }
                });
            }
        },

        async init() {
            try {
                const response = await axiosInstance.post('/auth/verify-session');
                if (response.data.status === 'ok' && response.data.user) {
                    this.user = response.data.user;
                    this.fetchUserProfile();
                    this.isAuthenticated = true;
                    this.userType = response.data.user.role;
                    return true;
                }
                return false;
            } catch (error) {
                this.resetState();
                return false;
            }
        },

        async verifySession() {
            try {
                const response = await axiosInstance.post('/auth/verify-session');
                if (response.data.status === 'ok' && response.data.user) {
                    this.user = response.data.user;
                    this.isAuthenticated = true;
                    this.userType = response.data.user.role;
                    return true;
                }
                this.resetState();
                return false;
            } catch (error) {
                this.resetState();
                return false;
            }
        },

        resetState() {
            this.user = null;
            this.isAuthenticated = false;
            this.userType = '';
            this.error = null;
            this.success = null;
            this.redirectPath = null;
        },

        async login(email, password) {
            let router = useRouter();
            if (!router && getCurrentInstance()) {
                router = getCurrentInstance().proxy.$router;
            }

            this.isLoading = true;
            this.error = null;
            this.needsActivation = false;
            this.pendingActivationEmail = null;

            try {
                const response = await axiosInstance.post('/auth/login', {
                    email,
                    password,
                });

                if (response.data.status === 'ok') {
                    await this.verifySession();
                    this.success = 'Logged in successfully!';

                    // Redirect to stored path or default to dashboard
                    if (router) {
                        const redirectTo = this.redirectPath || '/dashboard';
                        this.redirectPath = null; // Clear stored path
                        await router.push(redirectTo);
                    }
                }
            } catch (error) {
                if (error.response?.data?.error === 'Your account is not active.') {
                    this.needsActivation = true;
                    this.pendingActivationEmail = email;
                } else {
                    this.error = error.response?.data?.error || 'Login failed';
                }
                throw error;
            } finally {
                this.isLoading = false;
            }
        },

        // verify-magic-link
        async verifyMagicLink(token) {
            this.isLoading = true;
            this.error = null;

            try {
                const response = await axiosInstance.post('/auth/verify-magic-link', { token });
                await this.verifySession();
                if (response.data.status === 'ok') {
                    // Set authentication state
                    this.user = response.data.user;
                    this.isAuthenticated = true;
                    this.userType = response.data.user.role;
                    this.error = null;

                    return true;
                }
                return false;
            } catch (error) {
                this.error = error.response?.data?.error || 'Failed to verify magic link';
                throw error;
            } finally {
                this.isLoading = false;
            }
        },


        async register({ email, firstName, lastName, phoneNumber, companyName, password }) {
            if (!this.registrationEnabled) {
                throw new Error('Registration is not currently enabled. Please contact hr@protocase.com for assistance.');
            }

            this.isLoading = true;
            this.error = null;
            try {
                const response = await axiosInstance.post('/auth/register', {
                    email,
                    firstName,
                    lastName,
                    phoneNumber,
                    companyName,
                    password,
                });
                return response.data;
            } catch (error) {
                this.error = error.response?.data?.error || 'Registration failed';
                throw error;
            } finally {
                this.isLoading = false;
            }
        },

        async verifyEmail({ email, token }) {
            try {
                const response = await axiosInstance.post('/auth/verify-email', {
                    email: email.trim(), // Trim any whitespace
                    token: token.toUpperCase() // Backend expects uppercase token
                });
                return response.data;
            } catch (error) {
                this.error = error.response?.data?.error || 'Email verification failed';
                throw error;
            }
        },

        // profile
        async fetchUserProfile() {
            try {
                const response = await axiosInstance.get('auth/profile');
                if (response.data.status === 'ok') {
                    this.user = response.data.profile;
                }
                return response.data;
            } catch (error) {
                throw error;
            }
        },

        async resendActivation(email) {
            try {
                const response = await axiosInstance.post('/auth/resend-activation', {
                    email,
                    redirectUrl: `${window.location.origin}/verify-email`
                });

                if (response.data.status === 'ok') {
                    this.success = 'Activation email has been resent. Please check your inbox.';
                    this.error = null;
                }
                return response.data;
            } catch (error) {
                this.error = error.response?.data?.error || 'Failed to resend activation email';
                throw error;
            }
        },

        async forgotPassword({ email }) {
            this.isLoading = true;
            this.error = null;
            this.success = null;

            try {
                const response = await axiosInstance.post('/auth/forgot-password', {
                    email,
                    redirectUrl: `${window.location.origin}/reset-password` // Add redirect URL
                });

                if (response.data.status === 'ok') {
                    this.success = 'Password reset instructions have been sent to your email.';
                }
                return response.data;
            } catch (error) {
                this.error = error.response?.data?.error || 'Failed to process forgot password request';
                throw error;
            } finally {
                this.isLoading = false;
            }
        },

        async resetPassword({ email, token, password }) {
            this.isLoading = true;
            this.error = null;
            try {
                const response = await axiosInstance.post('/auth/reset-password', {
                    email,
                    token,
                    password
                });

                if (response.data.status === 'ok') {
                    this.success = 'Password reset successfully!';
                }
                return response.data;
            } catch (error) {
                this.error = error.response?.data?.error || 'Failed to reset password';
                throw error;
            } finally {
                this.isLoading = false;
            }
        },

        async fetchTeamMembers() {
            try {
                const response = await axiosInstance.get('/team-members');
                if (response.data.status === 'ok') {
                    this.teamMembers = response.data.data;
                }
                return response.data;
            } catch (error) {
                throw error;
            }
        },

        async fetchTeamMembersWithStatus() {
            try {
                const response = await axiosInstance.get('/auth/kurtz-audit');
                if (response.data.status === 'ok') {
                    // Add isLoading property to each member
                    this.teamMembers = response.data.data.map(member => ({
                        ...member,
                        isLoading: false
                    }));
                    return response.data;
                }
                throw new Error('Failed to fetch team members');
            } catch (error) {
                throw error;
            }
        },

        async fetchAllUsers() {
            try {
                const response = await axiosInstance.get('/auth/users');
                if (response.data.status === 'ok') {
                    return response.data;
                }
                throw new Error('Failed to fetch users');
            } catch (error) {
                throw error;
            }
        },

        async sendMagicURL(email) {
            try {
                const response = await axiosInstance.post('/auth/send-magic-url', { email });
                if (response.data.status === 'ok') {
                    this.success = 'Magic link sent successfully!';
                }
                return response.data;
            } catch (error) {
                this.error = error.response?.data?.error || 'Failed to send magic link';
                throw error;
            }
        },


        async logout() {
            let router = useRouter();
            if (!router && getCurrentInstance()) {
                router = getCurrentInstance().proxy.$router;
            }

            try {
                const response = await axiosInstance.post('/auth/logout');
                this.resetState();

                // Clear any local cookies by setting an expired cookie
                document.cookie = "session=; Path=/; Domain=.protocase.com; Expires=Thu, 01 Jan 1970 00:00:00 GMT";

                if (router) {
                    await router.push('/login');
                }

                return true;
            } catch (error) {
                this.error = error.response?.data?.error || 'Logout failed';
                throw error;
            }
        },

        async fetchDictionary(word) {
            try {
                const response = await axios.get(
                    `https://api.dictionaryapi.dev/api/v2/entries/en/${word}`
                );
                return response.data[0]?.meanings[0]?.definitions[0]?.definition || "";
            } catch (error) {
                return "Definition not available";
            }
        },

        async fetchKurtzResults() {
            try {
                // If admin is viewing their own assessment, don't fetch all results
                if (this.isAdminUser && window.location.pathname.includes('/kurtz/')) {
                    return null; // Return null when admin is taking the test
                }

                const response = await axiosInstance.get('/auth/kurtz-results');
                if (response.data.status === 'ok') {
                    // Store all results in adminKurtzResults instead of kurtzResults
                    this.adminKurtzResults = response.data.kurtzResults;
                    return response.data.results;
                }
                throw new Error('Failed to fetch Kurtz results');
            } catch (error) {
                throw error;
            }
        },

        // fetch kurtz Results by id
        async fetchKurtzResultsById(id) {
            try {
                const response = await axiosInstance.get(`auth/kurtz-results/${id}`);
                console.log(response.data);
                if (response.data.status === 'ok') {
                    this.kurtzResults = response.data.kurtzResults;
                    return response.data;
                }
                throw new Error('Failed to fetch Kurtz results');
            } catch (error) {
                throw error;
            }
        },

        async submitKurtzResults(results) {
            try {
                // Add error tracking to submitted results if not present
                if (!results.errorTracking) {
                    results.errorTracking = {
                        descriptorsSelection: 0,  // Changed from 'descriptorsselection' to 'descriptorsSelection'
                        adjectiveChecklist: 0,
                        colorRanking: 0,
                        wordSquare: 0,
                        qualities: 0,
                        organizationalValues: 0
                    };
                }

                const response = await axiosInstance.post('auth/kurtz-results', results);
                if (response.data.status === 'ok') {
                    // Only update kurtzResults for current test
                    this.kurtzResults = results;

                    // If admin, also add to adminKurtzResults array
                    if (this.isAdminUser) {
                        this.adminKurtzResults = [...this.adminKurtzResults, response.data.kurtzResult];
                    }
                    return response.data;
                }
                throw new Error('Failed to submit Kurtz results');
            } catch (error) {
                throw error;
            }
        },

        // Update fetchKurtzResultById method to handle response correctly
        async fetchKurtzResultById(id) {
            try {
                // Use axiosInstance instead of fetch for consistency
                const response = await axiosInstance.get(`/auth/kurtz-results/${id}`);

                if (response.data.status === 'ok' && response.data.kurtzResult) {
                    // For admin viewing results, store in adminKurtzResults
                    if (this.isAdminUser && !window.location.pathname.includes('/kurtz/')) {
                        this.adminKurtzResults = [response.data.kurtzResult];
                    } else {
                        // For taking the test, store in kurtzResults 
                        this.kurtzResults = response.data.kurtzResult;
                    }
                    return response.data;
                }
                throw new Error('Invalid response format');
            } catch (error) {
                throw error;
            }
        },

        async updateKurtzStatus(email, status) {
            try {
                const response = await axiosInstance.post('/auth/kurtz-status', {
                    email,
                    status
                });
                if (response.data.status === 'ok') {
                    return response.data;
                }
                throw new Error('Failed to update status');
            } catch (error) {
                throw error;
            }
        },

        async deleteKurtzCandidate(email) {
            try {
                // This should match the backend route '/auth/kurtz-candidate/:email'
                const response = await axiosInstance.delete(`/auth/kurtz-candidate/${email}`);
                if (response.data.status === 'ok') {
                    return response.data;
                }
                throw new Error('Failed to delete candidate');
            } catch (error) {
                throw error;
            }
        },

        async exportSecurityAssessments(ids) {
            try {
                // If single ID is provided, use the dedicated endpoint
                if (ids.length === 1) {
                    const id = ids[0];
                    const response = await axiosInstance.get(`/auth/security-assessment/${id}/pdf`, {
                        responseType: 'blob'
                    });

                    // Create blob URL and trigger download
                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `security-assessment-${id}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);

                    return true;
                } else {
                    // Original implementation for multiple IDs
                    const response = await axiosInstance.post('/auth/security-assessment/export',
                        { ids },
                        { responseType: 'blob' }
                    );

                    // Create blob URL and trigger download
                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'security-assessments.pdf');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);

                    return true;
                }
            } catch (error) {
                throw error;
            }
        },

        async inviteTeamMember(email, firstName, lastName, role) {
            this.isLoading = true;
            this.error = null;
            try {
                const response = await axiosInstance.post('auth/team/invite', {
                    email,
                    firstName,
                    lastName,
                    role
                });

                if (response.data.status === 'ok') {
                    this.success = 'Team member invited successfully!';
                }
                return response.data;
            } catch (error) {
                this.error = error.response?.data?.error || 'Failed to invite team member';
                throw error;
            } finally {
                this.isLoading = false;
            }
        },

        async verifyInvitation(secret) {
            this.isLoading = true;
            this.error = null;
            try {
                const response = await axiosInstance.post('/auth/invitations/verify', {
                    secret
                });
                return response.data;
            } catch (error) {
                this.error = error.response?.data?.error || 'Failed to verify invitation';
                throw error;
            } finally {
                this.isLoading = false;
            }
        },

        async completeInvitation(secret, password) {
            this.isLoading = true;
            try {
                const response = await axiosInstance.post('/auth/invitations/complete', {
                    token: secret, // kept as 'token' for backward compatibility
                    password
                });

                if (response.data.status === 'ok') {
                    // Immediately verify session after completion
                    await this.verifySession();
                }
                return response.data;
            } catch (error) {
                throw error;
            } finally {
                this.isLoading = false;
            }
        },

        async acceptPolicy() {
            this.isLoading = true;
            try {
                const response = await axiosInstance.post('/auth/accept-policy');
                if (response.data.status === 'ok') {
                    this.user = {
                        ...this.user,
                        policyAccepted: true
                    };
                    return response.data;
                }
                throw new Error(response.data.error || 'Failed to accept policy');
            } catch (error) {
                throw error;
            } finally {
                this.isLoading = false;
            }
        },

        async fetchPolicies() {
            try {
                const response = await axiosInstance.get('auth/policies');
                if (response.data.status === 'ok') {
                    return {
                        ok: true,
                        content: response.data.content
                    };
                }
                throw new Error(response.data.error || 'Failed to load policies');
            } catch (error) {
                this.error = 'Failed to load policies';
                return {
                    ok: false,
                    error: error.response?.data?.error || 'Failed to load policies'
                };
            }
        },

        async getPolicyStatus() {
            try {
                const response = await axiosInstance.get('auth/policies/status');
                return response.data;
            } catch (error) {
                throw error;
            }
        },

        async submitPolicyAcceptance(fileId) {
            try {
                const response = await axiosInstance.post('auth/policies/accept', { fileId });
                if (response.data.status === 'ok') {
                    // Update user state to reflect policy acceptance
                    this.user = {
                        ...this.user,
                        policyAccepted: true
                    };
                }
                return response.data;
            } catch (error) {
                throw error;
            }
        },

        async submitPolicyAcceptance(policyData) {
            try {
                const response = await axiosInstance.post('auth/policies/accept', { policyData });
                if (response.data.status === 'ok') {
                    this.user = {
                        ...this.user,
                        policyAccepted: true
                    };
                }
                return response.data;
            } catch (error) {
                throw error;
            }
        },

        async downloadPolicyPdf() {
            try {
                const response = await axiosInstance.get('auth/policy-pdf', {
                    responseType: 'blob'
                });

                // Create blob link to download
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `policy_acceptance.pdf`);

                // Open in new tab instead of downloading
                link.setAttribute('target', '_blank');
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);

                return url; // Return URL for direct linking
            } catch (error) {
                this.error = 'Failed to download policy PDF';
                throw error;
            }
        },

        async saveSecurityAssessmentDraft(formData) {
            this.isLoading = true;
            try {
                const response = await axiosInstance.post('/auth/security-assessment/draft', {
                    formData
                });

                if (response.data.status === 'ok') {
                    this.securityAssessment.draft = formData;
                    this.securityAssessment.lastSaved = new Date().toISOString();
                    this.success = 'Draft saved successfully';
                    return true;
                }
                throw new Error(response.data.error || 'Failed to save draft');
            } catch (error) {
                this.error = error.response?.data?.error || 'Failed to save draft';
                throw error;
            } finally {
                this.isLoading = false;
            }
        },

        async loadSecurityAssessmentDraft() {
            try {
                const response = await axiosInstance.get('/auth/security-assessment/draft');
                if (response.data.status === 'ok') {
                    this.securityAssessment.draft = response.data.draft;
                    this.securityAssessment.lastSaved = response.data.lastSaved;
                    return response.data.draft;
                }
                throw new Error(response.data.error || 'Failed to load draft');
            } catch (error) {
                this.error = error.response?.data?.error || 'Failed to load draft';
                throw error;
            }
        },

        async submitSecurityAssessment(formData) {
            this.isLoading = true;
            try {
                const response = await axiosInstance.post('/auth/security-assessment/submit', {
                    formData
                });

                if (response.data.status === 'ok') {
                    this.securityAssessment.submitted = true;
                    this.securityAssessment.status = 'submitted';
                    this.securityAssessment.draft = null;
                    this.success = 'Security assessment submitted successfully';
                    return true;
                }

                throw new Error(response.data.error || 'Failed to submit assessment');
            } catch (error) {
                // Add more specific error handling
                if (error.response?.status === 404) {
                    throw new Error('Security assessment submission endpoint not available');
                }

                this.error = error.response?.data?.error || error.message || 'Failed to submit assessment';
                this.securityAssessment.errors = error.response?.data?.errors || [];
                throw error;
            } finally {
                this.isLoading = false;
            }
        },

        async getSecurityAssessmentStatus() {
            try {
                const response = await axiosInstance.get('/auth/security-assessment/status');
                if (response.data.status === 'ok') {
                    this.securityAssessment.status = response.data.assessmentStatus;
                    this.securityAssessment.submitted = response.data.submitted;
                    return response.data;
                }
                throw new Error(response.data.error || 'Failed to get assessment status');
            } catch (error) {
                this.error = error.response?.data?.error || 'Failed to get assessment status';
                throw error;
            }
        },

        async uploadSecurityDocument(file, documentType) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('documentType', documentType);

            try {
                const response = await axiosInstance.post('/uploads/security-document',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );

                if (response.data.status === 'ok') {
                    this.success = 'Document uploaded successfully';
                    return response.data.data.id;
                }
                throw new Error(response.data.error || 'Failed to upload document');
            } catch (error) {
                this.error = error.response?.data?.error || 'Failed to upload document';
                throw error;
            }
        },

        async fetchUserDocuments(documentType = null) {
            try {
                let url = '/uploads/user-documents';
                if (documentType) {
                    url += `?type=${encodeURIComponent(documentType)}`;
                }

                const response = await axiosInstance.get(url);

                if (response.data.status === 'ok') {
                    return response.data.files;
                }
                throw new Error('Failed to fetch documents');
            } catch (error) {
                this.error = error.response?.data?.error || 'Failed to fetch documents';
                throw error;
            }
        },

        async deleteDocument(documentId) {
            try {
                const response = await axiosInstance.delete(`/uploads/document/${documentId}`);

                if (response.data.status === 'ok') {
                    this.success = 'Document deleted successfully';
                    return true;
                }
                throw new Error('Failed to delete document');
            } catch (error) {
                this.error = error.response?.data?.error || 'Failed to delete document';
                throw error;
            }
        },

        async fetchSecuritySubmissions() {
            try {
                const response = await axiosInstance.get('auth/security-assessment/submissions');
                if (response.data.status === 'ok') {
                    return response.data.submissions;
                }
                throw new Error('Failed to fetch security submissions');
            } catch (error) {
                throw error;
            }
        },

        async markSecurityAssessmentAsReviewed(id) {
            try {
                const response = await axiosInstance.post(`/auth/security-assessment/${id}/review`);
                if (response.data.status === 'ok') {
                    return response.data;
                }
                throw new Error('Failed to mark assessment as reviewed');
            } catch (error) {
                throw error;
            }
        },

        async exportSecurityAssessments(ids) {
            try {
                const response = await axiosInstance.post('/auth/security-assessment/export',
                    { ids },
                    { responseType: 'blob' }
                );

                // Create blob URL and trigger download
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'security-assessments.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);

                return true;
            } catch (error) {
                throw error;
            }
        },

        async fetchSecurityAssessmentById(id) {
            try {
                const response = await axiosInstance.get(`/auth/security-assessment/${id}`);
                if (response.data.status === 'ok') {
                    return response.data.assessment;
                }
                throw new Error('Failed to fetch assessment');
            } catch (error) {
                throw error;
            }
        },

        async toggleArchiveResult(id, archived) {
            try {
                const response = await axiosInstance.post(`/auth/kurtz-results/${id}/archive`, {
                    archived: archived
                });

                if (response.data.status === 'ok') {
                    // Update the local state in adminKurtzResults array
                    const index = this.adminKurtzResults.findIndex(result => result.id === id);
                    if (index !== -1) {
                        this.adminKurtzResults[index].archived = archived;
                    }
                    return response.data;
                }
                throw new Error('Failed to toggle archive status');
            } catch (error) {
                throw error;
            }
        },

        resetSecurityAssessment() {
            this.securityAssessment = {
                draft: null,
                status: null,
                submitted: false,
                lastSaved: null,
                errors: []
            };
        },

        // Add helper method to increment error counts
        incrementErrorCount(componentKey) {
            this.initializeErrorTracking();
            if (this.kurtzResults.errorTracking[componentKey] !== undefined) {
                this.kurtzResults.errorTracking[componentKey]++;
            } else {
                console.warn(`Invalid error tracking key: ${componentKey}`);
            }
        },

        async fetchAllKurtzResults() {
            this.isLoading = true;
            try {
                const response = await axiosInstance.get('/auth/kurtz-results/all');
                if (response.data.status === 'ok') {
                    this.kurtzAllResults = response.data.results;
                    return response.data.results;
                }
                throw new Error('Failed to fetch Kurtz results');
            } catch (error) {
                throw error;
            } finally {
                this.isLoading = false;
            }
        },

        resetKurtzTest() {
            this.kurtzProgress = null;
            this.isTestCompleted = false;
            this.kurtzResults = {
                descriptors: [],
                errorTracking: {
                    descriptorsSelection: 0,  // Changed from 'descriptorsselection' to 'descriptorsSelection'
                    adjectiveChecklist: 0,
                    colorRanking: 0,
                    wordSquare: 0,
                    qualities: 0,
                    organizationalValues: 0
                },
                attempts: 1
            };
        },

        async fetchDictionary(word) {
            try {
                const response = await axios.get(
                    `https://api.dictionaryapi.dev/api/v2/entries/en/${word}`
                );
                return response.data[0]?.meanings[0]?.definitions[0]?.definition || "";
            } catch (error) {
                return "Definition not available";
            }
        },

        async resolveUserName(userId) {
            try {
                const response = await axiosInstance.get(`/auth/users/${userId}`);
                if (response.data.status === 'ok') {
                    return response.data.user.name;
                }
                return 'Unknown';
            } catch (error) {
                return 'Unknown';
            }
        },

        async fetchTeamMembersWithStatus() {
            try {
                const response = await axiosInstance.get('/auth/team-members/status');
                if (response.data.status === 'ok') {
                    this.teamMembers = response.data.members.map(member => ({
                        ...member,
                        isLoading: false
                    }));
                    return response.data.members;
                }
                throw new Error('Failed to fetch team members with status');
            } catch (error) {
                throw error;
            }
        },

        resetKurtzResults() {
            this.kurtzResults = {
                descriptors: [],
                errorTracking: {
                    descriptorsSelection: 0,  // Changed from 'descriptorsselection' to 'descriptorsSelection'
                    adjectiveChecklist: 0,
                    colorRanking: 0,
                    wordSquare: 0,
                    qualities: 0,
                    organizationalValues: 0
                },
                attempts: 1
            };
        },

        async getPolicyData() {
            try {
                const response = await axiosInstance.get('/auth/policies/status');

                if (response.data.status === 'ok' && response.data.policyData) {

                    return response.data.policyData;
                }
                return null;
            } catch (error) {
                throw error;
            }
        },

        // policies/acceptances 
        async getAllPolicyAcceptances() {
            try {
                const response = await axiosInstance.get('/auth/policy-acceptances');
                if (response.data.status === 'ok') {
                    return response.data.acceptances;
                }
                throw new Error('Failed to fetch policy acceptances');


            }
            catch (error) {
                throw error;
            }

        },

        // Add new method to fetch employee policy status
        async fetchEmployeePolicyStatus() {
            try {
                const response = await axiosInstance.get('/auth/employee-policy-status');
                return response.data;
            } catch (error) {
                throw error;
            }
        },

        async fetchKurtzAudit() {
            try {
                const response = await axiosInstance.get('/auth/kurtz-audit');
                if (response.data.status === 'ok') {
                    // Assuming the audit data is in response.data.data
                    this.kurtzAudit = response.data.data;
                    return response.data;
                } else {
                    throw new Error('Failed to fetch Kurtz audit data');
                }
            } catch (error) {
                throw error;
            }
        },

        async saveNow() {
            if (this.isLoading) return false;
            
            this.isLoading = true;
            try {
                const response = await axiosInstance.post('/auth/security-assessment/draft', {
                    formData: this.securityAssessment.draft
                });

                if (response.data.status === 'ok') {
                    this.securityAssessment.lastSaved = new Date().toISOString();
                    this.success = 'Draft saved successfully';
                    return true;
                }
                throw new Error(response.data.error || 'Failed to save draft');
            } catch (error) {
                this.error = error.response?.data?.error || 'Failed to save draft';
                throw error;
            } finally {
                this.isLoading = false;
            }
        },
    },
});

