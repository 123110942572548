// Date validation constants
export const REASONABLE_AGE_MIN = 16;
export const REASONABLE_AGE_MAX = 100;

// Address history requirements
export const MIN_ADDRESS_HISTORY_YEARS = 5;

// Reference requirements
export const MIN_REFERENCES = 3;
export const MAX_REFERENCES = 5;
export const MIN_REFERENCE_YEARS = 2;

// File size limits
export const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

// History gaps
export const MAX_EMPLOYMENT_GAP_DAYS = 30;

// Document requirements by application type
export const REQUIRED_DOCUMENTS = {
  international: ['identificationDocuments', 'addressProof', 'criminalRecordCheck', 'permitDocuments'],
  canadian: ['identificationDocuments', 'addressProof']
};