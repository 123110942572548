<template>
  <div class="bg-blue-50 p-4 rounded-lg mb-4">
    <div 
      class="flex justify-between items-center cursor-pointer"
      @click="toggleInstructions"
    >
      <h3 class="text-lg font-medium text-blue-900">
        Instructions for {{ sectionTitle }}
      </h3>
      <ChevronDownIcon
        v-if="!showInstructions"
        class="h-5 w-5 text-blue-500"
      />
      <ChevronUpIcon
        v-else
        class="h-5 w-5 text-blue-500"
      />
    </div>
    
    <div v-if="showInstructions" class="mt-2 text-sm text-blue-700 space-y-2">
      <ul class="list-disc list-inside space-y-1">
        <li v-for="(instruction, index) in instructions" :key="index">
          {{ instruction }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/24/outline';

const props = defineProps({
  sectionTitle: {
    type: String,
    required: true
  },
  sectionId: {
    type: String,
    required: true
  }
});

const showInstructions = ref(true);

const instructions = computed(() => {
  switch (props.sectionId) {
    case 'consent':
      return [
        "Read the material carefully before signing",
        "The date format should be YYYY-MM-DD (e.g., 2024-02-13)",
        "Your consent will remain valid until you no longer require access or send written notice of revocation"
      ];

    case 'personal':
      return [
        "Enter your full legal surname",
        "Include all given names and put your preferred name in parentheses",
        "Enter your surname at birth even if it's the same as now",
        "For gender, select the option that best represents your identity",
        "Date of birth must be in YYYY-MM-DD format",
        "For name changes, provide all previous legal names and dates of change"
      ];

    case 'marital':
      return [
        "Select your current marital status",
        "For married/common-law, you must provide spouse information",
        "Spouse information must include full legal name and contact details",
        "Date format for spouse's birth date should be YYYY-MM-DD"
      ];

    case 'contact':
      return [
        "Provide at least one phone number",
        "Business phone is your work contact number",
        "Personal email should be one you check regularly",
        "Phone numbers should include area code and country code if applicable"
      ];

    case 'citizenship':
      return [
        "List all citizenships you currently hold",
        "Attach government-issued ID as proof of citizenship",
        "For Permanent Residents, include PR card information",
        "If born outside Canada, provide entry date and port of entry",
        "Birth city and province/country are mandatory fields"
      ];

    case 'residence':
      return [
        "List all addresses for the past 5 years with no gaps",
        "Start with your current address",
        "Include apartment/unit numbers if applicable",
        "Dates must be in YYYY-MM-DD format",
        "Provide proof of current address",
        "If you had multiple addresses at once, include all with overlapping dates"
      ];

    case 'employment':
      return [
        "List all employment, education, and unemployment periods for the past 5 years",
        "No gaps allowed - account for all time periods",
        "Include full institution names and addresses",
        "For remote work, provide employer's main office address",
        "Indicate if position was remote work",
        "Use YYYY-MM-DD format for all dates",
        "Include education and employment that overlap"
      ];

    case 'criminal':
      return [
        "Answer all questions honestly",
        "Include any convictions not pardoned/suspended",
        "Provide details of any detentions or arrests",
        "Include dates in YYYY-MM format",
        "Attach police certificates if required"
      ];

    case 'travel':
      return [
        "List all international travel in last 5 years",
        "Include entry date to Canada for international workers",
        "Calculate weeks spent accurately",
        "Specify business or pleasure for each trip",
        "Include trips over 6 months duration"
      ];

    case 'references':
      return [
        "Provide exactly three references that have known you for 5+ years",
        "Include at least one personal and one professional reference",
        "Cannot use family members, in-laws, or co-habitants",
        "Professional references can include former supervisors or business owners",
        "References will be contacted between 9 AM and 5 PM Atlantic Time",
        "References should be able to communicate in English",
        "References do not need to be in Canada but must be reachable during business hours"
      ];

    case 'security':
      return [
        "List all valid security clearances",
        "Include clearance level and issuing country",
        "Provide expiry dates in YYYY-MM-DD format",
        "Note: Criminal record checks are not security clearances"
      ];

    case 'statements':
      return [
        "Answer all statements truthfully",
        "Provide details for any 'Yes' answers",
        "International applicants must complete statements 12, 13, and 18-23",
        "Consider the last 5 years when answering"
      ];

    default:
      return [];
  }
});

function toggleInstructions() {
  showInstructions.value = !showInstructions.value;
}
</script>
