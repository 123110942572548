export function validateResidentialHistory(residences = [], applicationType = '', portOfEntry = '', errors = []) {
  // Validate required fields
  for (const residence of residences) {
    if (!residence.streetNumber || !residence.streetName || !residence.city || 
        !residence.provinceTerritory || !residence.country || !residence.postalCode) {
      errors.push('All address fields are required for residential history');
      return false;
    }
  }

  // Validate date coverage
  let sortedResidences = [...residences].sort(
    (a, b) => new Date(b.fromDate) - new Date(a.fromDate)
  );

  // Check for gaps
  for (let i = 0; i < sortedResidences.length - 1; i++) {
    const current = new Date(sortedResidences[i].toDate);
    const next = new Date(sortedResidences[i + 1].fromDate);

    if (current.getTime() - next.getTime() > 24 * 60 * 60 * 1000) {
      errors.push("No gaps are allowed in residential history dates");
      return false;
    }
  }

  // Validate 5 year history
  const fiveYearsAgo = new Date();
  fiveYearsAgo.setFullYear(fiveYearsAgo.getFullYear() - 5);

  const earliestDate = new Date(sortedResidences[sortedResidences.length - 1].fromDate);
  if (earliestDate > fiveYearsAgo) {
    errors.push("Residential history must cover the last 5 years");
    return false;
  }

  // International worker specific validation
  if (applicationType === 'international') {
    if (!portOfEntry || !portOfEntry.match(/.+,.+,.+/)) {
      errors.push('Port of entry must include city, province and airport name');
      return false;
    }
  }

  return true;
}

export function validateResidentialDates(residentialHistory, errors = []) {
  // Guard clause for undefined/null residentialHistory
  if (!residentialHistory || !Array.isArray(residentialHistory)) {
    errors.push('Invalid residential history data');
    return false;
  }

  if (residentialHistory.length === 0) {
    errors.push('Residential history is required');
    return false;
  }

  // Sort residences by fromDate in descending order
  const sortedResidences = [...residentialHistory].sort(
    (a, b) => {
      // Sort by year-month strings (YYYY-MM format)
      if (!a.fromDate) return 1;
      if (!b.fromDate) return -1;
      return b.fromDate.localeCompare(a.fromDate);
    }
  );

  // Check each residence has valid dates
  for (const residence of sortedResidences) {
    if (!residence.fromDate) {
      errors.push('From date is required for all residences');
      return false;
    }

    if (!residence.isPresent && !residence.toDate) {
      errors.push('To date is required for past residences');
      return false;
    }

    // For year-month format (YYYY-MM) we can compare strings directly
    const fromDate = residence.fromDate;
    const toDate = residence.isPresent ? getCurrentYearMonth() : residence.toDate;

    if (fromDate > toDate) {
      errors.push('From date cannot be after to date');
      return false;
    }
  }

  // Check for date gaps
  for (let i = 0; i < sortedResidences.length - 1; i++) {
    const currentResidence = sortedResidences[i];
    const nextResidence = sortedResidences[i + 1];
    
    const currentFrom = currentResidence.fromDate;
    const nextTo = nextResidence.isPresent ? getCurrentYearMonth() : nextResidence.toDate;

    // For month-level granularity, gap detection needs special handling
    // Check if months are consecutive (e.g., 2023-03 should come right after 2023-02)
    const [nextToYear, nextToMonth] = nextTo.split('-').map(Number);
    
    // Calculate what the next month should be
    let expectedNextMonth = parseInt(nextToMonth) + 1;
    let expectedNextYear = nextToYear;
    
    if (expectedNextMonth > 12) {
      expectedNextMonth = 1;
      expectedNextYear++;
    }
    
    const expectedNextDate = `${expectedNextYear}-${String(expectedNextMonth).padStart(2, '0')}`;
    
    // Check if there's a gap
    if (currentFrom > expectedNextDate) {
      // Calculate how many months gap
      const gapMonths = getMonthDifference(nextTo, currentFrom) - 1;
      if (gapMonths > 0) {
        errors.push(`Gap detected: ${gapMonths} month${gapMonths > 1 ? 's' : ''} between ${formatMonth(nextTo)} and ${formatMonth(currentFrom)}`);
        return false;
      }
    }
  }

  // Ensure 5-year coverage
  const now = new Date();
  const fiveYearsAgo = new Date(now);
  fiveYearsAgo.setFullYear(now.getFullYear() - 5);
  
  const fiveYearsAgoYearMonth = `${fiveYearsAgo.getFullYear()}-${String(fiveYearsAgo.getMonth() + 1).padStart(2, '0')}`;
  
  // Get earliest date from residential history
  const earliestDate = sortedResidences[sortedResidences.length - 1].fromDate;
  
  if (earliestDate > fiveYearsAgoYearMonth) {
    errors.push(`Your residential history must cover at least 5 years. Please add addresses going back to ${formatMonth(fiveYearsAgoYearMonth)}`);
    return false;
  }

  // Check if current address has proof files
  const currentResidence = residentialHistory.find(r => r.isPresent);
  if (currentResidence) {
    if (!validateProofFiles(currentResidence, errors)) {
      return false;
    }
  } else {
    errors.push('At least one address must be marked as current (present)');
    return false;
  }

  return true;
}

// Helper function to get current year-month
function getCurrentYearMonth() {
  const now = new Date();
  const month = (now.getMonth() + 1).toString().padStart(2, '0');
  return `${now.getFullYear()}-${month}`;
}

// Format a YYYY-MM date for display
function formatMonth(yearMonth) {
  if (!yearMonth) return '';
  
  try {
    const [year, month] = yearMonth.split('-');
    const date = new Date(`${year}-${month}-01`);
    
    return date.toLocaleDateString(undefined, { year: 'numeric', month: 'long' });
  } catch (e) {
    return yearMonth; // Fallback if parsing fails
  }
}

// Calculate difference in months between two YYYY-MM dates
function getMonthDifference(date1, date2) {
  const [year1, month1] = date1.split('-').map(Number);
  const [year2, month2] = date2.split('-').map(Number);
  
  return (year2 - year1) * 12 + (month2 - month1);
}

/**
 * Validates that a residence has required proof files
 * @param {Object} residence - The residence object to validate
 * @param {Array} errors - Array to collect error messages
 * @returns {boolean} - True if valid, false otherwise
 */
export function validateProofFiles(residence, errors = []) {
  // Skip validation for non-current residences
  if (!residence.isPresent) {
    return true;
  }

  // Check if proof files exist and at least one is uploaded successfully
  if (!residence.proofFiles || 
      residence.proofFiles.length === 0 || 
      !residence.proofFiles.some(file => file.id)) {
    errors.push('At least one proof of current address is required');
    return false;
  }

  // Check for failed uploads
  const failedUploads = residence.proofFiles.filter(file => file.error);
  if (failedUploads.length > 0) {
    errors.push('Some proof files failed to upload. Please try again or remove them.');
    return false;
  }

  return true;
}
