<template>
  <div>
    <h2 class="text-xl font-bold mb-4">Travel History</h2>

    <!-- Improved Instructions -->
    <div class="bg-blue-50 p-4 rounded-lg mb-6 border border-blue-200">
      <h3 class="font-bold text-blue-800 mb-2">Requirements:</h3>
      <ul class="list-disc pl-5 text-blue-800">
        <li>List all international travel in the past 5 years</li>
        <li>Include both business and personal travel</li>
        <li>Dates must be accurate and not overlap</li>
        <li>Provide purpose of travel for each entry</li>
      </ul>
    </div>

    <!-- Table View -->
    <div class="mb-6">
      <div class="overflow-x-auto">
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Country</th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                From</th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">To
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Purpose</th>
              <th scope="col" class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions</th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="(travel, index) in modelValue.travelHistory" :key="index" class="hover:bg-gray-50">
              <td class="px-6 py-4">
                <div class="text-sm text-gray-900">{{ travel.country }}</div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="text-sm text-gray-900">{{ formatDate(travel.fromDate) }}</div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="text-sm text-gray-900">{{ formatDate(travel.toDate) }}</div>
              </td>
              <td class="px-6 py-4">
                <div class="text-sm text-gray-900">{{ travel.purpose }}</div>
                <div class="text-xs text-gray-500">{{ travel.weeksSpent }} weeks</div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <button @click="editTravel(index)" class="text-blue-600 hover:text-blue-900 mr-3">
                  Edit
                </button>
                <button @click="removeTravel(index)" class="text-red-600 hover:text-red-900">
                  Remove
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <button type="button" @click="addTravel"
        class="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700">
        Add Travel History
      </button>


    </div>

    <!-- Edit Modal -->
    <div v-if="showEditModal"
      class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-50"
      @click.self="closeEditModal">
      <div class="relative bg-white rounded-lg shadow-xl max-w-2xl w-full mx-4" @click.stop>
        <!-- show errors -->
        <div v-if="validationErrors.length > 0" class="p-4 bg-red-50 border border-red-200 rounded">
          <h4 class="font-medium text-red-700 mb-2">Please correct the following issues:</h4>
          <ul class="list-disc pl-5 text-red-700 text-sm">
            <li v-for="(error, i) in validationErrors" :key="i">{{ error }}</li>
          </ul>
        </div>
        <!-- show errors end -->
        <!-- Modal Header -->
        <div class="p-4 border-b">
          <h3 class="text-lg font-medium">
            {{ editingIndex === -1 ? 'Add Travel History' : 'Edit Travel History' }}
          </h3>
          <button @click="closeEditModal" class="absolute top-4 right-4 text-gray-400 hover:text-gray-600">
            <span class="sr-only">Close</span>
            <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <!-- Modal Body -->
        <div class="p-6">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div class="md:col-span-2">
              <label class="block text-sm font-medium text-gray-700">
                Country <span class="text-red-600">*</span>
              </label>
              <select v-model="editingTravel.country" class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3"
                required>
                <option value="">Select a country</option>
                <option v-for="country in countries" :key="country" :value="country">
                  {{ country }}
                </option>
              </select>
            </div>

            <div>
              <label class="block text-sm font-medium text-gray-700">
                From Date <span class="text-red-600">*</span>
              </label>
              <input v-model="editingTravel.fromDate" type="month" :max="getCurrentDate()"
                class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3" required />
            </div>

            <div>
              <label class="block text-sm font-medium text-gray-700">
                To Date <span class="text-red-600">*</span>
              </label>
              <input v-model="editingTravel.toDate" type="month" :min="editingTravel.fromDate" :max="getCurrentDate()"
                @input="updateWeeks" class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3" required />
            </div>

            <div>
              <label class="block text-sm font-medium text-gray-700">
                Weeks Spent <span class="text-red-600">*</span>
              </label>
              <input v-model="editingTravel.weeksSpent" type="number" min="1"
                class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3" readonly />
            </div>

            <div class="md:col-span-2">
              <label class="block text-sm font-medium text-gray-700 mb-2">
                Purpose <span class="text-red-600">*</span>
              </label>
              <div class="flex space-x-4">
                <label class="inline-flex items-center">
                  <input type="radio" v-model="editingTravel.purpose" value="personal"
                    class="form-radio text-blue-600" />
                  <span class="ml-2">Personal</span>
                </label>
                <label class="inline-flex items-center">
                  <input type="radio" v-model="editingTravel.purpose" value="business"
                    class="form-radio text-blue-600" />
                  <span class="ml-2">Business</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal Footer -->
        <div class="p-4 border-t flex justify-end space-x-3">
          <button type="button" @click="closeEditModal"
            class="px-4 py-2 border rounded text-gray-600 hover:bg-gray-100">
            Cancel
          </button>
          <button type="button" @click="saveEditedTravel"
            class="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700">
            Save Changes
          </button>
        </div>
      </div>
    </div>


  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { countries } from '../../../utils/countryList';
import { validateTravelHistory } from '../../../utils/validation/travelValidation';

const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  }
});

const emit = defineEmits(['update:modelValue']);
const validationErrors = ref([]);
const showEditModal = ref(false);
const editingTravel = ref({});
const editingIndex = ref(-1);


function getCurrentDate() {
  const now = new Date();
  return `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}`;
}

function updateWeeks() {
  if (editingTravel.value.fromDate && editingTravel.value.toDate) {
    editingTravel.value.weeksSpent = calculateWeeks(
      editingTravel.value.fromDate,
      editingTravel.value.toDate
    );
  }
}

function addTravel() {
  editingIndex.value = -1;
  editingTravel.value = {
    country: '',
    fromDate: '',
    toDate: '',
    weeksSpent: 0,
    purpose: 'personal'
  };
  showEditModal.value = true;
}

function editTravel(index) {
  editingIndex.value = index;
  // Create a deep copy of the travel data
  editingTravel.value = JSON.parse(JSON.stringify(props.modelValue.travelHistory[index]));
  showEditModal.value = true;
}

function closeEditModal() {
  showEditModal.value = false;
  editingTravel.value = {};
  editingIndex.value = -1;
}

function validateTravel(travel) {
  const errors = [];

  // Basic field validation
  if (!travel.country) errors.push('Country is required');
  if (!travel.fromDate) errors.push('From date is required');
  if (!travel.toDate) errors.push('To date is required');
  if (!travel.weeksSpent) errors.push('Weeks spent is required');
  if (!travel.purpose) errors.push('Purpose is required');

  // Date validation
  if (travel.fromDate && travel.toDate) {
    const fromDate = new Date(travel.fromDate);
    const toDate = new Date(travel.toDate);
    if (fromDate > toDate) {
      errors.push('From date must be before To date');
    }
  }

  // Check for overlapping dates with existing travel records
  const otherTravels = props.modelValue.travelHistory.filter((_, i) => i !== editingIndex.value);
  for (const other of otherTravels) {
    const newFrom = new Date(travel.fromDate);
    const newTo = new Date(travel.toDate);
    const existingFrom = new Date(other.fromDate);
    const existingTo = new Date(other.toDate);

    if ((newFrom >= existingFrom && newFrom <= existingTo) ||
      (newTo >= existingFrom && newTo <= existingTo) ||
      (newFrom <= existingFrom && newTo >= existingTo)) {
      errors.push(`Travel dates overlap with existing travel to ${other.country}`);
      break;
    }
  }

  // Run utility validation
  const utilityErrors = [];
  validateTravelHistory([travel], utilityErrors);
  errors.push(...utilityErrors);

  validationErrors.value = errors;
  return errors.length === 0;
}

function saveEditedTravel() {
  if (!validateTravel(editingTravel.value)) return;

  if (editingIndex.value === -1) {
    props.modelValue.travelHistory.push(editingTravel.value);
  } else {
    props.modelValue.travelHistory[editingIndex.value] = editingTravel.value;
  }

  sortTravelHistory();
  emit('update:modelValue', props.modelValue);
  closeEditModal();
}

function removeTravel(index) {
  props.modelValue.travelHistory.splice(index, 1);
  emit('update:modelValue', props.modelValue);
}

function sortTravelHistory() {
  props.modelValue.travelHistory.sort((a, b) =>
    new Date(b.fromDate) - new Date(a.fromDate)
  );
}

function handleDateChange(index) {
  const travel = props.modelValue.travelHistory[index];
  if (travel.fromDate && travel.toDate) {
    const [fromYear, fromMonth] = travel.fromDate.split('-').map(Number);
    const [toYear, toMonth] = travel.toDate.split('-').map(Number);

    if (fromYear > toYear || (fromYear === toYear && fromMonth > toMonth)) {
      alert('From date must be before To date');
      travel.toDate = '';
    }
  }
  emit('update:modelValue', props.modelValue);
}

// Add this function in the script setup section
function calculateWeeks(fromDate, toDate) {
  if (!fromDate || !toDate) return 0;

  const start = new Date(fromDate);
  const end = new Date(toDate);
  // Set to last day of the month since we're working with month inputs
  end.setMonth(end.getMonth() + 1, 0);

  // Calculate the time difference in milliseconds
  const timeDiff = end - start;
  // Convert to weeks and round to nearest whole number
  return Math.round(timeDiff / (1000 * 60 * 60 * 24 * 7));
}

watch(() => props.modelValue.travelHistory, () => {
  validationErrors.value = [];
}, { deep: true });

function getCurrentYearMonth() {
  const now = new Date();
  return `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}`;
}

function formatDate(yearMonth) {
  if (!yearMonth) return '';

  try {
    const [year, month] = yearMonth.split('-');
    const date = new Date(Date.UTC(parseInt(year), parseInt(month) - 1, 1));

    return new Intl.DateTimeFormat('en', {
      year: 'numeric',
      month: 'long',
      timeZone: 'UTC'
    }).format(date);
  } catch (e) {
    return yearMonth;
  }
}
</script>
