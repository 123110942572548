/**
 * Validates criminal history conviction details
 */
export function validateConviction(conviction) {
    const required = ['charges', 'policeService', 'date', 'sentence'];
    const errors = [];

    // Check required fields
    required.forEach(field => {
        if (!conviction[field]?.trim()) {
            errors.push(`${field} is required`);
        }
    });

    // Validate location
    const loc = conviction.location || {};
    if (!loc.city?.trim() || !loc.province?.trim() || !loc.country?.trim()) {
        errors.push('Complete location details are required');
    }

    // Validate date format and recency
    if (conviction.date) {
        if (!/^\d{4}-\d{2}$/.test(conviction.date)) {
            errors.push('Date must be in YYYY-MM format');
        } else {
            const convictionDate = new Date(`${conviction.date}-01`);
            const fiveYearsAgo = new Date();
            fiveYearsAgo.setFullYear(fiveYearsAgo.getFullYear() - 5);

            if (convictionDate < fiveYearsAgo) {
                errors.push('Only convictions within the last 5 years are required');
            }
        }
    }

    // Optional: Validate police certificate if required
    if (conviction.requiresCertificate && !conviction.policeCertificate) {
        errors.push('Police certificate must be attached');
    }

    return {
        isValid: errors.length === 0,
        errors
    };
}


/**
 * Validates criminal history detention details
 */
export function validateDetention(detention) {
    const required = ['occurrence', 'details', 'date', 'status'];
    const errors = [];

    // Check required fields
    required.forEach(field => {
        if (!detention[field]?.trim()) {
            errors.push(`${field} is required`);
        }
    });

    // Validate location
    const loc = detention.location || {};
    if (!loc.city?.trim() || !loc.province?.trim() || !loc.country?.trim()) {
        errors.push('Complete location details are required');
    }

    // Optional: enforce date format
    if (detention.date && !/^\d{4}-\d{2}$/.test(detention.date)) {
        errors.push('Date must be in YYYY-MM format');
        // Return early to prevent duplicate date validation
        return {
            isValid: false,
            errors
        };
    }

    // validate recency 
    if (detention.date) {
        const detentionDate = new Date(`${detention.date}-01`);
        const fiveYearsAgo = new Date();
        fiveYearsAgo.setFullYear(fiveYearsAgo.getFullYear() - 5);

        if (detentionDate < fiveYearsAgo) {
            // Only add the recency error if we haven't already added a date format error
            if (errors.length === 0) {
                errors.push('Only detentions within the last 5 years are required');
            }
        }
    }

    return {
        isValid: errors.length === 0,
        errors
    };
}

/**
 * Validates complete criminal history section
 */
export function validateCriminalHistory(criminalHistory) {
    const errors = [];

    if (criminalHistory.hasConvictions === 'yes') {
        if (!Array.isArray(criminalHistory.convictions) || criminalHistory.convictions.length === 0) {
            errors.push('Please provide details of your convictions');
            return { isValid: false, errors };
        }

        criminalHistory.convictions.forEach((conviction, index) => {
            const result = validateConviction(conviction);
            if (!result.isValid) {
                errors.push(`Conviction ${index + 1}: ${result.errors.join(', ')}`);
            }
        });
    }

    if (Array.isArray(criminalHistory.detentions)) {
        criminalHistory.detentions.forEach((detention, index) => {
            const result = validateDetention(detention);
            if (!result.isValid) {
                errors.push(`Detention ${index + 1}: ${result.errors.join(', ')}`);
            }
        });
    }

    return {
        isValid: errors.length === 0,
        errors
    };
}
