import { helpers } from '@vuelidate/validators';

// Custom validators
export const phoneRegex = helpers.regex(/^\+?[\d\s-()]{10,}$/);
export const postalCodeRegex = helpers.regex(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
export const dateNotInFuture = (value) => !value || new Date(value) <= new Date();
export const dateNotInPast = (value) => !value || new Date(value) >= new Date();

// Validation messages
export const validationMessages = {
    required: 'This field is required',
    email: 'Please enter a valid email address',
    phone: 'Please enter a valid phone number (minimum 10 digits)',
    postalCode: 'Please enter a valid postal code (e.g., A1A 1A1)',
    dateNotInFuture: 'Date cannot be in the future',
    dateNotInPast: 'Date cannot be in the past',
    minLength: (min) => `Must be at least ${min} characters`,
    maxLength: (max) => `Must not exceed ${max} characters`,
    atLeastOne: 'At least one option must be selected',
    validDate: 'Please enter a valid date',
    validDateRange: 'End date must be after start date',
    requiredWhenMarried: 'This field is required when married or common-law',
    requiredWhenInternational: 'This field is required for international workers',
    fileRequired: 'Please upload the required document',
    fileSize: 'File size must not exceed 10MB',
    fileType: 'Invalid file type. Please upload a PDF, JPG, or PNG file',
};

// Helper functions
export const validateDateRange = (startDate, endDate) => {
    if (!startDate || !endDate) return true;
    return new Date(startDate) <= new Date(endDate);
};

export const validateFileUpload = (file) => {
    const maxSize = 10 * 1024 * 1024; // 10MB
    const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png'];

    if (file.size > maxSize) {
        return validationMessages.fileSize;
    }

    if (!allowedTypes.includes(file.type)) {
        return validationMessages.fileType;
    }

    return true;
};

// Form section validation rules
export const personalInfoRules = {
    surname: { required: true, minLength: 2 },
    givenNames: { required: true, minLength: 2 },
    gender: { required: true },
    dateOfBirth: { required: true, dateNotInFuture },
    maritalStatus: { required: true },
    spouse: {
        surname: { requiredWhenMarried: true },
        givenNames: { requiredWhenMarried: true },
        dateOfBirth: { requiredWhenMarried: true },
        citizenship: { requiredWhenMarried: true },
        telephone: { requiredWhenMarried: true, phoneRegex }
    }
};

export const contactRules = {
    businessPhone: { phoneRegex },
    homePhone: { phoneRegex },
    mobilePhone: { phoneRegex },
    businessEmail: { email: true },
    personalEmail: { email: true }
};

export const citizenshipRules = {
    citizenships: {
        $each: {
            country: { required: true }
        }
    },
    cityOfBirth: { required: true },
    countryOfBirth: { required: true },
    dateOfEntry: { required: true },
    portOfEntry: { requiredWhenInternational: true }
};

export const residentialRules = {
    streetNumber: { required: true },
    streetName: { required: true },
    city: { required: true },
    provinceTerritory: { required: true },
    country: { required: true },
    postalCode: { required: true, postalCodeRegex },
    fromDate: { required: true },
    toDate: { required: true }
};

export const referenceRules = {
    surname: { required: true },
    givenName: { required: true },
    relationship: { required: true },
    telephone: { required: true, phoneRegex },
    email: { required: true, email: true },
    type: { required: true }
};

export const securityClearanceRules = {
    level: { required: true },
    issuingCountry: { required: true },
    issuingDepartment: { required: true },
    expiryDate: { required: true, dateNotInPast }
};

export const documentRules = {
    identificationDocuments: { required: true },
    addressProof: { required: true },
    criminalRecordCheck: { requiredWhenInternational: true }
};

// Add helper function for date validation
export const validateHistoryGaps = (history) => {
    if (!history || !Array.isArray(history)) return false;

    // Sort all entries by fromDate
    const sortedEntries = [...history].sort((a, b) =>
        new Date(a.fromDate) - new Date(b.fromDate)
    );

    // Check for gaps between consecutive entries
    for (let i = 0; i < sortedEntries.length - 1; i++) {
        const currentEntry = sortedEntries[i];
        const nextEntry = sortedEntries[i + 1];

        const currentEndDate = currentEntry.isPresent ?
            new Date() :
            new Date(currentEntry.toDate);
        const nextStartDate = new Date(nextEntry.fromDate);

        // Calculate gap in days
        const gapDays = (nextStartDate - currentEndDate) / (1000 * 60 * 60 * 24);

        // If gap is more than 1 day and there's no unemployment entry covering it
        if (gapDays > 1) {
            const hasUnemploymentCoverage = history.some(entry =>
                entry.type === 'unemployment' &&
                new Date(entry.fromDate) <= currentEndDate &&
                (entry.isPresent || new Date(entry.toDate) >= nextStartDate)
            );

            if (!hasUnemploymentCoverage) {
                return false;
            }
        }
    }
    return true;
};

// Employment History Validation Functions
export function validateEmploymentHistory(history = [], errorsArray = []) {
    const errors = Array.isArray(errorsArray) ? errorsArray : [];

    if (!Array.isArray(history) || history.length === 0) {
        errors.push("At least one employment, education, or unemployment record is required");
        return false;
    }

    let isValid = true;
    const now = new Date();
    const fiveYearsAgo = new Date();
    fiveYearsAgo.setFullYear(fiveYearsAgo.getFullYear() - 5);

    const parseStartOfMonth = (ym) => new Date(`${ym}-01`);
    const parseEndOfMonth = (ym) => {
        const [year, month] = ym.split('-').map(Number);
        return new Date(year, month, 0);
    };
    const label = (entry) => entry.employer || entry.title || entry.type || 'an entry';

    // Sort by most recent (descending)
    const sortedHistory = [...history].sort(
        (a, b) => parseStartOfMonth(b.fromDate) - parseStartOfMonth(a.fromDate)
    );

    // 1. Validate current position
    const currentPositions = sortedHistory.filter(h => h.isPresent && h.type === 'employment');
    if (currentPositions.length === 0) {
        errors.push("Your current employment position must be listed");
        isValid = false;
    } else if (currentPositions.length > 1) {
        errors.push("Only one employment position can be marked as current");
        isValid = false;
    }

    // 2. Validate each entry
    sortedHistory.forEach((entry, index) => {
        const entryIndex = index + 1;

        // Basic date logic
        if (!entry.fromDate || (!entry.toDate && !entry.isPresent)) {
            errors.push(`Entry ${entryIndex}: Start date and either end date or 'Present' are required`);
            isValid = false;
        }

        if (entry.fromDate && parseStartOfMonth(entry.fromDate) > now) {
            errors.push(`Entry ${entryIndex}: Start date cannot be in the future`);
            isValid = false;
        }

        if (!entry.isPresent && entry.toDate && parseStartOfMonth(entry.fromDate) > parseEndOfMonth(entry.toDate)) {
            errors.push(`Entry ${entryIndex}: End date cannot be before start date`);
            isValid = false;
        }

        switch (entry.type) {
            case 'employment':
                if (!entry.employer?.trim() || !entry.title?.trim()) {
                    errors.push(`Entry ${entryIndex}: Employer and job title are required for employment entries`);
                    isValid = false;
                }
                if (!entry.isRemote && !validateAddress(entry.address)) {
                    errors.push(`Entry ${entryIndex}: Full address is required for non-remote positions`);
                    isValid = false;
                }
                break;

            case 'education':
                if (!entry.employer?.trim() || !entry.title?.trim()) {
                    errors.push(`Entry ${entryIndex}: Institution and program name are required for education entries`);
                    isValid = false;
                }
                if (!validateAddress(entry.address)) {
                    errors.push(`Entry ${entryIndex}: Full institution address is required`);
                    isValid = false;
                }
                break;

            case 'unemployment':
                if (!entry.description?.trim()) {
                    errors.push(`Entry ${entryIndex}: Reason is required for unemployment periods`);
                    isValid = false;
                }
                break;

            default:
                errors.push(`Entry ${entryIndex}: Invalid entry type "${entry.type}"`);
                isValid = false;
        }
    });

    // 3. Chronological order check
    for (let i = 0; i < sortedHistory.length - 1; i++) {
        if (parseStartOfMonth(sortedHistory[i].fromDate) < parseStartOfMonth(sortedHistory[i + 1].fromDate)) {
            errors.push("Entries must be listed from most recent to oldest");
            isValid = false;
            break;
        }
    }

    // 4. Check for full 5-year coverage
    const earliestDate = sortedHistory.reduce((min, entry) => {
        const start = parseStartOfMonth(entry.fromDate);
        return start < min ? start : min;
    }, now);

    if (earliestDate > fiveYearsAgo) {
        errors.push("Employment history must cover at least the past 5 years");
        isValid = false;
    }

    // 5. Detect and validate gaps
    for (let i = 0; i < sortedHistory.length - 1; i++) {
        const current = sortedHistory[i];
        const next = sortedHistory[i + 1];

        const currentEnd = current.isPresent ? now : parseEndOfMonth(current.toDate);
        const nextStart = parseStartOfMonth(next.fromDate);

        const gapInMonths = (nextStart.getFullYear() - currentEnd.getFullYear()) * 12 +
            (nextStart.getMonth() - currentEnd.getMonth());

        if (gapInMonths > 0) {
            const coveredByUnemployment = history.some(entry =>
                entry.type === 'unemployment' &&
                parseStartOfMonth(entry.fromDate) <= currentEnd &&
                (entry.isPresent || parseEndOfMonth(entry.toDate) >= nextStart)
            );

            if (!coveredByUnemployment) {
                errors.push(`Gap detected between ${label(current)} and ${label(next)}. ` +
                    `Please add an unemployment entry to cover this period.`);
                isValid = false;
            }
        }
    }

    return isValid;
}


function validateAddress(address) {
    if (!address) return false;

    const requiredFields = [
        'streetNumber',
        'streetName',
        'city',
        'province',
        'country',
        'postalCode'
    ];

    return requiredFields.every(field =>
        address[field] && address[field].trim().length > 0
    );
}

// Update employmentHistoryRules to use the consolidated validation
export const employmentHistoryRules = {
    $each: {
        employer: {
            required: helpers.withMessage('Employer/Institution name is required', value => !!value?.trim())
        },
        title: {
            required: helpers.withMessage('Position/Program title is required', value => !!value?.trim())
        },
        fromDate: {
            required: helpers.withMessage('Start date is required', value => !!value),
            dateNotInFuture: helpers.withMessage('Start date cannot be in the future', dateNotInFuture)
        },
        toDate: {
            required: helpers.withMessage(
                'End date is required for past positions',
                (value, vm) => !vm.isPresent ? !!value : true
            ),
            dateNotInFuture: helpers.withMessage('End date cannot be in the future', dateNotInFuture)
        },
        type: {
            required: helpers.withMessage('Type is required', value =>
                ['employment', 'education', 'unemployment'].includes(value)
            )
        },
        description: {
            required: helpers.withMessage(
                'Description is required for unemployment periods',
                (value, vm) => vm.type !== 'unemployment' || !!value?.trim()
            )
        },
        address: {
            required: helpers.withMessage(
                'Address is required for employment and education entries',
                (value, vm) => vm.type === 'unemployment' || validateAddress(value)
            )
        }
    },
    $trackBy: 'id',
    validate: helpers.withMessage(
        'Please fix the validation errors in employment history',
        (value) => {
            const errors = [];
            return validateEmploymentHistory(value, errors);
        }
    )
};
