<template>
  <Teleport to="body">
    <transition name="modal-fade">
      <div v-if="modelValue" 
           class="fixed inset-0 z-50 overflow-y-auto"
           role="dialog"
           aria-modal="true"
           @keydown.esc="close">
        <!-- Backdrop -->
        <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
             @click="close"
             aria-hidden="true">
        </div>

        <!-- Modal Panel -->
        <div class="flex min-h-screen items-center justify-center p-4">
          <div class="relative w-full max-w-2xl transform overflow-hidden rounded-lg bg-white shadow-xl transition-all"
               @click.stop
               ref="modalPanel">
            <!-- Header -->
            <div class="bg-white px-4 py-3 border-b border-gray-200">
              <div class="flex items-center justify-between">
                <h3 class="text-lg font-medium text-gray-900">
                  <slot name="title">Modal Title</slot>
                </h3>
                <button type="button"
                        @click="close"
                        class="text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500">
                  <span class="sr-only">Close</span>
                  <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>

            <!-- Content -->
            <div class="px-4 py-5 sm:p-6">
              <slot name="content"></slot>
            </div>

            <!-- Footer -->
            <div class="bg-gray-50 px-4 py-3 border-t border-gray-200 flex justify-end space-x-3">
              <slot name="footer"></slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </Teleport>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch } from 'vue';

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['update:modelValue', 'close']);
const modalPanel = ref(null);

// Close modal
const close = () => {
  emit('update:modelValue', false);
  emit('close');
};

// Handle focus trap
const handleTab = (e) => {
  if (!modalPanel.value) return;

  const focusable = modalPanel.value.querySelectorAll(
    'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
  );
  
  const firstFocusable = focusable[0];
  const lastFocusable = focusable[focusable.length - 1];

  if (e.shiftKey) {
    if (document.activeElement === firstFocusable) {
      lastFocusable.focus();
      e.preventDefault();
    }
  } else {
    if (document.activeElement === lastFocusable) {
      firstFocusable.focus();
      e.preventDefault();
    }
  }
};

// Event listeners
onMounted(() => {
  document.addEventListener('keydown', handleTab);
});

onUnmounted(() => {
  document.removeEventListener('keydown', handleTab);
});

// Focus management
watch(() => props.modelValue, (newVal) => {
  if (newVal && modalPanel.value) {
    const focusable = modalPanel.value.querySelector('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
    if (focusable) {
      focusable.focus();
    }
  }
});
</script>

<style scoped>
.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.3s ease;
}

.modal-fade-enter-from,
.modal-fade-leave-to {
  opacity: 0;
}
</style>
