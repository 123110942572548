<template>
  <div class="flex min-h-screen bg-gray-50">
    <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div class="mx-auto w-full max-w-md">
        <h2 class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
          Complete Your Registration
        </h2>

        <div v-if="loading" class="mt-8 text-center">
          <div
            class="inline-block animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"
          ></div>
          <p class="mt-2 text-sm text-gray-600">Verifying invitation...</p>
        </div>

        <form
          v-else-if="!verified"
          class="mt-8 space-y-6"
          @submit.prevent="handleSetPassword"
        >
          <!-- Error Alert -->
          <div v-if="error" class="rounded-md bg-red-50 p-4 mb-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
                </svg>
              </div>
              <div class="ml-3">
                <p class="text-sm font-medium text-red-800">{{ error }}</p>
              </div>
            </div>
          </div>

          <div class="space-y-4 rounded-md shadow-sm">
            <!-- Password Field -->
            <div class="relative">
              <label for="password" class="block text-sm font-medium text-gray-700"
                >New Password</label>
              <div class="mt-1 relative rounded-md shadow-sm">
                <input
                  v-model="password"
                  :type="showPassword ? 'text' : 'password'"
                  id="password"
                  required
                  class="block w-full rounded-md border-gray-300 pr-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
                <button
                  type="button"
                  @click="showPassword = !showPassword"
                  class="absolute inset-y-0 right-0 flex items-center pr-3"
                >
                  <svg v-if="showPassword" class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                  </svg>
                  <svg v-else class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                  </svg>
                </button>
              </div>
            </div>

            <!-- Confirm Password Field -->
            <div class="relative">
              <label for="confirmPassword" class="block text-sm font-medium text-gray-700"
                >Confirm Password</label>
              <div class="mt-1 relative rounded-md shadow-sm">
                <input
                  v-model="confirmPassword"
                  :type="showConfirmPassword ? 'text' : 'password'"
                  id="confirmPassword"
                  required
                  class="block w-full rounded-md border-gray-300 pr-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
                <button
                  type="button"
                  @click="showConfirmPassword = !showConfirmPassword"
                  class="absolute inset-y-0 right-0 flex items-center pr-3"
                >
                  <svg v-if="showConfirmPassword" class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                  </svg>
                  <svg v-else class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                  </svg>
                </button>
              </div>
            </div>

            <!-- Password Requirements -->
            <div class="text-sm text-gray-600">
              <p>Password must:</p>
              <ul class="list-disc list-inside mt-1">
                <li>Be at least 8 characters long</li>
                <li>Include at least one uppercase letter</li>
                <li>Include at least one number</li>
                <li>Include at least one special character</li>
              </ul>
            </div>
          </div>

          <div>
            <button
              type="submit"
              :disabled="loading"
              class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
            >
              Complete Registration
            </button>
          </div>
        </form>

        <div v-else-if="verified" class="mt-8 text-center">
          <div class="text-green-500 text-xl mb-4">✓</div>
          <h3 class="text-lg font-medium text-gray-900">{{ success }}</h3>
          <p class="mt-2 text-sm text-gray-600">Redirecting to login...</p>
        </div>

        <div v-else class="mt-8 text-center">
          <div class="text-red-500 text-xl mb-4">✕</div>
          <h3 class="text-lg font-medium text-gray-900">Verification Failed</h3>
          <p class="mt-2 text-sm text-gray-600">{{ error }}</p>
          <p class="mt-4">
            <router-link to="/login" class="text-indigo-600 hover:text-indigo-500">
              Return to login
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useInternalAuthStore } from "@/stores/internalAuth";

const route = useRoute();
const router = useRouter();
const authStore = useInternalAuthStore();

const loading = ref(true);
const verified = ref(false);
const error = ref("");
const password = ref("");
const confirmPassword = ref("");
const success = ref("");
const showPassword = ref(false);
const showConfirmPassword = ref(false);

onMounted(async () => {
  const { secret } = route.query;
  if (!secret) {
    error.value = "Invalid invitation link";
    loading.value = false;
    return;
  }

  try {
    loading.value = true;
    error.value = "";
    await authStore.verifyInvitation(secret); // Make sure this uses the correct endpoint
    loading.value = false;
  } catch (err) {
    console.error("Verification error:", err);
    error.value = err.response?.data?.error || "Invalid or expired invitation";
    loading.value = false;
  }
});

// Update the handleSetPassword function to use the correct endpoint
const handleSetPassword = async () => {
  if (password.value !== confirmPassword.value) {
    error.value = "Passwords do not match";
    return;
  }

  // Add password validation
  const passwordRegex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
  if (!passwordRegex.test(password.value)) {
    error.value = "Password does not meet requirements";
    return;
  }

  loading.value = true;
  try {
    const response = await authStore.completeInvitation(
      route.query.secret,
      password.value
    );
    if (response.status === "ok") {
      verified.value = true;
      success.value = response.message;
      authStore.user = response.user;
      authStore.isAuthenticated = true;
      setTimeout(() => {
        router.push("/");
      }, 2000);
    }
  } catch (err) {
    error.value = err.response?.data?.error || "Failed to complete registration";
  } finally {
    loading.value = false;
  }
};
</script>
