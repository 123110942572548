<script setup>
import { ref, watch, onMounted, computed } from 'vue';
import SectionInstructions from '../../SectionInstructions.vue';
import { validateReferences } from '../../../utils/validation/referenceValidation';

const props = defineProps({
  modelValue: { type: Object, required: true },
  personalInfo: Object,
  residentialHistory: Array
});

const emit = defineEmits(['update:modelValue']);

const validationErrors = ref([]);
const showEditModal = ref(false);
const showRemoveModal = ref(false);
const editingReference = ref({});
const editingIndex = ref(-1);
const removeIndex = ref(-1);

const references = computed(() => props.modelValue.references || []);
const canAddReference = computed(() => references.value.length < 5);

onMounted(() => validateAllReferences());
watch(() => references.value, validateAllReferences, { deep: true });

function formatDate(date) {
  if (!date) return '';
  return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long' });
}

function validateAllReferences() {
  validationErrors.value = [];
  validateReferences(references.value, props.personalInfo, props.residentialHistory, validationErrors.value);
}

function validateForm(reference) {
  const errors = [];
  const required = ['givenName', 'surname', 'relationship', 'type', 'knownSince', 'telephone', 'email'];
  required.forEach(field => {
    if (!reference[field]) {
      errors.push(`${field.charAt(0).toUpperCase() + field.slice(1)} is required`);
    }
  });
  return errors;
}

function editReference(index) {
  editingIndex.value = index;
  editingReference.value = { ...references.value[index] };
  showEditModal.value = true;
}

function addReference() {
  editingIndex.value = -1;
  editingReference.value = {
    givenName: '',
    surname: '',
    relationship: '',
    type: 'personal',
    knownSince: '',
    telephone: '',
    email: ''
  };
  showEditModal.value = true;
}


function saveReference() {
  const formErrors = validateForm(editingReference.value);
  if (formErrors.length > 0) {
    validationErrors.value = formErrors;
    return;
  }

  const updatedReferences = [...references.value];
  const newEntry = { ...editingReference.value };

  if (editingIndex.value > -1) {
    updatedReferences[editingIndex.value] = newEntry;
  } else {
    updatedReferences.push(newEntry);
  }

  emit('update:modelValue', { ...props.modelValue, references: updatedReferences });
  closeEditModal();
}

function confirmRemove(index) {
  removeIndex.value = index;
  showRemoveModal.value = true;
}

function executeRemove() {
  const updated = [...references.value];
  updated.splice(removeIndex.value, 1);
  emit('update:modelValue', { ...props.modelValue, references: updated });
  showRemoveModal.value = false;
}

function closeRemoveModal() {
  removeIndex.value = -1;
  showRemoveModal.value = false;
}

function removeReference(index) {
  confirmRemove(index);
}

function closeEditModal() {
  editingReference.value = {};
  editingIndex.value = -1;
  showEditModal.value = false;
}
</script>

<template>
  <div>
    <h2 class="text-xl font-bold mb-4">References</h2>
    <SectionInstructions sectionTitle="References" sectionId="references" />

    <div class="overflow-x-auto">
      <table class="min-w-full divide-y divide-gray-200 text-sm">
        <thead class="bg-gray-50">
          <tr>
            <th class="px-4 py-2 text-left font-medium text-gray-500 uppercase">Name</th>
            <th class="px-4 py-2 text-left font-medium text-gray-500 uppercase">Type</th>
            <th class="px-4 py-2 text-left font-medium text-gray-500 uppercase">Known Since</th>
            <th class="px-4 py-2 text-left font-medium text-gray-500 uppercase">Contact</th>
            <th class="px-4 py-2 text-right font-medium text-gray-500 uppercase">Actions</th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="(ref, index) in references" :key="index">
            <td class="px-4 py-2">
              <div class="font-medium text-gray-900">{{ ref.surname }}, {{ ref.givenName }}</div>
              <div class="text-gray-500 text-xs">{{ ref.relationship }}</div>
            </td>
            <td class="px-4 py-2">
              <span :class="[
                'px-2 py-0.5 rounded-full text-xs font-semibold inline-flex',
                ref.type === 'professional' ? 'bg-green-100 text-green-800' : 'bg-blue-100 text-blue-800'
              ]">
                {{ ref.type.charAt(0).toUpperCase() + ref.type.slice(1) }}
              </span>
            </td>
            <td class="px-4 py-2">{{ formatDate(ref.knownSince) }}</td>
            <td class="px-4 py-2">
              <div>{{ ref.telephone }}</div>
              <div class="text-xs text-gray-500">{{ ref.email }}</div>
            </td>
            <td class="px-4 py-2 text-right whitespace-nowrap">
              <button @click="editReference(index)" class="text-blue-600 hover:underline mr-2">Edit</button>
              <button @click="removeReference(index)" class="text-red-600 hover:underline">
                Remove
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <button v-if="canAddReference" type="button" @click="addReference"
      class="mt-4 inline-flex items-center px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded hover:bg-blue-700">
      Add Reference
    </button>

    <!-- Error Display -->
    <div v-if="validationErrors.length" class="mt-4 p-4 bg-red-50 border border-red-200 rounded text-sm text-red-700">
      <h4 class="font-medium mb-2">Please correct the following issues:</h4>
      <ul class="list-disc pl-5 space-y-1">
        <li v-for="(error, index) in validationErrors" :key="index">{{ error }}</li>
      </ul>
    </div>

    <!-- Modal -->
    <div v-if="showEditModal" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
      @click.self="closeEditModal">
      <div class="bg-white rounded-lg p-6 max-w-xl w-full shadow-xl" @click.stop>
        <h3 class="text-lg font-bold mb-4">{{ editingIndex > -1 ? 'Edit' : 'Add' }} Reference</h3>

        <!-- errors -->
        <div v-if="validationErrors.length" class="mb-4 p-4 bg-red-50 border border-red-200 rounded text-sm text-red-700">
          <h4 class="font-medium mb-2">Please correct the following issues:</h4>
          <ul class="list-disc pl-5 space-y-1">
            <li v-for="(error, index) in validationErrors" :key="index">{{ error }}</li>
          </ul>
        </div>
        <div class="grid grid-cols-2 gap-4">
          <div>
            <label class="block text-sm font-medium text-gray-700">Given Name</label>
            <input v-model="editingReference.givenName" type="text"
              class="mt-1 block w-full rounded-md border-gray-300" />
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700">Surname</label>
            <input v-model="editingReference.surname" type="text"
              class="mt-1 block w-full rounded-md border-gray-300" />
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700">Type</label>
            <select v-model="editingReference.type" class="mt-1 block w-full rounded-md border-gray-300">
              <option value="personal">Personal</option>
              <option value="professional">Professional</option>
            </select>
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700">Known Since</label>
            <input v-model="editingReference.knownSince" type="month"
              class="mt-1 block w-full rounded-md border-gray-300" />
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700">Telephone</label>
            <input v-model="editingReference.telephone" type="tel"
              class="mt-1 block w-full rounded-md border-gray-300" />
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700">Email</label>
            <input v-model="editingReference.email" type="email" class="mt-1 block w-full rounded-md border-gray-300" />
          </div>
          <div class="col-span-2">
            <label class="block text-sm font-medium text-gray-700">Relationship</label>
            <input v-model="editingReference.relationship" type="text"
              class="mt-1 block w-full rounded-md border-gray-300" />
          </div>
        </div>

        <div class="mt-6 flex justify-end space-x-2">
          <button type="button" @click="closeEditModal" class="text-sm text-gray-700 hover:underline">Cancel</button>
          <button type="button" @click="saveReference"
            class="text-sm px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700">
            Save
          </button>
        </div>
      </div>
    </div>

    <!-- Remove Confirmation Modal -->
    <div v-if="showRemoveModal" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
      @click.self="closeRemoveModal">
      <div class="bg-white rounded-lg p-6 max-w-md w-full shadow-xl" @click.stop>
        <h3 class="text-lg font-bold mb-4">Confirm Removal</h3>
        <p class="mb-6">Are you sure you want to remove this reference?</p>
        <div class="flex justify-end space-x-2">
          <button type="button" @click="closeRemoveModal" class="text-sm text-gray-700 hover:underline">Cancel</button>
          <button type="button" @click="executeRemove"
            class="text-sm px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700">
            Remove
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
