/**
 * Creates an empty address object
 */
export function createEmptyAddress(streetNum = "", streetN = "", cityName = "", prov = "", ctry = "", postal = "") {
  return {
    streetNumber: streetNum,
    streetName: streetN,
    city: cityName,
    province: prov,
    country: ctry,
    postalCode: postal,
  };
}

/**
 * Creates an empty employment record
 */
export function createEmptyEmploymentRecord() {
  return {
    type: "employment",
    employer: "Protocase Inc. II",
    title: "",
    address: createEmptyAddress(
      "46",
      "Wabana Ct",
      "Sydney",
      "NS",
      "Canada",
      "B1P 0B9"
    ),
    fromDate: "",
    toDate: "",
    isPresent: true,
    description: "",
    isRemote: false,
  };
}

/**
 * Returns the default state of the security assessment form data.
 */
export function getDefaultFormData() {
  return {
    applicationType: "",
    consent: { agreed: false, signatureDate: "" },
    preferredLanguage: "english",
    personal: {
      surname: "",
      givenNames: "",
      surnameAtBirth: "",
      otherNames: "",
      gender: "",
      dateOfBirth: "",
      maritalStatus: "",
      nameChanges: [{ fromName: "", toName: "", dateOfChange: "" }],
      spouse: {
        surname: "",
        givenNames: "",
        dateOfBirth: "",
        citizenship: "",
        telephone: "",
      },
      visaType: "",
      visaNumber: "",
      visaExpiryDate: "",
    },
    contact: {
      businessPhone: "",
      homePhone: "",
      mobilePhone: "",
      businessEmail: "",
      personalEmail: "",
    },
    citizenship: {
      citizenships: [
        {
          country: "", // Allow multiple citizenships
        },
      ],
      permanentResidentCountries: "",
      cityOfBirth: "",
      provinceOfBirth: "",
      countryOfBirth: "",
      dateOfEntry: "",
      countryOfEmigration: "",
      proofDocument: null, // For government-issued ID upload
      proofDocumentType: "", // To specify type of proof (passport, birth certificate, etc)
      portOfEntry: "",
      hasValidForeignPassport: false,
      foreignPassports: [],
    },
    residentialHistory: [
      {
        apartmentNumber: "",
        streetNumber: "",
        streetName: "",
        city: "",
        provinceTerritory: "",
        country: "",
        postalCode: "",
        fromDate: "",
        toDate: "",
        isPresent: true,
      },
    ],
    employmentHistory: [createEmptyEmploymentRecord()],
    criminalHistory: {
      hasConvictions: false,
      convictions: [
        { charges: "", policeService: "", location: "", date: "", sentence: "" },
      ],
      detentions: [{ occurrence: "", details: "", location: "", date: "", status: "" }],
    },
    travelHistory: [
      { country: "", fromDate: "", toDate: "", weeksSpent: "", purpose: "" },
    ],
    references: [
      {
        surname: "",
        givenName: "",
        relationship: "",
        knownSince: "",
        telephone: "",
        address: "",
        email: "",
        type: "",
      },
    ],
    securityClearance: {
      level: "",
      issuingCountry: "",
      issuingDepartment: "",
      expiryDate: "",
    },
    statements: {
      statement1: {
        value: null,
        details: "",
        label:
          "I hold a valid passport of a country other than Canada. If yes, specify which country or countries:",
        data_key: "statements_valid_passport",
        applyTo: ['international', 'canadian'],
      },
      statement2: {
        value: null,
        details: "",
        label:
          "I have travelled outside of Canada for pleasure more than 10 times in the last five years (excluding the United States).",
        data_key: "statements_travelled_outside_canada_pleasure",
        applyTo: ['canadian'],
      },
      statement3: {
        value: null,
        details: "",
        label:
          "I have travelled outside of Canada for business (excluding the United States).",
        data_key: "statements_travelled_outside_canada_business",
        applyTo: ['canadian'],
      },
      statement4: {
        value: null,
        details: "",
        label:
          "I have resided outside of Canada (excluding the United States). If yes, provide details (city/town of residence, country and dates):",
        data_key: "statements_resided_outside_canada",
        applyTo: ['canadian'],
      },
      statement5: {
        value: null,
        details: "",
        label:
          "I have immediate family members that reside in a country outside of Canada (excluding the United States). If yes, list the country and relationship:",
        data_key: "statements_family_outside_canada",
        applyTo: ['canadian'],
      },
      statement6: {
        value: null,
        details: "",
        label:
          "I have received financial support from an organization or government agency outside of Canada for my education (excluding the United States). If yes, provide name of organization and/or government and country:",
        data_key: "statements_financial_support_outside_canada",
        applyTo: ['canadian'],
      },
      statement7: {
        value: null,
        details: "",
        label:
          "I own or have partial ownership (20% or more voting shares) in a business or company outside of Canada (excluding the United States). If yes, provide details:",
        data_key: "statements_partial_ownership",
        applyTo: ['canadian'],
      },
      statement8: {
        value: null,
        details: "",
        label:
          "I have had contact (meetings, business or social activities) with officials of a foreign government (excluding the United States). If yes, provide details:",
        data_key: "statements_contact_non_canadian",
        applyTo: ['canadian'],
      },
      statement9: {
        value: null,
        details: "",
        label:
          "I have sponsored a foreign individual for entry into Canada. If yes, provide relationship to individual, date and country of origin:",
        data_key: "statements_sponsored_foreign_individual",
        applyTo: ['canadian'],
      },
      statement10: {
        value: null,
        details: "",
        label:
          "I have travelled outside of Canada for a period greater than 90 consecutive days (excluding the United States). If yes, provide details, country, dates, etc.:",
        data_key: "statements_travelled_outside_90_days",
        applyTo: ['canadian'],
      },
      statement11: {
        value: null,
        details: "",
        label:
          "I received my education outside of Canada. If yes, provide details on program of study, country and dates, etc.:",
        data_key: "statements_education_outside_canada",
        applyTo: ['canadian'],
      },
      statement12: {
        value: null,
        details: "",
        label:
          "I have been denied entry into a country. If yes, provide reason, country, dates, etc.:",
        data_key: "statements_denied_entry_canada",
        applyTo: ['canadian'],
      },
      statement13: {
        value: null,
        details: "",
        label:
          `I have previously been ordered to surrender my passport to a government official (example: customs, police, passport agency, immigration official, etc.)If yes, provide date, location, to whom the passport was surrendered, reasons and outcome: `,
        data_key: "statements_surrender_passport",
        applyTo: ['international', 'canadian'],
      },
      statement14: {
        value: null,
        details: "",
        label:
          "I have worked for a company located in a foreign country (excluding the United States). If yes, provide company name, country and dates of employment:",
        data_key: "statements_worked_in_other_country",
        applyTo: ['canadian'],
      },
      statement15: {
        value: null,
        details: "",
        label:
          "I consider myself to have allegiance to a country other than Canada or the United States. If yes, provide name of country:",
        data_key: "statements_allegiance_other_country",
        applyTo: ['canadian'],
      },
      statement16: {
        value: null,
        details: "",
        label:
          "I have worked for a foreign government, including military service (excluding the United States). If yes, provide dates, nationality of government, government department and position(s) held:",
        data_key: "statements_worked_foreign_gov",
        applyTo: ['canadian'],
      },
      statement17: {
        value: null,
        details: "",
        label:
          "I have served in a security / intelligence organization in a country other than Canada (excluding the United States). If yes, list organization and country:",
        data_key: "statements_served_inteliigence_other_countries",
        applyTo: ['canadian'],
      },
      statement18: {
        value: null,
        details: "",
        label:
          "I have previously had a security clearance denied, suspended or revoked. If yes, provide details:",
        data_key: "statements_issued_security_status",
        applyTo: ['international', 'canadian'],
      },
      statement19: {
        value: null,
        details: "",
        label:
          "I have a meaningful association with an individual who has a criminal record, is a member of, or a supporter of, a group involved in espionage, terrorism, organized crime, proliferation of controlled goods, subversive or militant activities. If yes, provide details:",

        data_key: 'statements_association_with_criminal',
        applyTo: ['international', 'canadian'],
      },
      statement20: {
        value: null,
        details: "",
        label:
          "I have been terminated or asked to resign from a position due to a theft or fraud related incident(s). If yes, provide details:",
        data_key: 'statements_resign_for_theft',
        applyTo: ['canadian'],
      },
      statement21: {
        value: null,
        details: "",
        label: "I have filed for bankruptcy. If yes, provide details:",
        data_key: "statements_bankruptcy",
        applyTo: ["international", "domestic"],
      },
      statement22: {
        value: null,
        details: "",
        label:
          "I currently have a financial judgement against me. If yes, provide details:",
        data_key: "statements_financial_judgement",
        applyTo: ["international", "domestic"],
      },
      statement23: {
        value: null,
        details: "",
        label:
          "I have previously been denied credit and/or had my credit card revoked. If yes, provide details:",
        data_key: "statements_denied_credit",
        applyTo: ["international", "domestic"],
      },
      statement24: {
        value: null,
        details: "",
        label:
          `I have previously applied for a security status/ and or clearance with the Government of Canada, a foreign government and/or private sector organization or an international body. If yes, provide details (e.g., type, reason, etc):`,
        data_key: "statements_applied_security_before",
        applyTo: ['international'],
      },
      statement25: {
        value: null,
        details: "",
        label:
          "I have or a member of my immediate family, had contact with foreign representatives in Canada or visited their place of employment or residence. If yes, provide details:",
        data_key: "statements_contact_foreign_rep",
        applyTo: ['international'],
      },
      statement26: {
        value: null,
        details: "",
        label:
          "I have or my family has received correspondence, whether solicited or not, from a foreign government. If yes, provide details:",
        data_key: "statements_family_correspondence_with_foreign_gov",
        applyTo: ['international'],
      },
      statement27: {
        value: null,
        details: "",
        label:
          "I have had direct or indirect contact with anyone whom I know, belongs to or I suspect belongs to a foreign intelligence service. If yes, provide details: ",
        data_key: "statements_foreign_intelligence",
        applyTo: ['international'],
      },
      statement28: {
        value: null,
        details: "",
        label:
          "I have had professional or social contacts with representatives of foreign governments. If so, which government(s) and under what circumstances.",
        data_key: "statements_foreign_gov_social_contacts",
        applyTo: ['international'],
      },
      statement29: {
        value: null,
        details: "",
        label:
          "I have financial, personal or property assets including bank accounts in countries for which I am not a citizen of. If yes, provide details (e.g. banking institution name, country that the banking institution is based, location of property, etc):",
        data_key: "statements_foreign_assets",
        applyTo: ['international'],
      },
      statement30: {
        value: null,
        details: "",
        label:
          "I currently financially support an individual(s) or organization(s) outside of Canada. If yes, provide details:",
        data_key: "statements_foreign_financial_support",
        applyTo: ['international'],
      },
      statement31: {
        value: null,
        details: "",
        label:
          "I have been terminated or asked to resign from a position due to a theft or fraud related incident(s). If yes, provide details:",
        data_key: "statements_resign_for_theft",
        applyTo: ['international'],
      },
    },
    requiredDocuments: {
      passportPages: null,
      workPermit: null,
      criminalRecord: null,
      identityDocuments: null,
    },
    supportingDocuments: {
      identificationDocuments: [], // For passport, driver's license, etc.
      addressProof: [], // For utility bills, lease agreements, etc.
      employmentDocuments: [], // For employment letters, pay stubs, etc.
      educationDocuments: [], // For degrees, certificates, etc.
      securityClearanceDocuments: [], // For existing security clearances
      criminalRecordCheck: [], // For police certificates
      additionalDocuments: [], // For any other supporting documents
    },
  };
}