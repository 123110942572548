<template>
  <div class="max-w-4xl mx-auto">
    <div class="mb-6">
      <h2 class="text-2xl font-bold text-gray-900 mb-2">Supporting Documents</h2>
      <SectionInstructions sectionTitle="Supporting Documents" sectionId="documents" />
    </div>

    <!-- Info box with improved styling -->
    <div class="mb-6 p-4 bg-blue-50 border-l-4 border-blue-400 rounded flex items-start">
      <div class="flex-shrink-0">
        <svg class="h-5 w-5 text-blue-400" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clip-rule="evenodd" />
        </svg>
      </div>
      <div class="ml-3">
        <p class="text-sm text-blue-700">
          Please upload all required documents in PDF, JPG, or PNG format.
          Make sure all documents are clear and legible.
        </p>
      </div>
    </div>

    <!-- Document sections with visual grouping -->
    <div class="space-y-8">
      <!-- Required Documents Group -->
      <div class="bg-white shadow rounded-lg p-6">
        <h3 class="text-lg font-medium text-gray-900 mb-4">Required Documents</h3>
        <div class="space-y-6">
          <FileUploadSection v-model="modelValue.supportingDocuments.identificationDocuments"
            title="Identification Documents"
            description="Upload government-issued photo ID (passport, driver's license, etc.)" :required="true"
            :accept="'.pdf,.jpg,.jpeg,.png'" :multiple="true" :maxFiles="3" documentType="identification"
            :assessmentId="assessmentId" @update:modelValue="handleFileChange('identificationDocuments', $event)"
            
            />
          <FileUploadSection v-model="modelValue.supportingDocuments.addressProof"
            title="Address Proof"
            description="Upload a recent utility bill, bank statement" :required="true"
            :accept="'.pdf,.jpg,.jpeg,.png'" :multiple="true" :maxFiles="3" documentType="addressProof"
            :assessmentId="assessmentId" @update:modelValue="handleFileChange('addressProof', $event)"
            
            />
          <FileUploadSection v-model="modelValue.supportingDocuments.employmentDocuments"
            title="Employment Documents"
            description="Upload recent pay stubs or employment verification letters" :required="true"
            :accept="'.pdf,.jpg,.jpeg,.png'" :multiple="true" :maxFiles="3" documentType="employmentDocuments"
            :assessmentId="assessmentId" @update:modelValue="handleFileChange('employmentDocuments', $event)"
            
            />
          <FileUploadSection v-model="modelValue.supportingDocuments.educationDocuments"
            title="Education Documents"
            description="Upload diplomas, transcripts, or certificates" :required="true"
            :accept="'.pdf,.jpg,.jpeg,.png'" :multiple="true" :maxFiles="3" documentType="educationDocuments"
            :assessmentId="assessmentId" @update:modelValue="handleFileChange('educationDocuments', $event)"
            
            />
          <FileUploadSection v-model="modelValue.supportingDocuments.securityClearanceDocuments"
            title="Security Clearance Documents"
            description="Upload any security clearance documents if applicable" :required="false"
            :accept="'.pdf,.jpg,.jpeg,.png'" :multiple="true" :maxFiles="3" documentType="securityClearanceDocuments"
            :assessmentId="assessmentId" @update:modelValue="handleFileChange('securityClearanceDocuments', $event)"
            
            />
          <FileUploadSection v-if="modelValue.applicationType === 'international'"
            v-model="modelValue.supportingDocuments.workPermit"
            title="Work Permit"
            description="Upload a valid work permit if applicable" :required="false"
            :accept="'.pdf,.jpg,.jpeg,.png'" :multiple="true" :maxFiles="3" documentType="workPermit"
            :assessmentId="assessmentId" @update:modelValue="handleFileChange('workPermit', $event)"
            
            />
          <FileUploadSection v-if="modelValue.applicationType === 'international'"
            v-model="modelValue.supportingDocuments.criminalRecordCheck"
            title="Criminal Record Check"
            description="Upload a recent criminal record check if applicable" :required="false"
            :accept="'.pdf,.jpg,.jpeg,.png'" :multiple="true" :maxFiles="3" documentType="criminalRecordCheck"
            :assessmentId="assessmentId" @update:modelValue="handleFileChange('criminalRecordCheck', $event)"
            
            />  

        </div>
      </div>


    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useInternalAuthStore } from '../../../stores/internalAuth';
import { useSecurityAssessmentStore } from '../../../stores/securityAssessment';
import SectionInstructions from '../../SectionInstructions.vue';
import FileUploadSection from '../../FileUploadSection.vue';

const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  }
});

const emit = defineEmits(['update:modelValue']);
const route = useRoute();
const authStore = useInternalAuthStore();
const securityStore = useSecurityAssessmentStore();

// Get assessmentId from route if we're editing an existing assessment
const assessmentId = computed(() => {
  return route.params.id || securityStore.draftId || null;
});

// Check if we have a user ID to associate with uploads
const hasUserId = computed(() => {
  return !!authStore.user?.id;
});

function handleFileChange(field, files) {
  if (!props.modelValue.supportingDocuments) {
    props.modelValue.supportingDocuments = {};
  }

  props.modelValue.supportingDocuments[field] = files;
  emit('update:modelValue', props.modelValue);
}

// Initialize any missing document arrays
onMounted(() => {
  if (!props.modelValue.supportingDocuments) {
    props.modelValue.supportingDocuments = {};
  }

  const documentTypes = [
    'identificationDocuments',
    'addressProof',
    'employmentDocuments',
    'educationDocuments',
    'securityClearanceDocuments',
    'workPermit',
    'criminalRecordCheck',
    'additionalDocuments'
  ];

  documentTypes.forEach(type => {
    if (!props.modelValue.supportingDocuments[type]) {
      props.modelValue.supportingDocuments[type] = [];
    }
  });
});

// Watch for application type changes to adjust required document arrays
watch(() => props.modelValue.applicationType, (newType) => {
  if (newType === 'international') {
    if (!props.modelValue.supportingDocuments.workPermit) {
      props.modelValue.supportingDocuments.workPermit = [];
    }
    if (!props.modelValue.supportingDocuments.criminalRecordCheck) {
      props.modelValue.supportingDocuments.criminalRecordCheck = [];
    }
  }
});
</script>
