<template>
  <div class="flex flex-col md:flex-row h-full">
    <!-- Error Dialog -->
    <ErrorDialog v-if="errors.length > 0 || securityStore.errors.length > 0"
      :errors="[...errors, ...securityStore.errors]" :show="showErrorDialog" @close="showErrorDialog = false"
      @clear-errors="clearErrors" />

    <!-- Auto-recovery notification -->
    <Transition enter-active-class="transform ease-out duration-300 transition"
      enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-100"
      leave-from-class="opacity-100" leave-to-class="opacity-0">
      <div v-if="showRecoveryNotification" class="fixed top-4 left-1/2 transform -translate-x-1/2 z-50 max-w-sm w-full">
        <div class="bg-white rounded-lg shadow-lg border border-gray-200 p-4">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <InformationCircleIcon class="h-5 w-5 text-blue-400" />
            </div>
            <div class="ml-3 w-0 flex-1">
              <p class="text-sm font-medium text-gray-900">
                Previous progress restored
              </p>
              <p class="mt-1 text-sm text-gray-500">
                From {{ formatDate(savedFormDate) }}
              </p>
            </div>
            <div class="ml-4 flex-shrink-0 flex">
              <button @click="startFresh" class="mr-3 text-sm font-medium text-blue-600 hover:text-blue-500">
                Start Fresh
              </button>
              <button @click="hideRecoveryNotification" class="text-gray-400 hover:text-gray-500">
                <XMarkIcon class="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <!-- Sidebar Navigation - Hidden on mobile by default -->
    <div class="hidden md:block">
      <FormNavigation :sections="formSections" v-model:currentStep="currentStep"
        :hasError="(sectionId) => checkSectionHasErrors(sectionId)" />
    </div>

    <!-- if status is "submitted" -->
    <div v-if="securityStore.submissionStatus === 'submitted'"
      class="fixed inset-0 bg-white bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div class="bg-white rounded-lg shadow-xl p-6 max-w-md w-full">
        <h3 class="text-lg font-medium mb-4">Form Submitted</h3>
        <p class="text-gray-600 mb-4">
          Your security assessment has been submitted successfully.
        </p>
        <div class="flex space-x-4">
          <button @click="securityStore.editAssessment" class="px-4 py-2 border border-green-600 text-green-600 rounded hover:bg-green-50">
            Edit Assessment
          </button>
          <button @click="resetAndStartNew" 
            class="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700">
            Start New Assessment
          </button>
        </div>
      </div>
    </div>
    <!-- if status is "draft" -->

    <!-- Main Form Content -->
    <div class="flex-1 min-h-screen bg-gray-50" v-if="securityStore.submissionStatus === 'draft'">
      <!-- Sticky Header -->
      <FormHeader :sections="formSections" v-model:currentStep="currentStep" :scenarios="scenarios"
        @load-scenario="loadScenario" />

      <!-- Form Content -->
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6 md:py-8">

        <!-- Instructions Dialog -->
        <div v-if="showInstructions"
          class="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div class="bg-white rounded-lg shadow-xl max-w-4xl w-full max-h-[90vh] flex flex-col">
            <div class="p-4 border-b flex justify-between items-center">
              <h2 class="text-xl font-semibold">Application Instructions</h2>
              <button @click="showInstructions = false" class="text-gray-500 hover:text-gray-700">
                <span class="sr-only">Close</span>
                <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div class="p-4 overflow-y-auto flex-grow">
              <SecurityInstructionsCanadian v-if="formData.applicationType === 'canadian'" />
              <SecurityInstructionsInternational v-if="formData.applicationType === 'international'" />
              <div v-if="!formData.applicationType" class="text-center p-8">
                <p class="text-lg text-gray-600">Please select an application type to view instructions.</p>
              </div>
            </div>
            <div class="p-4 border-t bg-gray-50 flex justify-end">
              <button @click="showInstructions = false"
                class="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700">
                Close
              </button>
            </div>
          </div>
        </div>

        <div class="rounded-lg p-1">
          <!-- Action buttons - Better positioning and styling -->
          <div class="flex flex-col sm:flex-row justify-between items-center mb-6 gap-3">


            <button type="button" @click="showInstructions = true"
              class="w-full sm:w-auto flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50">
              <ExclamationTriangleIcon class="h-5 w-5 mr-2" />
              View Instructions
            </button>
          </div>

          <div @submit.prevent="handleSubmit">
            <!-- Application Type Section -->
            <ApplicationTypeSection v-if="currentStep === 0" v-model="formData" />

            <!-- Personal Information -->
            <PersonalInformationSection v-if="currentStep === 1" v-model="formData" :field-errors="fieldErrors" />

            <!-- Contact Information -->
            <ContactInformationSection v-if="currentStep === 2" v-model="formData" />

            <!-- Citizenship & Origin -->
            <CitizenshipSection v-if="currentStep === 3" v-model="formData" />

            <!-- Residential History -->
            <ResidentialHistorySection v-if="currentStep === 4" v-model="formData" />

            <!-- Employment History -->
            <EmploymentHistorySection v-if="currentStep === 5" v-model="formData" />

            <!-- Criminal History -->
            <CriminalHistorySection v-if="currentStep === 6" v-model="formData" />

            <!-- Travel History -->
            <TravelHistorySection v-if="currentStep === 7" v-model="formData" />

            <!-- References -->
            <ReferencesSection v-if="currentStep === 8" v-model="formData" />

            <!-- Security Clearance -->
            <SecurityClearanceSection v-if="currentStep === 9" v-model="formData" />

            <!-- Security Assessment Statements -->
            <SecurityStatementsSection v-if="currentStep === 10" v-model="formData" />

            <!-- Supporting Documents -->
            <SupportingDocumentsSection v-if="currentStep === 11" v-model="formData" />

            <!-- Form Review Section -->
            <FormReviewSection v-if="currentStep === 12" :formData="formData" />

            <!-- Navigation Buttons -->
            <div class="mt-6 flex flex-col sm:flex-row justify-between items-center gap-4 pt-5 border-t">
              <button type="button" @click="prevStep" v-if="currentStep > 0"
                class="w-full sm:w-auto inline-flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                <ChevronLeftIcon class="h-5 w-5 mr-2" />
                Previous
              </button>
              <button @click="securityStore.saveNow()" :disabled="securityStore.isSavingNow"
                class="w-full sm:w-auto flex items-center justify-center px-4 py-2 border border-blue-500 rounded-md shadow-sm text-sm font-medium text-blue-500 bg-white hover:bg-blue-50 disabled:opacity-50 disabled:cursor-not-allowed">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                  <path
                    d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
                </svg>
                {{ securityStore.isSavingNow ? 'Saving...' : 'Save & Edit Later' }}
              </button>
              <button type="button" @click="nextStep" v-if="currentStep < formSections.length - 1"
                class="w-full sm:w-auto inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                Next
                <ChevronRightIcon class="h-5 w-5 ml-2" />
              </button>
              <button @click="handleSubmit" v-if="currentStep === formSections.length - 1" :disabled="isSubmitting"
                class="w-full sm:w-auto inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                <span v-if="isSubmitting" class="flex items-center">
                  <svg class="animate-spin h-5 w-5 mr-2" viewBox="0 0 24 24">
                    <!-- Add loading spinner SVG -->
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                    </path>
                  </svg>
                  Submitting...
                </span>
                <span v-else>Submit Assessment</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Fixed bottom navigation for mobile -->
      <div
        class="md:hidden fixed bottom-0 left-0 right-0 bg-white border-t px-4 py-3 flex justify-between items-center shadow-lg">
        <button @click="prevStep" v-if="currentStep > 0"
          class="flex items-center justify-center px-3 py-1 text-sm text-gray-700">
          <ChevronLeftIcon class="h-5 w-5 mr-1" />
          Previous
        </button>
        <span v-else></span>

        <div class="text-xs text-gray-500">
          Step {{ currentStep + 1 }}/{{ formSections.length }}
        </div>

        <button @click="nextStep" v-if="currentStep < formSections.length - 1"
          class="flex items-center justify-center px-3 py-1 text-sm font-medium text-green-600">
          Next
          <ChevronRightIcon class="h-5 w-5 ml-1" />
        </button>
        <button @click="handleSubmit" v-if="currentStep === formSections.length - 1" :disabled="isSubmitting"
          class="flex items-center justify-center px-3 py-1 text-sm font-medium text-green-600">
          Submit
        </button>
      </div>
    </div>
  </div>

  <!-- Add submit confirmation dialog -->
  <div v-if="showSubmitConfirmation"
    class="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
    <div class="bg-white rounded-lg shadow-xl p-6 max-w-md w-full">
      <h3 class="text-lg font-medium mb-4">Confirm Submission</h3>
      <p class="text-gray-600 mb-4">
        Are you sure you want to submit your security assessment? This action cannot be undone.
      </p>
      <div class="flex justify-end space-x-4">
        <button @click="showSubmitConfirmation = false" class="px-4 py-2 text-gray-600 hover:text-gray-800">
          Cancel
        </button>
        <button @click="confirmSubmit" class="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700">
          Confirm Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, onUnmounted, computed } from "vue";
import ErrorDialog from './common/ErrorDialog.vue';
import { useSecurityAssessmentStore } from "@/stores/securityAssessment";
import { storeToRefs } from 'pinia';
import FormNavigation from './SecurityAssessment/FormNavigation.vue';
import FormHeader from './SecurityAssessment/FormHeader.vue';
import ApplicationTypeSection from './SecurityAssessment/FormSections/ApplicationTypeSection.vue';
import PersonalInformationSection from './SecurityAssessment/FormSections/PersonalInformationSection.vue';
import ContactInformationSection from './SecurityAssessment/FormSections/ContactInformationSection.vue';
import CriminalHistorySection from './SecurityAssessment/FormSections/CriminalHistorySection.vue';
import CitizenshipSection from './SecurityAssessment/FormSections/CitizenshipSection.vue';
import ResidentialHistorySection from './SecurityAssessment/FormSections/ResidentialHistorySection.vue';
import EmploymentHistorySection from './SecurityAssessment/FormSections/EmploymentHistorySection.vue';
import TravelHistorySection from './SecurityAssessment/FormSections/TravelHistorySection.vue';
import ReferencesSection from './SecurityAssessment/FormSections/ReferencesSection.vue';
import SecurityClearanceSection from './SecurityAssessment/FormSections/SecurityClearanceSection.vue';
import SecurityStatementsSection from './SecurityAssessment/FormSections/SecurityStatementsSection.vue';
import SupportingDocumentsSection from './SecurityAssessment/FormSections/SupportingDocumentsSection.vue';
import FormReviewSection from './SecurityAssessment/FormSections/FormReviewSection.vue';
import { useVuelidate } from '@vuelidate/core';
import { required, email, minLength, maxLength, helpers } from '@vuelidate/validators';
import { useInternalAuthStore } from "@/stores/internalAuth";
import { ExclamationTriangleIcon, XCircleIcon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/24/outline";
import SecurityInstructionsCanadian from "./SecurityInstructionsCanadian.vue";
import SecurityInstructionsInternational from "./SecurityInstructionsInternational.vue";
import scenarios from '@/data/security-assessment-scenarios.json';
import { 
  validateStep,
  stepValidation,
  validateFullForm
} from '@/utils/validation/sectionValidation';
import {
  personalInfoRules,
  contactRules,
  citizenshipRules,
  residentialRules,
  validateEmploymentHistory,
  employmentHistoryRules,
  referenceRules,
  securityClearanceRules,
  documentRules,
  validationMessages,
  validateDateRange
} from '@/utils/validation/formValidation';


import { InformationCircleIcon, XMarkIcon } from "@heroicons/vue/24/outline";

const securityStore = useSecurityAssessmentStore();
const authStore = useInternalAuthStore();

// Use storeToRefs for reactive store state
const { formData, fieldErrors, currentStep, isSubmitting, showRecoveryDialog, savedFormDate, showSubmitConfirmation } = storeToRefs(securityStore);

// Add ref for validation errors
const errors = ref([]);
const validationErrors = ref([]);

// Add these with other refs near the top of the script
const showErrorDialog = ref(false);

// Update form sections to use computed errors
const formSections = [
  { title: "Application Type", id: "applicationType" },
  { title: "Personal Information", id: "personal" },
  { title: "Contact Information", id: "contact" },
  { title: "Citizenship & Origin", id: "citizenship" },
  { title: "Residential History", id: "residentialHistory" }, // Changed from "residence"
  { title: "Employment History", id: "employmentHistory" }, // Changed from "employment"
  { title: "Criminal History", id: "criminal" },
  { title: "Travel History", id: "travel" },
  { title: "References", id: "references" },
  { title: "Security Clearance", id: "securityClearance" }, // Changed from "security"
  { title: "Security Assessment Statements", id: "statements" },
  { title: "Supporting Documents", id: "supportingDocuments" }, // Changed from "documents"
  { title: "Review & Submit", id: "review" },
];


// Combine all validation rules
// Combine all validation rules
const rules = {
  applicationType: { required },
  personal: personalInfoRules,
  contact: contactRules,
  citizenship: citizenshipRules,
  residentialHistory: {
    $each: residentialRules
  },
  employmentHistory: {
    $each: employmentHistoryRules
  },
  // Add Criminal History validation
  criminal: {
    hasConvictions: { required },
    convictions: helpers.withMessage(
      'Conviction details are required when you have convictions',
      (value, vm) => vm.hasConvictions !== 'yes' || (Array.isArray(value) && value.length > 0)
    )
  },
  // Add Travel History validation
  travel: {
    // Optional section, so basic validation is fine
    $each: {
      country: { required },
      fromDate: { required },
      toDate: { required },
      purpose: { required }
    }
  },
  references: {
    $each: referenceRules
  },
  securityClearance: securityClearanceRules,
  supportingDocuments: documentRules,
  statements: {
    $each: {
      details: {
        required: helpers.withMessage(
          'Details are required when statement is checked',
          (value, vm) => !vm.value || !!value
        )
      }
    }
  }
  // No validation needed for 'review' section as it's just a summary
};
const v$ = useVuelidate(rules, formData);

/**
 * Validates the form based on the current step
 */
 async function validateForm() {
  securityStore.clearErrors();
  
  // Use validateFullForm from sectionValidation.js
  const result = await validateFullForm(formData.value, v$.value);
  
  if (!result.isValid) {
    // Add all errors to the store
    if (result.errors && result.errors.length) {
      result.errors.forEach(error => {
        securityStore.addError(error);
      });
    }
    return false;
  }
  return true;
}

// setStatusToDraft function
function setStatusToDraft() {
  console.log('Setting status to draft');
  securityStore.securitySubmissionStatus.value = 'draft';
}


/**
 * Moves to the previous form step.
 */
function prevStep() {
  console.log('Moving to previous step');
  securityStore.prevStep();
}


/**
 * Submits the form.
 */
async function handleSubmit() {
  // Run all validations on submit
  if (!validateForm()) {
    return;
  }

  try {
    await securityStore.submitForm();
    alert("Form submitted successfully.");
  } catch (error) {
    console.error("Form submission failed:", error);
  }
}


/**
 * Recover saved form with explicit user action
 */
async function recoverSavedForm() {
  // First check if user is authenticated
  if (!authStore.isAuthenticated) {
    securityStore.addError("You must be logged in to recover saved drafts");
    return;
  }

  try {
    // Use the store's recoverSavedForm method directly
    await securityStore.recoverSavedForm();
  } catch (error) {
    console.error("Failed to load draft:", error);
  }
}

/**
 * Loads a scenario from the provided scenarios list with error handling
 */
async function loadScenario(event) {
  try {
    const index = parseInt(event.target.value);
    if (isNaN(index)) {
      console.warn('Invalid scenario index');
      return;
    }

    const scenario = scenarios.scenarios[index];
    if (!scenario?.data) {
      console.error('Invalid scenario data:', scenario);
      return;
    }

    // Reset form to default state first
    const defaultData = getDefaultFormData();

    // Deep merge scenario data with default data
    formData.value = {
      ...defaultData,
      ...JSON.parse(JSON.stringify(scenario.data))
    };

    // Reset to first step
    currentStep.value = 0;

    // Clear any existing errors
    securityStore.clearErrors();
  } catch (error) {
    console.error('Error loading scenario:', error);
    securityStore.addError('Failed to load scenario. Please try again.');
  }
}

// Add state for instructions dialog
const showInstructions = ref(false);



// Update nextStep function to properly handle validation
const nextStep = async () => {
  try {
    const sectionResults = await validateStep(currentStep.value, formData.value, v$);
    if (sectionResults.isValid) {
      console.log('Validation passed, moving to next step');
      securityStore.nextStep();
      if (authStore.isAuthenticated) {
        await securityStore.saveServerDraft();
      }
    } else {
      console.log('Validation failed', sectionResults.errors);

      // Clear previous errors
      securityStore.clearErrors();

      // Add new errors
      if (sectionResults.errors) {
        const errorArray = Array.isArray(sectionResults.errors) ? sectionResults.errors : [sectionResults.errors];
        errorArray.forEach(error => {
          securityStore.addError(error);
        });
      } else {
        securityStore.addError('Please fix validation errors before proceeding');
      }

      // Show error dialog
      errors.value = [...securityStore.errors];
      showErrorDialog.value = true;
    }
  } catch (error) {
    console.error('Validation error:', error);
    securityStore.addError('An error occurred during validation');
    errors.value = [...securityStore.errors];
    showErrorDialog.value = true;
  }
};

// Add new refs
const autoSaveTimeout = ref(null);
const formSummary = ref([]);

// Add new refs for recovery notification
const showRecoveryNotification = ref(false);
const recoveryNotificationTimer = ref(null);

// Update onMounted to auto-recover and show notification
onMounted(async () => {
  if (!authStore.isAuthenticated) {
    console.log('User not authenticated, skipping draft check');
    return;
  }

  const hasSavedForm = await securityStore.checkForSavedForm();
  if (hasSavedForm) {
    await recoverSavedForm();
    showRecoveryNotification.value = true;
    // Auto-hide notification after 2 minutes
    recoveryNotificationTimer.value = setTimeout(() => {
      hideRecoveryNotification();
    }, 120000);
  }
});

// Cleanup timer on component unmount
onUnmounted(() => {
  if (recoveryNotificationTimer.value) {
    clearTimeout(recoveryNotificationTimer.value);
  }
});

function hideRecoveryNotification() {
  showRecoveryNotification.value = false;
  if (recoveryNotificationTimer.value) {
    clearTimeout(recoveryNotificationTimer.value);
  }
}

// Update startFresh to also hide notification
function startFresh() {
  securityStore.startFresh();
  hideRecoveryNotification();
}

async function confirmSubmit() {
  // Ensure user is authenticated before submitting
  if (!authStore.isAuthenticated) {
    securityStore.addError("You must be logged in to submit the form");
    return;
  }

  showSubmitConfirmation.value = false;
  isSubmitting.value = true;
  try {
    await authStore.submitSecurityAssessment(formData.value);
    formData.value = getDefaultFormData();
    alert("Form submitted successfully.");
  } catch (error) {
    console.error("Form submission failed:", error);
    errors.value.push("An unexpected error occurred. Please try again later.");
  } finally {
    isSubmitting.value = false;
  }
}

function checkSectionHasErrors(sectionId) {
  
  
  // Special case for "review" section which doesn't need validation
  if (sectionId === 'review') {
    return false;
  }
  
  // Map section IDs to step numbers and validation functions
  const sectionToStepMap = {
    'applicationType': 0,
    'personal': 1,
    'contact': 2,
    'citizenship': 3,
    'residentialHistory': 4,
    'employmentHistory': 5,
    'criminal': 6,
    'travel': 7,
    'references': 8,
    'securityClearance': 9,
    'statements': 10,
    'supportingDocuments': 11
  };
  
  // Get step number for this section
  const stepNum = sectionToStepMap[sectionId];
  if (stepNum === undefined) {
   
    return false;
  }
  
  // Use the stepValidation from sectionValidation.js
  const validator = stepValidation[stepNum];
  if (!validator) {
    
    return false;
  }
  
  // Run the validation for this section
  const result = validator(formData.value);
  
  return result && !result.isValid;
}
function formatDate(date) {
  if (!date) return '';
  return new Intl.DateTimeFormat('en-US', {
    dateStyle: 'medium',
    timeStyle: 'short'
  }).format(date);
}

const clearErrors = () => {
  errors.value = [];
  securityStore.clearErrors();
};

// Add new method for resetting and starting new
async function resetAndStartNew() {
  try {
    await securityStore.updateStatus('draft');
    securityStore.resetForm();
  } catch (error) {
    console.error('Error resetting form:', error);
    securityStore.addError('Failed to reset form. Please try again.');
  }
}
</script>

<style>
/* Add some styles for the form summary sections */
.section-summary h4 {
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.section-summary .content {
  padding-left: 1rem;
  border-left: 2px solid #e2e8f0;
}

/* Add keyboard shortcut hints */
[data-tooltip] {
  position: relative;
}

[data-tooltip]:hover:after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px 8px;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
}

/* Add responsive padding for mobile */
@media (max-width: 768px) {
  .md\:hidden {
    display: block;
  }

  .md\:block {
    display: none;
  }

  /* Add bottom padding to account for fixed navigation */
  .min-h-screen {
    padding-bottom: 60px;
  }
}
</style>
