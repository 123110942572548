<template>
  <div class="px-2 sm:px-0">
    <h2 class="text-lg sm:text-xl font-bold mb-3 sm:mb-4">Application Type</h2>
    <div class="space-y-3 sm:space-y-4">
      <label class="block text-sm font-medium text-gray-700">
        Select your application type:
      </label>
      <select 
        v-model="modelValue.applicationType"
        class="mt-1 block w-full border rounded-md shadow-sm py-3 sm:py-2 px-3 text-base sm:text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500" 
        required
        @change="$emit('update:modelValue', modelValue)"
      >
        <option value="">Select type</option>
        <option value="canadian">Canadian Citizen/Permanent Resident</option>
        <option value="international">International Worker/Student</option>
      </select>
    </div>
    
    <!-- Instructions Preview -->
    <div v-if="modelValue.applicationType" class="mt-4 sm:mt-6 p-3 sm:p-4 bg-gray-50 rounded-lg">
      <h3 class="text-base sm:text-lg font-medium mb-2">Selected Application Type: {{ applicationTypeLabel }}</h3>
      <p class="text-sm sm:text-base text-gray-600">
        You've selected the {{ applicationTypeLabel.toLowerCase() }} application type. 
        Click "View Instructions" above to see detailed instructions for this application type.
      </p>
    </div>
    
    <!-- Document Requirements -->
    <div v-if="modelValue.applicationType === 'international'" class="mt-3 sm:mt-4">
      <h3 class="text-base sm:text-lg font-medium mb-2">Required Documents</h3>
      <ul class="list-disc list-inside space-y-1 sm:space-y-2 text-sm sm:text-base">
        <li>Valid passport signature pages</li>
        <li>Work/study permit</li>
        <li>International criminal record check</li>
        <li>Government-issued identity documents</li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  }
});

defineEmits(['update:modelValue']);

const applicationTypeLabel = computed(() => {
  if (props.modelValue.applicationType === 'canadian') {
    return 'Canadian Citizen/Permanent Resident';
  } else if (props.modelValue.applicationType === 'international') {
    return 'International Worker/Student';
  }
  return '';
});
</script>
