<template>
  <div>
    <h2 class="text-xl font-bold mb-4">Security Clearance</h2>
    <SectionInstructions sectionTitle="Security Clearance" sectionId="security" />

    <!-- Info box -->
    <div class="">
      <p class="text-sm mb-2 font-semibold text-gray-700">
        If you currently hold or have previously held a security clearance, please provide the details below.
        All fields are required if any security clearance information is provided.
      </p>
    </div>

    <div class="space-y-4">
      <!-- International Options -->
      <div v-if="modelValue.applicationType === 'international'" class="grid grid-cols-3 space-y-2  items-center">

        <div v-for="option in internationalOptions" :key="option.value" class="flex items-center">
          <input type="radio" :id="option.value" :value="option.value" v-model="modelValue.securityClearance.level"
            @change="handleLevelChange" class="form-radio h-4 w-4 text-blue-600" />
          <label :for="option.value" class="ml-2 text-sm font-medium text-gray-700">
            {{ option.label }}
          </label>
        </div>

        <div v-if="modelValue.securityClearance.level === 'other'" class="mt-2">
          <input v-model="modelValue.securityClearance.otherSpecification" type="text" placeholder="Please specify"
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            @change="$emit('update:modelValue', modelValue)" />
        </div>
      </div>

      <!-- Domestic Options -->
      <div v-else class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <div class="grid grid-cols-3 gap-4">
            <div v-for="option in internationalOptions" :key="option.value" class="flex items-center mr-4">
              <input type="checkbox" :id="option.value" :value="option.value"
                v-model="modelValue.securityClearance.selectedLevels" @change="handleLevelChange"
                class="form-checkbox h-4 w-4 text-blue-600" />
              <label :for="option.value" class="ml-2 text-sm font-medium text-gray-700">
                {{ option.label }}
              </label>
            </div>
          </div>
        </div>
      </div>

      <!-- Common Fields -->
      <div v-if="modelValue.securityClearance.level !== 'none'" class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label class="block text-sm font-medium text-gray-700">
            Issuing Country <span class="text-red-600">*</span>
          </label>
          <input v-model="modelValue.securityClearance.issuingCountry" type="text"
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            required @change="validateFields" />
        </div>

        <div>
          <label class="block text-sm font-medium text-gray-700">
            Issuing Department <span class="text-red-600">*</span>
          </label>
          <input v-model="modelValue.securityClearance.issuingDepartment" type="text"
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            required @change="validateFields" />
        </div>

        <div>
          <label class="block text-sm font-medium text-gray-700">
            Expiry Date <span class="text-red-600">*</span>
          </label>
          <input v-model="modelValue.securityClearance.expiryDate" type="date"
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            required @change="validateExpiryDate" />
        </div>
      </div>

      <!-- Validation Messages -->
      <div v-if="validationErrors.length > 0" class="mt-4 p-4 bg-red-50 border border-red-200 rounded">
        <h4 class="font-medium text-red-700 mb-2">Please correct the following issues:</h4>
        <ul class="list-disc pl-5 text-red-700 text-sm">
          <li v-for="(error, i) in validationErrors" :key="i">{{ error }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref } from 'vue';
import SectionInstructions from '../../SectionInstructions.vue';

const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  },
});

const emit = defineEmits(['update:modelValue']);
const validationErrors = ref([]);

const internationalOptions = [
  { value: 'none', label: 'None' },
  { value: 'confidential', label: 'Confidential' },
  { value: 'secret', label: 'Secret' },
  { value: 'top_secret', label: 'Top Secret' },
  { value: 'nato_secret', label: 'NATO Secret' },
  { value: 'nato_cosmic', label: 'NATO Cosmic' },
  { value: 'other', label: 'Other (Specify)' }
];

// Initialize isCanadian object if not exists
if (!props.modelValue.securityClearance.isCanadian) {
  props.modelValue.securityClearance.isCanadian = {};
}

// Ensure the selectedLevels array exists
if (!props.modelValue.securityClearance.selectedLevels) {
  props.modelValue.securityClearance.selectedLevels = [];
}

function handleLevelChange() {
  if (props.modelValue.applicationType === 'international') {
    // Handle radio button logic
    if (!props.modelValue.securityClearance.level) {
      clearSecurityFields();
    }
  } else {
    // Handle checkbox logic
    if (props.modelValue.securityClearance.selectedLevels.length === 0) {
      clearSecurityFields();
    } else {
      // Set level to the first selected value for compatibility
      props.modelValue.securityClearance.level = props.modelValue.securityClearance.selectedLevels[0];
    }
  }
  emit('update:modelValue', props.modelValue);
}

function clearSecurityFields() {
  props.modelValue.securityClearance.issuingCountry = '';
  props.modelValue.securityClearance.issuingDepartment = '';
  props.modelValue.securityClearance.expiryDate = '';
  props.modelValue.securityClearance.otherSpecification = '';
}

function validateFields() {
  validationErrors.value = [];
  const clearance = props.modelValue.securityClearance;

  if (clearance.level && !clearance.issuingCountry) {
    validationErrors.value.push('Issuing country is required');
  }
  if (clearance.level && !clearance.issuingDepartment) {
    validationErrors.value.push('Issuing department is required');
  }
  if (clearance.level && !clearance.expiryDate) {
    validationErrors.value.push('Expiry date is required');
  }

  emit('update:modelValue', props.modelValue);
}

function validateExpiryDate() {
  if (props.modelValue.securityClearance.expiryDate) {
    const expiryDate = new Date(props.modelValue.securityClearance.expiryDate);
    const today = new Date();

    if (expiryDate < today) {
      validationErrors.value.push('The security clearance appears to have expired. Please provide a current clearance.');
      props.modelValue.securityClearance.expiryDate = '';
    }
  }
  validateFields();
}
</script>
