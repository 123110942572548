import { helpers } from '@vuelidate/validators';

// Shared regex patterns
export const phoneRegex = /^\+?[\d\s-()]{10,}$/;
export const postalCodeRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

// Validation helpers
export const validateAtLeastOnePhone = (contact) => {
  return !!(contact.businessPhone || contact.homePhone || contact.mobilePhone);
};

export const dateNotInFuture = (value) => !value || new Date(value) <= new Date();

export const validateDates = (startDate, endDate, isPresent = false, errors = []) => {
  if (!startDate) return false;
  
  const start = new Date(startDate);
  const end = isPresent ? new Date() : new Date(endDate || new Date());
  
  if (end < start) {
    if (errors && Array.isArray(errors)) {
      errors.push('End date must be after start date');
    }
    return false;
  }
  
  if (start > new Date()) {
    if (errors && Array.isArray(errors)) {
      errors.push('Start date cannot be in the future');
    }
    return false;
  }
  
  return true;
};

export const validateFileUpload = (file, allowedExtensions, errors = []) => {
  if (!file) return false;

  const extension = '.' + file.name.split('.').pop().toLowerCase();
  if (!allowedExtensions.includes(extension)) {
    errors.push(`Invalid file type. Allowed types: ${allowedExtensions.join(', ')}`);
    return false;
  }

  const maxSize = 10 * 1024 * 1024; // 10MB
  if (file.size > maxSize) {
    errors.push('File size must be less than 10MB');
    return false;
  }

  return true;
};

/**
 * Calculate the timespan between two dates in years
 */
export const calculateTimespan = (fromDate, toDate) => {
  const start = new Date(fromDate);
  const end = new Date(toDate);
  return (end - start) / (1000 * 60 * 60 * 24 * 365); // Convert milliseconds to years
};

/**
 * Calculate employment gap in days between two employment records
 */
export const calculateEmploymentGap = (previousRecord, nextRecord) => {
  const prevEndDate = previousRecord.isPresent ? new Date() : new Date(previousRecord.toDate);
  const nextStartDate = new Date(nextRecord.fromDate);
  return (nextStartDate - prevEndDate) / (1000 * 60 * 60 * 24); // Convert milliseconds to days
};

/**
 * Sort history records by date (employment or residential)
 */
export const sortHistoryByDate = (records) => {
  return [...records].sort((a, b) => new Date(a.fromDate) - new Date(b.fromDate));
};

/**
 * Validate all fields in an address object
 */
export const validateAddressFields = (address) => {
  const required = ['streetNumber', 'streetName', 'city', 'provinceTerritory', 'country', 'postalCode'];
  return required.every(field => address[field]?.trim());
};