<template>
  <div class="invite-member-container max-w-2xl mx-auto p-6 bg-white rounded-md shadow-lg">
    <h2 class="text-2xl font-semibold mb-4">Invite a Team Member</h2>
    <form @submit.prevent="handleInviteMember" class="space-y-6">
      <div>
        <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
        <input
          v-model="email"
          id="email"
          type="email"
          required
          placeholder="Enter email"
          class="p-2 m-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>
      <div>
        <label for="firstName" class="block text-sm font-medium text-gray-700">First Name</label>
        <input
          v-model="firstName"
          id="firstName"
          type="text"
          required
          placeholder="Enter first name"
          class="p-2 m-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>
      <div>
        <label for="lastName" class="block text-sm font-medium text-gray-700">Last Name</label>
        <input
          v-model="lastName"
          id="lastName"
          type="text"
          required
          placeholder="Enter last name"
          class="p-2 m-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>
      <div>
        <label for="role" class="block text-sm font-medium text-gray-700">Role</label>
        <select
          v-model="role"
          id="role"
          required
          class="p-2 m-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        >
          <option value="" disabled>Select role</option>
          <option value="employee">Employee</option>
          <option value="candidate">Candidate</option>
        </select>
      </div>
      <div class="flex items-center justify-end">
        <button
          type="submit"
          :disabled="isLoading"
          class="inline-flex justify-center rounded-md bg-indigo-600 px-4 py-2 text-white font-medium shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
        >
          {{ isLoading ? 'Inviting...' : 'Invite Member' }}
        </button>
      </div>
    </form>
    <p v-if="error" class="mt-4 text-red-500">{{ error }}</p>
    <p v-if="success" class="mt-4 text-sm font-semibold leading-relaxed text-green-500">
      {{ success }}
    </p>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useInternalAuthStore } from "@/stores/internalAuth";

const authStore = useInternalAuthStore();
const email = ref("");
const firstName = ref("");
const lastName = ref("");
const role = ref("");
const error = ref("");
const success = ref("");
const isLoading = ref(false);

const handleInviteMember = async () => {
  if (!email.value || !firstName.value || !lastName.value || !role.value) {
    error.value = "Please fill in all fields";
    return;
  }

  isLoading.value = true;
  error.value = "";
  success.value = "";

  try {
    await authStore.inviteTeamMember(email.value, firstName.value, lastName.value, role.value);
    success.value = "Team member invited successfully!";
    email.value = "";
    firstName.value = "";
    lastName.value = "";
    role.value = "";
  } catch (err) {
    error.value = err.response?.data?.error || "Failed to invite team member";
  } finally {
    isLoading.value = false;
  }
};
</script>

<style scoped>
.invite-member-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
</style>
