<template>
  <Layout>
    <router-view v-if="initialized"></router-view>
    <div v-else class="flex items-center justify-center min-h-screen">
      <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
    </div>
  </Layout>
  <Toast />
</template>

<script setup>
import Layout from '@/components/Layout.vue';
import Toast from '@/components/common/Toast.vue';
import { onMounted, onUnmounted, ref } from 'vue';
import { useInternalAuthStore } from '@/stores/internalAuth';
import { useRouter } from 'vue-router';

const initialized = ref(false);
const authStore = useInternalAuthStore();
const router = useRouter();

onMounted(async () => {
  try {
    await authStore.init();
    initialized.value = true;

    // If not authenticated and not on public route, redirect to login
    if (!authStore.isAuthenticated && !router.currentRoute.value.meta.public) {
      router.push('/login');
    }
  } catch (error) {
    console.error('Failed to initialize auth:', error);
    router.push('/login');
  }
});

onUnmounted(() => {
  // Use the correct store instance
  authStore.cleanup();
});
</script>