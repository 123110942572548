<template>
  <div class="p-6">
    <!-- Instructions -->
    <p class="text-sm text-gray-600 mb-8 text-center">
      <span class="font-semibold uppercase">Directions:</span> In this section, you will find groups of four boxes. Each of those boxes is made up of a set of three words. Please read through all the boxes in a group before rating each box. Rating the boxes: Similar to the colored boxes you rated in the last section, put a <span class="font-semibold">"4"</span> in the box that most fully describes you, a <span class="font-semibold">"3"</span> in the box that is next closest to describing you, a <span class="font-semibold">"2"</span> in the box that is the third closest to describing you, and finally a <span class="font-semibold">"1"</span> in the box which describes you the least. Once you've chosen numbers for each row, add the numbers in each of the vertical columns and put the totals in the squares below.
      <!-- br -->
      <br />
      <span class="font-bold">  Note: Each row across should contain every number once. </span>
    </p>

    <!-- Word Square Cards -->
    <div v-for="(row, rowIndex) in wordSquare" :key="rowIndex" class="mb-6">
      <h2 class="text-lg font-semibold mb-4">Group {{ rowIndex + 1 }}</h2>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        <!-- Card for each group -->
        <div
          v-for="(group, groupIndex) in row.groups"
          :key="groupIndex"
          class="p-4 rounded-lg border"
        >
          <ul class="text-sm text-gray-600 mb-4">
            <li v-for="word in group.words" :key="word">{{ word }}</li>
          </ul>
          <!-- Input Ratings (1-4) -->
          <div class="flex items-center justify-center">
            <input
              type="number"
              v-model="group.rating"
              min="1"
              max="4"
              class="w-16 px-2 py-1 border rounded-lg text-center"
              placeholder="1-4"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Manual Totals -->
    <div class="mt-6">
      <h3 class="text-lg font-semibold mb-4">Column Totals</h3>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        <div
          v-for="(total, index) in manualTotals"
          :key="index"
          class="p-4 rounded-lg border"
        >
          <label :for="'total-' + index" class="block text-sm font-medium text-gray-700"
            >Column {{ index + 1 }}</label
          >
          <input
            type="number"
            v-model="manualTotals[index]"
            :id="'total-' + index"
            class="mt-1 block w-full rounded-md border px-2 py-1"
          />
        </div>
      </div>
    </div>

    <!-- Next Button -->
    <div class="flex justify-end mt-8">
      <button
        type="button"
        @click="validateAndSubmit"
        class="border px-4 py-2 rounded hover:bg-blue-600 bg-blue-500 text-white"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits } from "vue";
import { useInternalAuthStore } from "@/stores/internalAuth";

const authStore = useInternalAuthStore();
const emit = defineEmits(["valid-submit"]);

// Add manual totals
const manualTotals = ref([0, 0, 0, 0]);

// Word Square Data with word groups
const wordSquare = ref([
  {
    groups: [
      { words: ["Spontaneous", "Impulsive", "Impetuous"], rating: "" },
      { words: ["Stable", "Methodical", "Planner"], rating: "" },
      { words: ["Cooperative", "Idealistic", "Wants harmony"], rating: "" },
      { words: ["Rational", "Curious", "Complex"], rating: "" },
    ],
  },
  {
    groups: [
      { words: ["Adventurous", "Daring", "In a hurry"], rating: "" },
      { words: ["Traditional", "Responsible", "Dependable"], rating: "" },
      { words: ["Catalyst", "Compassionate", "Inspirational"], rating: "" },
      { words: ["Logical", "Analytical", "Loner"], rating: "" },
    ],
  },
  {
    groups: [
      { words: ["Love excitement", "Explorer", "Unpredictable"], rating: "" },
      { words: ["Dutiful", "Teacher", "Industrious"], rating: "" },
      { words: ["Authentic", "Empathic", "Motivator"], rating: "" },
      { words: ["Intellectual", "Inventive", "Problem solver"], rating: "" },
    ],
  },
  {
    groups: [
      { words: ["Energetic", "Expedient", "Jokester"], rating: "" },
      { words: ["Makes rules", "Orderly", "Prepares"], rating: "" },
      { words: ["Supportive", "Self-aware", "Caring"], rating: "" },
      { words: ["System-thinker", "Independent", "Perfectionist"], rating: "" },
    ],
  },
  {
    groups: [
      { words: ["Bold", "Witty", "Risk-taker"], rating: "" },
      { words: ["Loyal", "Reliable", "Likes structure"], rating: "" },
      { words: ["Romantic", "Flexible", "Self-actualizing"], rating: "" },
      { words: ["Theoretical", "Ingenious", "Individualist"], rating: "" },
    ],
  },
]);

const validateAndSubmit = () => {
  return new Promise((resolve, reject) => {
    let errors = [];
    
    // Initialize error tracking if not exists
    if (!authStore.kurtzResults?.errorTracking) {
      authStore.initializeErrorTracking();
    }

    // Validate each row
    wordSquare.value.forEach((row, rowIndex) => {
      const ratings = row.groups.map(g => Number(g.rating));
      
      // Check if all ratings are filled
      if (ratings.some(r => !r)) {
        errors.push(`Please rate all boxes in Group ${rowIndex + 1}`);
        return;
      }

      // Check if ratings are unique 1-4 in each row
      const validRatings = [1, 2, 3, 4];
      const hasAllRatings = validRatings.every(v => ratings.includes(v));
      if (!hasAllRatings) {
        errors.push(`Group ${rowIndex + 1} must contain exactly one of each rating (1-4)`);
      }
    });

    // Calculate column totals
    const calculatedTotals = [0, 0, 0, 0];
    wordSquare.value.forEach(row => {
      row.groups.forEach((group, colIndex) => {
        calculatedTotals[colIndex] += Number(group.rating);
      });
    });

    // Check for both missing and incorrect totals
    let hasMissingTotals = false;
    manualTotals.value.forEach((total, index) => {
      if (!total) {
        hasMissingTotals = true;
      } else if (Number(total) !== calculatedTotals[index]) {
        errors.push(`Column ${index + 1} total is incorrect`);
      }
    });

    // Add missing totals error if needed
    if (hasMissingTotals) {
      errors.push("Please fill in all column totals");
    }

    // Additional validations from directions
    const allRatings = wordSquare.value.flatMap(row => 
      row.groups.map(g => Number(g.rating))
    );

    // Check if any rating is outside 1-4 range
    if (allRatings.some(r => r < 1 || r > 4)) {
      errors.push("All ratings must be between 1 and 4");
    }

    if (errors.length > 0) {
      alert(errors.join('\n'));
      // Ensure error tracking exists and increment
      if (authStore.kurtzResults?.errorTracking) {
        if (typeof authStore.kurtzResults.errorTracking.wordSquare === 'number') {
          authStore.kurtzResults.errorTracking.wordSquare++;
        } else {
          authStore.kurtzResults.errorTracking.wordSquare = 1;
        }
        console.log('Word Square Errors:', authStore.kurtzResults.errorTracking.wordSquare);
      } else {
        console.error('Error tracking not properly initialized');
      }
      reject(new Error('Validation failed'));
      return;
    }

    submitResults();
    resolve();
  });
};

const submitResults = () => {
  authStore.kurtzResults.wordSquare = {
    value: wordSquare.value,
    totals: manualTotals.value.map(t => String(t)), // Convert to strings
  };
  emit("valid-submit");
};
</script>

<style scoped>
/* Add your styles here */
</style>
