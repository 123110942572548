<template>
  <div class="sticky top-0 z-10 bg-gray-100 border-y shadow-sm">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="py-3 sm:py-4">
        <div class="flex flex-wrap items-center justify-between gap-2">
          <h1 class="text-base sm:text-lg font-semibold text-gray-900">
            {{ sections[currentStep].title }}
          </h1>
          <div class="flex flex-wrap items-center gap-2 sm:gap-4">
            <!-- Add saving indicator -->
            <span v-if="isSaving" class="text-xs sm:text-sm text-gray-500 flex items-center">
              <svg class="animate-spin h-3 w-3 sm:h-4 sm:w-4 mr-1 sm:mr-2" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Saving...
            </span>
            <!-- Add completion percentage -->
            <span class="text-xs sm:text-sm text-gray-500">
              {{ completionPercentage }}% Complete
            </span>
           
            <span class="hidden sm:inline text-xs sm:text-sm text-gray-500">
              Step {{ currentStep + 1 }} of {{ sections.length }}
            </span>
            <div class="sm:hidden">
              <div class="relative">
                <select 
                  :value="currentStep"
                  @change="handleStepChange($event.target.value)"
                  class="block w-full pl-3 pr-8 py-1.5 text-xs border-gray-300 bg-white focus:outline-none focus:ring-green-500 focus:border-green-500 rounded-md appearance-none"
                >
                  <option v-for="(section, index) in sections" :key="index" :value="index">
                    {{ (index + 1) + '. ' + section.title }}
                  </option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg class="h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
                    <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Progress Bar -->
        <div class="mt-3 sm:mt-4 h-1.5 bg-gray-200 rounded-full overflow-hidden">
          <div 
            class="h-full bg-green-600 transition-all duration-300" 
            :style="{width: `${((currentStep + 1) / sections.length) * 100}%`}"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const emit = defineEmits(['update:currentStep', 'load-scenario']);

defineProps({
  sections: {
    type: Array,
    required: true
  },
  currentStep: {
    type: Number,
    required: true
  },
  scenarios: {
    type: Object,
    required: true
  },
  isSaving: {
    type: Boolean,
    default: false
  },
  completionPercentage: {
    type: Number,
    default: 0
  }
});

// Handle step changes
const handleStepChange = (step) => {
  const newStep = parseInt(step);
  if (!isNaN(newStep)) {
    emit('update:currentStep', newStep);
  }
};
</script>

<style scoped>
@media (max-width: 640px) {
  .gap-2 {
    gap: 0.375rem;
  }
}
</style>
