<template>
  <div class="border border-gray-200 rounded-lg shadow-sm overflow-hidden">
    <div 
      class="flex justify-between items-center p-4 bg-gray-50 cursor-pointer" 
      @click="$emit('toggle')"
    >
      <h4 class="font-medium text-gray-700">{{ title }}</h4>
      <i class="fas" :class="expanded ? 'fa-chevron-up' : 'fa-chevron-down'" aria-hidden="true"></i>
    </div>
    <div v-show="expanded" class="p-4 bg-white">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReviewSection',
  props: {
    title: String,
    expanded: Boolean
  },
  emits: ['toggle']
}
</script>