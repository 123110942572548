import { ref } from 'vue';

// Option 1: Using Mapbox (requires API key but has better rate limits)
const MAPBOX_TOKEN = import.meta.env.VITE_MAPBOX_TOKEN; // Add this to your .env file

export function useAddressSearch() {
  const searchResults = ref([]);
  const isLoading = ref(false);
  const error = ref(null);

  // Using Mapbox
  async function searchAddressMapbox(query) {
    if (!query || query.length < 3) {
      searchResults.value = [];
      return;
    }

    isLoading.value = true;
    error.value = null;

    try {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(query)}.json?access_token=${MAPBOX_TOKEN}&types=address`
      );
      const data = await response.json();
      
      searchResults.value = data.features.map(feature => ({
        fullAddress: feature.place_name,
        streetNumber: feature.address,
        streetName: feature.text,
        city: feature.context?.find(c => c.id.includes('place'))?.text,
        provinceTerritory: feature.context?.find(c => c.id.includes('region'))?.text,
        country: feature.context?.find(c => c.id.includes('country'))?.text,
        postalCode: feature.context?.find(c => c.id.includes('postcode'))?.text,
      }));
    } catch (err) {
      error.value = 'Failed to fetch address suggestions';
      searchResults.value = [];
    } finally {
      isLoading.value = false;
    }
  }

  // Option 2: Using OpenStreetMap Nominatim (free, but stricter rate limits)
  async function searchAddressOSM(query) {
    if (!query || query.length < 3) {
      searchResults.value = [];
      return;
    }

    isLoading.value = true;
    error.value = null;

    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(query)}&addressdetails=1`,
        {
          headers: {
            'User-Agent': 'HR Portal Address Search' // Required by Nominatim ToS
          }
        }
      );
      const data = await response.json();
      
      searchResults.value = data.map(result => ({
        fullAddress: result.display_name,
        streetNumber: result.address.house_number,
        streetName: result.address.road,
        city: result.address.city || result.address.town,
        provinceTerritory: result.address.state,
        country: result.address.country,
        postalCode: result.address.postcode,
      }));
    } catch (err) {
      error.value = 'Failed to fetch address suggestions';
      searchResults.value = [];
    } finally {
      isLoading.value = false;
    }
  }

  return {
    searchResults,
    isLoading,
    error,
    searchAddressMapbox,
    searchAddressOSM
  };
}
