import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';
import AcceptPolicy from '@/components/AcceptPolicy.vue';
import InviteTeamMember from '@/components/InviteTeamMember.vue';
import InviteCandidate from '@/components/InviteCandidate.vue';
import ForgotPassword from '@/components/ForgotPassword.vue';
import ResetPassword from '@/components/ResetPassword.vue';
import Kurtz from '@/views/Kurtz.vue';
import SubmissonKurtz from '@/components/Kurtz/Submission.vue';
import UserAcceptanceStatus from '@/components/UserAcceptanceStatus.vue';
import KurtzResults from '@/components/Admin/KurtzResults.vue';
import KurtzResultDetail from '@/components/Admin/KurtzResultDetail.vue'; // Import the new KurtzResultDetail component
import { useInternalAuthStore } from '@/stores/internalAuth'; // Change this import
import SecurityAssessmentForm from '@/components/SecurityAssessmentForm.vue';
import ActivateAccount from '@/views/ActivateAccount.vue'; // Add this import
import KurtzInvitationsAudit from '../components/Admin/KurtzInvitationsAudit.vue'; // Import the component directly instead of using dynamic import
import InviteConfirmation from '@/views/InviteConfirmation.vue'; // Import the new InviteConfirmation component
import VerifyMagicLink from '@/views/VerifyMagicLink.vue'; // Import the new VerifyMagicLink component
import { setupAuthGuard } from './auth-guard'; // Import the setupAuthGuard function
import SecurityAssessmentDashboard from '@/views/SecurityAssessmentDashboard.vue';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login.vue'),
        beforeEnter: (to, from, next) => {
            const authStore = useInternalAuthStore();
            if (authStore.isAuthenticated) {
                next('/dashboard');
            } else {
                next();
            }
        },
        meta: {
            title: 'Login',
            requiresAuth: false
        }
    },
    { path: '/accept-policy', name: 'Accept Policy', component: AcceptPolicy, meta: { title: 'Accept Policy', requiresAuth: true } },
   
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: () => import('@/components/ForgotPassword.vue'),
        meta: { requiresAuth: false }
    },
    {
        path: '/policy-acceptance-status', name: 'UserAcceptanceStatus', component: UserAcceptanceStatus,
    },
    {
        path: '/kurtz-results', name: 'KurtzResults', component: KurtzResults, meta: { title: 'Kurtz Results', requiresAuth: true }

    },
    { 
        path: '/kurtz-results/:id', 
        name: 'KurtzResultDetail', 
        component: KurtzResultDetail, 
        props: true, 
        meta: { 
            title: 'Kurtz Result Detail', 
            requiresAuth: true 
        } 
    },
    {
        path: '/kurtz/submission', name: 'SubmissonKurtz', component: SubmissonKurtz,
        meta: {
            title: 'Submission',
            requiresAuth: true
        }

    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: () => import('@/components/ResetPassword.vue'),
        meta: { 
            requiresAuth: false,
            isPublic: true // Add this to mark it as public
        }
    },
    {
        path: '/kurtz', name: 'Kurtz', component: Kurtz, meta: { title: 'Kurtz', requiresAuth: true },
    },
    {
        path: '/invite-team-member',
        name: 'InviteTeamMember',
        component: InviteTeamMember,
        meta: {
            title: 'Invite Team Member',
            requiresAuth: true
        }
    },
    {
        path: '/invite-candidate',
        name: 'InviteCandidate',
        component: InviteCandidate,
        meta: {
            title: 'Invite Candidate',
            requiresAuth: true
        }
    },
    {
        path: '/recent-hires',
        name: 'RecentHires',
        component: () => import('@/views/RecentHiresView.vue'),
        meta: { requiresAuth: true, requiresAdmin: true }
    },
    {
        path: '/kurtz/audit',
        name: 'KurtzAudit',
        component: KurtzInvitationsAudit,
        meta: { requiresAuth: true, requiresAdmin: true }
    },
    {
        path: '/security-assessment',
        name: 'SecurityAssessment',
        component: SecurityAssessmentForm,
        meta: {
            requiresAuth: true,
            title: 'Security Assessment Form'
        }
    },
    {
        path: '/activate-account',
        name: 'ActivateAccount',
        component: ActivateAccount,
        meta: {
            title: 'Activate Account',
            requiresAuth: false,
            isPublic: true // Add isPublic flag
        }
    },
    {
        path: '/confirmation/invite',
        name: 'InviteConfirmation',
        component: InviteConfirmation,
        meta: {
            title: 'Invite Confirmation',
            requiresAuth: false,
            isPublic: true  // Add this flag
        }
    },
    {
        path: '/verify-magic-link',
        name: 'VerifyMagicLink',
        component: VerifyMagicLink,
        meta: {
            requiresAuth: false,
            isPublic: true // Add this flag
        }
    },
    {
        path: '/auth/verify-magic-link',  // Update this path to match the frontend URL
        name: 'VerifyMagicLink',
        component: VerifyMagicLink,
        meta: {
            requiresAuth: false,
            isPublic: true
        }
    },
    {
        path: '/admin/security-assessments',
        name: 'SecurityAssessmentDashboard',
        component: SecurityAssessmentDashboard,
        meta: { 
            requiresAuth: true,
            requiresAdmin: true 
        }
    },
    {
        path: '/admin/security-assessment/view/:id',
        name: 'SecurityAssessmentView',
        component: () => import('@/views/SecurityAssessmentView.vue'),
        meta: { 
            requiresAuth: true,
            requiresAdmin: true 
        },
        props: true // Enable props for the route params
    },

];

// Define public routes
const publicRoutes = [
    '/login',
    '/register',
    '/forgot-password',
    '/reset-password',
    '/verify-email',
    '/activate', 
    '/confirmation/invite',
    '/confirmation',
    '/auth/verify-magic-link'
];

// Update auth guard to handle session checks properly
const authGuard = async (to, from, next) => {
    const authStore = useInternalAuthStore();

    // Always allow public routes
    if (publicRoutes.includes(to.path) || to.meta.isPublic) {
        return next();
    }

    try {
        // Initialize auth store if needed
        if (!authStore.isAuthenticated) {
            const isValid = await authStore.init();
            if (!isValid) {
                authStore.redirectPath = to.fullPath;
                return next('/login');
            }
        }

        // Check admin requirement
        if (to.meta.requiresAdmin && !authStore.isAdminUser) {
            return next('/');
        }

        return next();
    } catch (error) {
        console.error('Auth guard error:', error);
        authStore.redirectPath = to.fullPath;
        return next('/login');
    }
};

const router = createRouter({
    history: createWebHistory(),
    routes,
});

// Add global navigation guard before the router creation
router.beforeEach((to, from, next) => {
    const authStore = useInternalAuthStore();
    
    // Redirect authenticated users trying to access auth pages
    if (authStore.isAuthenticated && 
        ['/login', '/reset-password', '/verify-email'].includes(to.path)) {
      next('/dashboard');
      return;
    }
  
    next();
  });

// Apply the auth guard
router.beforeEach(authGuard);

// Setup authentication guard
setupAuthGuard(router);

export default router;