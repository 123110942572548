import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from '@/router/'
import './style.css'
import * as Sentry from "@sentry/vue";
//in your `main.js` file
import '../node_modules/flowbite-vue/dist/index.css'

const app = createApp(App)
const pinia = createPinia()
app.use(pinia)

// Sentry.init({
//     app,
//     dsn: "https://7c6bef8d5b51ac13b217a9974deb0e57@o4506678483484672.ingest.us.sentry.io/4508417399783424",
//     integrations: [
//         Sentry.browserTracingIntegration({ router }),
//         Sentry.replayIntegration(),
//     ],
//     // Tracing
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     maskAllText: false, // Set to true to mask all text nodes in the transaction
//     // Set 'tracePropagationTargets' to control for hr.protocase.com 
//     tracePropagationTargets: ['hr.protocase.com'],
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

//     // Set release version


// });

// Mount app immediately without waiting for auth
app.use(router)
app.mount('#app')