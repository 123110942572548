<template>
  <div class="max-w-2xl mx-auto p-6 bg-white rounded-md shadow-lg">
    <h2 class="text-2xl font-semibold mb-4">Invite a Candidate</h2>
    <form @submit.prevent="handleInviteCandidate" class="space-y-6">
      <div>
        <label for="email" class="block m-2 text-sm font-medium text-gray-700">Email</label>
        <input
          v-model="email"
          id="email"
          type="email"
          required
          placeholder="Enter email"
          class="p-2 m-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>
      <div>
        <label for="firstName" class="block m-2 text-sm font-medium text-gray-700">First Name</label>
        <input
          v-model="firstName"
          id="firstName"
          type="text"
          required
          placeholder="Enter first name"
          class="p-2 m-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>
      <div>
        <label for="lastName" class="block m-2 text-sm font-medium text-gray-700">Last Name</label>
        <input
          v-model="lastName"
          id="lastName"
          type="text"
          required
          placeholder="Enter last name"
          class="p-2 m-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>

      <div class="flex items-center justify-end">
        <button
          type="submit"
          :disabled="authStore.isLoading"
          class="inline-flex justify-center rounded-md bg-indigo-600 px-4 py-2 text-white font-medium shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          {{ authStore.isLoading ? 'Inviting...' : 'Invite Candidate' }}
        </button>
      </div>
    </form>
    <p v-if="authStore.error" class="mt-4 text-red-500">{{ authStore.error }}</p>
    <p v-if="authStore.success" class="mt-4 text-sm font-semibold leading-relaxed text-green-500">
      {{ authStore.success }}
    </p>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useInternalAuthStore } from "@/stores/internalAuth";

const authStore = useInternalAuthStore();

const email = ref("");
const firstName = ref("");
const lastName = ref("");

const handleInviteCandidate = async () => {
  try {
    await authStore.inviteTeamMember(
      email.value,
      firstName.value,
      lastName.value,
      'candidate' // Hardcoded role as candidate
    );
    
    // Clear form if successful
    if (!authStore.error) {
      email.value = "";
      firstName.value = "";
      lastName.value = "";
    }
  } catch (error) {
    console.error('Failed to invite candidate:', error);
  }
};
</script>
