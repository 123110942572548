<template>
  <div v-if="isLoading">
    <Spinner />
    <!-- Add a loading spinner during async operations -->
  </div>

  <div v-else>
    <div
      v-if="isAccepted"
      class="mt-32 max-w-6xl mx-auto p-6 bg-white rounded-md shadow-lg"
    >
      <h2>You've already accepted the policy</h2>
      <div class="flex gap-4">
       
        <button
          @click="downloadPolicyAsPdf"
          class="mt-4 text-green-600 hover:text-green-800 font-medium"
        >
          Download Policy PDF
        </button>
      </div>
    </div>

    <div v-else>
      <div
        id="policy-content"
        class="mt-32 max-w-6xl mx-auto p-6 bg-white rounded-md shadow-lg"
      >
        <h2 class="text-2xl font-semibold mb-4">Employee Handbook</h2>

        <div class="m-10 text-green-400" v-if="showPrompt">
          <p class="text-sm">
            Please scroll to the bottom of the policy to accept the terms.
          </p>
          <p class="text-sm">
            By accepting the policy, you agree to the terms and conditions outlined above.
          </p>
        </div>

        <div v-if="showPrompt" class="p-4 border border-gray-300 rounded-md">
          <div v-html="policiesContent"></div>
        </div>
        <div v-if="showPrompt" class="mt-4">
          <h2 class="text-xl font-semibold mt-4">Acknowledgment to Employee Handbook</h2>
          <p class="mt-2 text-sm">
            I, <span class="font-bold">{{ name }}</span
            >, acknowledge that on <span class="font-bold">{{ currentDate }}</span
            >, I received a copy of the Protocase, USA Inc.’s (“the Company”) Employee
            Handbook (“Handbook”) and that I read it, understood it, and agree to comply
            with it. I understand that the Company has the maximum discretion permitted by
            law to interpret, administer, change, modify, or delete the rules,
            regulations, procedures, and benefits contained in the Handbook at any time
            with or without notice. No statement or representation by a supervisor or
            manager or any other employee, whether oral or written, can supplement or
            modify this Handbook. Changes can only be made if approved in writing by NAME.
            I also understand that any delay or failure by the Company to enforce any
            rule, regulation, or procedure contained in the Handbook will not constitute a
            waiver of the Company’s right to do so in the future.
          </p>
          <p class="mt-2 text-sm">
            I understand that neither this Handbook nor any other communication by a
            management representative or any other employee, whether oral or written, is
            intended in any way to create a contract of employment. I understand that,
            unless I have a written employment agreement signed by an authorized Company
            representative, I am employed at-will and this Handbook does not modify my
            at-will employment status. If I have a written employment agreement signed by
            an authorized Company representative and this Handbook conflicts with the
            terms of my employment agreement, I understand that the terms of my employment
            agreement will govern.
          </p>
        </div>
      </div>
    </div>
    <button
      @click="acceptPolicy"
      v-if="showPrompt"
      class="inline-flex justify-center rounded-md bg-blue-600 px-4 py-2 text-white font-medium shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 mt-4"
    >
      Accept Policy
    </button>
  </div>

  <!-- Success modal -->
  <div
    v-if="showSuccessModal"
    class="fixed z-50 inset-0 flex items-center justify-center bg-black bg-opacity-50"
  >
    <div class="bg-white p-6 rounded-lg shadow-lg text-center">
      <h2 class="text-2xl font-semibold text-green-500">Success!</h2>
      <p class="mt-4">You've signed the Policy Package!</p>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, nextTick, watch } from "vue";
import { useInternalAuthStore } from "@/stores/internalAuth";
import html2pdf from "html2pdf.js";
import Spinner from "@/components/Spinner.vue";
import { useRouter } from "vue-router";
import { marked } from "marked";

const router = useRouter();
const authStore = useInternalAuthStore();
const showPrompt = ref(false);
const name = ref(""); // Change to ref
const userId = ref(""); // Change to ref
const isLoading = ref(false);
const currentDate = new Date().toLocaleDateString();
const policiesContent = ref("");
const isAccepted = ref(false);
const showSuccessModal = ref(false);
const pdfLink = ref("");

// Enhanced function to convert DokuWiki syntax to HTML with TailwindCSS classes
const convertDokuWikiToHTMLWithTailwind = (text) => {
  // Convert headers with TailwindCSS classes
  text = text.replace(
    /^======\s*(.+?)\s*======$/gm,
    '<h1 class="text-3xl font-bold mb-4">$1</h1>'
  );
  text = text.replace(
    /^=====\s*(.+?)\s*=====$/gm,
    '<h2 class="text-2xl font-semibold text-gray-700 mb-3">$1</h2>'
  );
  text = text.replace(
    /^====\s*(.+?)\s*====$/gm,
    '<h3 class="text-xl font-semibold text-gray-700 mb-2">$1</h3>'
  );
  text = text.replace(
    /^===\s*(.+?)\s*===$/gm,
    '<h4 class="text-lg font-semibold text-gray-700 mb-1">$1</h4>'
  );
  text = text.replace(
    /^==\s*(.+?)\s*==$/gm,
    '<h5 class="text-base font-semibold text-gray-700">$1</h5>'
  );

  // Convert unordered lists with TailwindCSS classes
  text = text.replace(/^\s*[*-]\s+(.+)$/gm, '<li class="text-gray-600">$1</li>'); // List item
  text = text.replace(
    /(<li.+<\/li>)/g,
    '<ul class="list-disc list-inside space-y-2 ml-6">$1</ul>'
  ); // Wrap list items in <ul>

  // Convert bold and italic syntax
  text = text.replace(/\*\*(.+?)\*\*/g, "<strong>$1</strong>"); // Bold
  text = text.replace(/\/\/(.+?)\/\//g, "<em>$1</em>"); // Italics

  // Wrap paragraphs with TailwindCSS spacing
  text = text.replace(/\n{2,}/g, '</p><p class="text-gray-600 mt-4">'); // Separate paragraphs
  text = `<p class="text-gray-600 mt-4">${text}</p>`; // Wrap all content

  return text;
};

// Load and display policies
const loadPolicies = async () => {
  try {
    const response = await authStore.fetchPolicies();
    if (response.ok) {
      policiesContent.value = convertDokuWikiToHTMLWithTailwind(response.content);
    } else {
      throw new Error(response.error || "Failed to load policies");
    }
  } catch (error) {
    console.error("Error loading policies:", error);
    authStore.error = "Failed to load policies. Please try again later.";
  }
};

// Function to generate PDF from policy data
const generatePDF = async (policyData) => {
  const element = document.querySelector("#policy-content");
  const opt = {
    margin: 1,
    filename: `${name.value}_policy_${new Date().toISOString().split("T")[0]}.pdf`,
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
  };

  try {
    const pdf = await html2pdf().from(element).set(opt).output("blob");
    return new Blob([pdf], { type: "application/pdf" });
  } catch (error) {
    console.error("Failed to generate PDF:", error);
    throw error;
  }
};

// Updated acceptPolicy function
const acceptPolicy = async () => {
  isLoading.value = true;
  try {
    // Simplify the policy data object
    const policyData = {
      content: document.querySelector("#policy-content").innerHTML,
      userName: name.value,
      userId: userId.value,
    };

    // Submit acceptance
    await authStore.submitPolicyAcceptance(policyData);

    // Update UI state
    showPrompt.value = false;
    isAccepted.value = true;
    showSuccessModal.value = true;

    setTimeout(() => {
      showSuccessModal.value = false;
      router.push("/");
    }, 3000);
  } catch (error) {
    console.error("Failed to accept policy:", error);
    authStore.error = "Failed to accept policy.";
  } finally {
    isLoading.value = false;
  }
};

// Updated checkPolicyStatus function
const checkPolicyStatus = async () => {
  try {
    const response = await authStore.getPolicyStatus();
    if (response.status === "ok") {
      isAccepted.value = response.accepted;
      if (response.accepted && response.policyData) {
        showPrompt.value = false;
      } else {
        showPrompt.value = true;
      }
    }
  } catch (error) {
    console.error("Failed to check policy status:", error);
    authStore.error = "Failed to check policy status";
  }
};

// Add this method
const viewPolicyPdf = async () => {
  try {
    isLoading.value = true;
    await authStore.downloadPolicyPdf();
  } catch (error) {
    console.error("Failed to view policy PDF:", error);
  } finally {
    isLoading.value = false;
  }
};

// Update downloadPolicyAsPdf function
const downloadPolicyAsPdf = async () => {
  isLoading.value = true;
  try {
    if (isAccepted.value) {
      // If policy is already accepted, get content from backend
      const policyData = await authStore.getPolicyStatus();
      if (!policyData.policyData) {
        throw new Error('No policy data found');
      }

      // Create temporary element with policy content
      const tempElement = document.createElement('div');
      tempElement.innerHTML = policyData.policyData.content;
      document.body.appendChild(tempElement);

      const opt = {
        margin: 1,
        filename: `policy_${name.value}_${new Date().toISOString().split('T')[0]}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
      };

      await html2pdf().from(tempElement).set(opt).save();
      document.body.removeChild(tempElement);
    } else {
      // If viewing current policy, use visible element
      const element = document.querySelector('#policy-content');
      if (!element) {
        throw new Error('Policy content element not found');
      }

      const opt = {
        margin: 1,
        filename: `policy_${name.value}_${new Date().toISOString().split('T')[0]}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
      };

      await html2pdf().from(element).set(opt).save();
    }
  } catch (error) {
    console.error('Failed to download PDF:', error);
    authStore.error = "Failed to download policy PDF";
  } finally {
    isLoading.value = false;
  }
};

// Update name when auth store changes
const updateUserInfo = () => {
  if (authStore.user?.firstName && authStore.user?.lastName) {
    name.value = `${authStore.user.firstName} ${authStore.user.lastName}`;
    userId.value = authStore.user.id;
  }
};

// Check if the user has accepted the policy when component mounts
onMounted(async () => {
  await authStore.init();
  updateUserInfo(); // Add this line
  await loadPolicies();
  await checkPolicyStatus();
});

// Watch for auth store changes
watch(
  () => authStore.user,
  (newUser) => {
    if (newUser) {
      updateUserInfo();
    }
  },
  { immediate: true }
);
</script>
