<template>
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
    <!-- Header -->
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-2xl font-semibold text-gray-900">Security Assessment Submissions</h1>
        <p class="mt-2 text-sm text-gray-700">
          A list of all security assessments submitted by users.
        </p>
      </div>
    </div>

    <!-- Filters -->
    <div class="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
      <!-- Status Filter -->
      <div>
        <label class="block text-sm font-medium text-gray-700">Status</label>
        <select v-model="filters.status"
          class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500">
          <option value="">All Statuses</option>
          <option value="submitted">Submitted</option>
          <option value="reviewed">Reviewed</option>
          <option value="draft">Draft</option>
        </select>
      </div>

      <!-- Documents Filter (new) -->
      <div>
        <label class="block text-sm font-medium text-gray-700">Documents</label>
        <select v-model="filters.hasDocuments"
          class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500">
          <option value="">All Submissions</option>
          <option value="true">Has Documents</option>
          <option value="false">No Documents</option>
        </select>
      </div>

      <!-- Document Category Filter (new) -->
      <div>
        <label class="block text-sm font-medium text-gray-700">Document Type</label>
        <select v-model="filters.documentType"
          class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500">
          <option value="">All Types</option>
          <option value="identification">Identification</option>
          <option value="addressProof">Address Proof</option>
          <option value="employmentDocuments">Employment</option>
          <option value="educationDocuments">Education</option>
          <option value="securityClearanceDocuments">Security Clearance</option>
          <option value="criminalRecordCheck">Criminal Record Check</option>
          <option value="workPermit">Work Permit</option>
        </select>
      </div>

      <!-- Search -->
      <div>
        <label class="block text-sm font-medium text-gray-700">Search</label>
        <input v-model="filters.search" type="text" placeholder="Search by name or email"
          class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500" />
      </div>
    </div>

    <!-- Table -->
    <div class="mt-8 flex flex-col">
      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle">
          <div class="overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="relative w-12 px-6 sm:w-16 sm:px-8">
                    <input type="checkbox" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300"
                      v-model="selectAll" @change="toggleSelectAll" />
                  </th>
                  <th v-for="column in columns" :key="column.key" scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                    @click="sortBy(column.key)">
                    <div class="flex items-center">
                      {{ column.label }}
                      <span v-if="sortColumn === column.key" class="ml-2">
                        {{ sortDirection === 'asc' ? '↑' : '↓' }}
                      </span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-if="isLoading" class="animate-pulse">
                  <td colspan="6" class="p-4">
                    <div class="h-4 bg-gray-200 rounded w-3/4"></div>
                  </td>
                </tr>
                <tr v-else-if="error" class="bg-red-50">
                  <td colspan="6" class="p-4 text-sm text-red-500">
                    {{ error }}
                  </td>
                </tr>
                <tr v-else-if="!submissions.length" class="text-center">
                  <td colspan="6" class="p-4 text-sm text-gray-500">
                    No submissions found
                  </td>
                </tr>
                <tr v-for="submission in paginatedSubmissions" :key="submission.id" class="hover:bg-gray-50">
                  <td class="relative w-12 px-6 sm:w-16 sm:px-8">
                    <input type="checkbox" class="h-4 w-4 rounded border-gray-300"
                      :checked="selectedSubmissions.includes(submission.id)" @change="toggleSelection(submission.id)" />
                  </td>
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm">
                    <div class="font-medium text-gray-900">
                      {{ submission.user.firstName }} {{ submission.user.lastName }}
                    </div>
                    <div class="text-gray-500">{{ submission.user.email }}</div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm">
                    <span :class="[
                      'inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium',
                      submission.status === 'submitted' ? 'bg-green-100 text-green-800' :
                        submission.status === 'reviewed' ? 'bg-blue-100 text-blue-800' : 'bg-yellow-100 text-yellow-800'
                    ]">
                      {{ submission.status }}
                    </span>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ formatDate(submission.submittedAt) }}
                  </td>
                  <!-- Documents column (clickable button) -->
                  <td class="px-3 py-4 text-sm text-gray-500">
                    <div v-if="getFilteredDocuments(submission).length > 0" class="relative">
                      <button @click="openDocumentsModal(submission)"
                        class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                        <span class="mr-1">{{ getFilteredDocuments(submission).length }}</span>
                        <span>Documents</span>
                      </button>
                    </div>
                    <span v-else class="text-gray-400 italic text-xs">No documents</span>
                  </td>
                  <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6">
                    <button @click="downloadPdf(submission.id)" :disabled="isPdfLoading[submission.id]"
                      class="text-green-600 hover:text-green-900 disabled:opacity-50">
                      {{ isPdfLoading[submission.id] ? 'Generating...' : 'Download as PDF' }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- Pagination -->
    <div class="mt-4 flex items-center justify-between">
      <div class="flex items-center">
        <select v-model="perPage"
          class="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500">
          <option :value="10">10 per page</option>
          <option :value="25">25 per page</option>
          <option :value="50">50 per page</option>
        </select>
      </div>
      <nav class="flex items-center space-x-2">
        <button :disabled="currentPage === 1" @click="currentPage--"
          class="rounded-md border border-gray-300 px-3 py-2 text-sm font-medium"
          :class="currentPage === 1 ? 'text-gray-400' : 'text-gray-700 hover:bg-gray-50'">
          Previous
        </button>
        <span class="text-sm text-gray-700">
          Page {{ currentPage }} of {{ totalPages }}
        </span>
        <button :disabled="currentPage === totalPages" @click="currentPage++"
          class="rounded-md border border-gray-300 px-3 py-2 text-sm font-medium"
          :class="currentPage === totalPages ? 'text-gray-400' : 'text-gray-700 hover:bg-gray-50'">
          Next
        </button>
      </nav>
    </div>

    <!-- Document Viewer Dialog -->
    <div v-if="showDocumentDialog" class="fixed inset-0 z-10 overflow-y-auto" aria-labelledby="document-viewer-title"
      role="dialog" aria-modal="true">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"
          @click="closeDocumentDialog"></div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <div
          class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
          <div class="sm:flex sm:items-start">
            <div class="mt-3 text-center sm:mt-0 sm:text-left w-full">
              <div class="flex justify-between items-center mb-4">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="document-viewer-title">
                  {{ currentDocument?.name || 'Document Viewer' }}
                </h3>
                <button @click="closeDocumentDialog" class="text-gray-400 hover:text-gray-500">
                  <span class="sr-only">Close</span>
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              <div class="mt-2 bg-gray-50 p-4 rounded-lg">
                <div v-if="currentDocument" class="w-full min-h-[500px] flex flex-col items-center justify-center">
                  <!-- Show loading state while document is being retrieved -->
                  <div v-if="isDocumentLoading" class="flex flex-col items-center justify-center">
                    <svg class="animate-spin h-10 w-10 text-blue-500 mb-4" xmlns="http://www.w3.org/2000/svg"
                      fill="none" viewBox="0 0 24 24">
                      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                      <path class="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                      </path>
                    </svg>
                    <p class="text-gray-600">Loading document...</p>
                  </div>

                  <!-- Document preview -->
                  <div v-else-if="documentUrl" class="w-full max-h-[500px] overflow-auto">
                    <div v-if="isImage(currentDocument.name)" class="flex justify-center">
                      <img :src="documentUrl" class="max-w-full h-auto" :alt="currentDocument.name" />
                    </div>
                    <div v-else-if="isPdf(currentDocument.name)" class="w-full h-[500px]">
                      <iframe :src="documentUrl" class="w-full h-full border-0" frameborder="0"></iframe>
                    </div>
                    <div v-else class="text-center py-10">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-gray-400 mx-auto mb-4" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                      </svg>
                      <p class="text-gray-600">This file type cannot be previewed</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  @click="securityStore.getDocument(currentSubmissionId, currentDocument.id, currentDocument.name)"
                  class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm">
                  Download
                </button>
                <button @click="closeDocumentDialog"
                  class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:w-auto sm:text-sm">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Documents List Modal -->
    <div v-if="showDocumentsListModal" class="fixed inset-0 z-10 overflow-y-auto" aria-labelledby="documents-list-title"
      role="dialog" aria-modal="true">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"
          @click="closeDocumentsListModal"></div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <div
          class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
          <div class="absolute top-0 right-0 pt-4 pr-4">
            <button @click="closeDocumentsListModal"
              class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              <span class="sr-only">Close</span>
              <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <div class="sm:flex sm:items-start">
            <div class="mt-3 text-center sm:mt-0 sm:text-left w-full">
              <h3 class="text-lg leading-6 font-medium text-gray-900" id="documents-list-title">
                Documents for {{ currentSubmission?.user?.firstName }} {{ currentSubmission?.user?.lastName }}
              </h3>

              <div class="mt-4">
                <div v-for="(docGroup, category) in groupDocumentsByCategory(currentSubmission)" :key="category"
                  class="mb-4 border border-gray-200 rounded-lg overflow-hidden">
                  <div class="bg-gray-50 px-4 py-2 border-b border-gray-200">
                    <h4 class="text-sm font-medium text-gray-900">{{ formatCategory(category) }} <span
                        class="text-gray-500 text-xs">({{ docGroup.length }})</span></h4>
                  </div>

                  <ul class="divide-y divide-gray-200">
                    <li v-for="(document, index) in docGroup" :key="index" class="px-4 py-3 hover:bg-gray-50">
                      <div class="flex items-center justify-between">
                        <div class="flex items-center flex-1 min-w-0">
                          <div class="flex-shrink-0 mr-3">
                            <svg v-if="isImage(document.name)" class="h-5 w-5 text-indigo-500"
                              xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                              <path fill-rule="evenodd"
                                d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                clip-rule="evenodd" />
                            </svg>
                            <svg v-else-if="isPdf(document.name)" class="h-5 w-5 text-red-500"
                              xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                              <path fill-rule="evenodd"
                                d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z"
                                clip-rule="evenodd" />
                            </svg>
                            <svg v-else class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20" fill="currentColor">
                              <path fill-rule="evenodd"
                                d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a2 2 0 01-2 2H6a2 2 0 01-2-2V4z"
                                clip-rule="evenodd" />
                            </svg>
                          </div>
                          <div class="flex-1 min-w-0">
                            <p class="text-sm font-medium text-gray-900 truncate" :title="document.name">
                              {{ document.name }}
                            </p>
                            <p v-if="document.description" class="text-xs text-gray-500 truncate"
                              :title="document.description">
                              {{ document.description }}
                            </p>
                          </div>
                        </div>
                        <div class="ml-4 flex-shrink-0 flex">
                          <button @click="openDocumentViewer(currentSubmission.id, document)"
                            class="mr-2 inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                            View
                          </button>
                          <button @click="downloadDocument(currentSubmission.id, document.id, document.name)"
                            class="inline-flex items-center px-2.5 py-1.5 border border-transparent shadow-sm text-xs font-medium rounded text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                            Download
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <div v-if="Object.keys(groupDocumentsByCategory(currentSubmission)).length === 0"
                  class="text-center py-10 bg-gray-50 rounded-lg">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-gray-400 mx-auto mb-4" fill="none"
                    viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z" />
                  </svg>
                  <p class="text-gray-600">No documents found</p>
                </div>
              </div>

              <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button @click="closeDocumentsListModal"
                  class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:w-auto sm:text-sm">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';
import { useInternalAuthStore } from '@/stores/internalAuth';
import { useSecurityAssessmentStore } from '@/stores/securityAssessment';
import { storeToRefs } from 'pinia';
import { axiosInstance } from '@/lib/axios';

const authStore = useInternalAuthStore();
const securityStore = useSecurityAssessmentStore();
const router = useRouter();
const { isAdminUser } = storeToRefs(authStore);

// State
const submissions = ref([]);
const selectedSubmissions = ref([]);
const isLoading = ref(true);
const error = ref(null);
const currentPage = ref(1);
const perPage = ref(10);
const sortColumn = ref('submittedAt');
const sortDirection = ref('desc');
const isPdfLoading = ref({});
const showDocumentsListModal = ref(false);
const currentSubmission = ref(null);
const isDocumentLoading = ref(false);
const documentUrl = ref(null);

// openDocumentsModal function to open the documents list modal
function openDocumentsModal(submission) {
  currentSubmission.value = submission;
  showDocumentsListModal.value = true;
}

const filters = ref({
  status: '',
  dateFrom: '',
  dateTo: '',
  search: '',
  hasDocuments: '',
  documentType: '', // New filter for document type
});

// Document viewer dialog state
const showDocumentDialog = ref(false);
const currentDocument = ref(null);
const currentSubmissionId = ref(null);

// Document category labels
const categoryLabels = {
  identificationDocuments: 'Identification',
  addressProof: 'Address Proof',
  employmentDocuments: 'Employment',
  educationDocuments: 'Education',
  securityClearanceDocuments: 'Security Clearance',
  criminalRecordCheck: 'Criminal Record Check',
  workPermit: 'Work Permit',
  additionalDocuments: 'Additional Documents'
};

// Column definitions
const columns = [
  { key: 'user', label: 'User' },
  { key: 'status', label: 'Status' },
  { key: 'submittedAt', label: 'Submitted Date' },
  { key: 'documents', label: 'Documents' }, // New column
  { key: 'actions', label: 'Actions' },
];




// Computed properties
const filteredSubmissions = computed(() => {
  let filtered = [...submissions.value];

  // Apply filters
  if (filters.value.status) {
    filtered = filtered.filter(s => s.status === filters.value.status);
  }

  if (filters.value.dateFrom) {
    filtered = filtered.filter(s => new Date(s.submittedAt) >= new Date(filters.value.dateFrom));
  }

  if (filters.value.dateTo) {
    filtered = filtered.filter(s => new Date(s.submittedAt) <= new Date(filters.value.dateTo));
  }

  if (filters.value.search) {
    const search = filters.value.search.toLowerCase();
    filtered = filtered.filter(s =>
      s.user.firstName.toLowerCase().includes(search) ||
      s.user.lastName.toLowerCase().includes(search) ||
      s.user.email.toLowerCase().includes(search)
    );
  }

  // Apply document filters
  if (filters.value.hasDocuments === 'true') {
    filtered = filtered.filter(s => {
      if (!s.documents) return false;
      if (filters.value.documentType) {
        // Filter by specific document type
        return s.documents.some(doc => doc.type === filters.value.documentType);
      }
      return s.documents.length > 0;
    });
  } else if (filters.value.hasDocuments === 'false') {
    filtered = filtered.filter(s => !s.documents || s.documents.length === 0);
  } else if (filters.value.documentType) {
    // Only filter by document type if hasDocuments is not set
    filtered = filtered.filter(s =>
      s.documents && s.documents.some(doc => doc.type === filters.value.documentType)
    );
  }

  // Apply sorting
  filtered.sort((a, b) => {
    let aVal, bVal;

    if (sortColumn.value === 'user') {
      aVal = `${a.user.lastName}${a.user.firstName}`.toLowerCase();
      bVal = `${b.user.lastName}${b.user.firstName}`.toLowerCase();
    } else if (sortColumn.value === 'documents') {
      aVal = (a.documents || []).length;
      bVal = (b.documents || []).length;
    } else if (sortColumn.value === 'reviewedBy' && a.reviewer && b.reviewer) {
      aVal = `${a.reviewer.lastName}${a.reviewer.firstName}`.toLowerCase();
      bVal = `${b.reviewer.lastName}${b.reviewer.firstName}`.toLowerCase();
    } else {
      aVal = a[sortColumn.value];
      bVal = b[sortColumn.value];
    }

    if (!aVal) aVal = '';
    if (!bVal) bVal = '';

    return sortDirection.value === 'asc' ? aVal > bVal ? 1 : -1 : aVal < bVal ? 1 : -1;
  });

  return filtered;
});

const paginatedSubmissions = computed(() => {
  const start = (currentPage.value - 1) * perPage.value;
  return filteredSubmissions.value.slice(start, start + perPage.value);
});

const totalPages = computed(() =>
  Math.max(1, Math.ceil(filteredSubmissions.value.length / perPage.value))
);

const selectAll = computed({
  get: () => {
    return paginatedSubmissions.value.length > 0 &&
      selectedSubmissions.value.length === paginatedSubmissions.value.length;
  },
  set: (value) => {
    if (value) {
      selectedSubmissions.value = paginatedSubmissions.value.map(s => s.id);
    } else {
      selectedSubmissions.value = [];
    }
  }
});

// Methods
function sortBy(column) {
  if (sortColumn.value === column) {
    sortDirection.value = sortDirection.value === 'asc' ? 'desc' : 'asc';
  } else {
    sortColumn.value = column;
    sortDirection.value = 'asc';
  }
}

function toggleSelection(id) {
  const index = selectedSubmissions.value.indexOf(id);
  if (index === -1) {
    selectedSubmissions.value.push(id);
  } else {
    selectedSubmissions.value.splice(index, 1);
  }
}

function toggleSelectAll() {
  if (selectedSubmissions.value.length === paginatedSubmissions.value.length) {
    selectedSubmissions.value = [];
  } else {
    selectedSubmissions.value = paginatedSubmissions.value.map(s => s.id);
  }
}

// Format document category names for display
function formatCategory(category) {
  return categoryLabels[category] || category.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
}

// Get filtered documents based on selected document type
function getFilteredDocuments(submission) {
  if (!submission.formData || !submission.formData.supportingDocuments) {
    return [];
  }

  const supportingDocs = submission.formData.supportingDocuments;
  const allDocuments = [];

  // Process each document category
  Object.keys(supportingDocs).forEach(category => {
    if (Array.isArray(supportingDocs[category])) {
      // Add category information to each document
      const docsWithCategory = supportingDocs[category].map(doc => ({
        ...doc,
        type: category
      }));
      allDocuments.push(...docsWithCategory);
    }
  });

  // Apply document type filter if needed
  if (filters.value.documentType) {
    return allDocuments.filter(doc => doc.type === filters.value.documentType);
  }

  return allDocuments;
}

// Group documents by category for display
function groupDocumentsByCategory(submission) {
  const documents = getFilteredDocuments(submission);
  const grouped = {};

  documents.forEach(doc => {
    if (!grouped[doc.type]) {
      grouped[doc.type] = [];
    }
    grouped[doc.type].push(doc);
  });

  return grouped;
}

// Function to check if a file is an image
function isImage(fileName) {
  if (!fileName) return false;
  const ext = fileName.split('.').pop().toLowerCase();
  return ['jpg', 'jpeg', 'png', 'gif', 'webp', 'bmp'].includes(ext);
}

// Function to check if a file is a PDF
function isPdf(fileName) {
  if (!fileName) return false;
  return fileName.split('.').pop().toLowerCase() === 'pdf';
}

// Update the openDocumentViewer function
async function openDocumentViewer(submissionId, document) {
  showDocumentsListModal.value = false;
  currentSubmissionId.value = submissionId;
  currentDocument.value = document;
  showDocumentDialog.value = true;

  // Reset document URL and set loading state
  documentUrl.value = null;
  isDocumentLoading.value = true;

  try {
    // Get the document blob
    const blob = await securityStore.getDocument(submissionId, document.id);
    
    // Create URL for the blob
    documentUrl.value = URL.createObjectURL(blob);
  } catch (err) {
    console.error('Error fetching document:', err);
    error.value = 'Failed to load document. Please try again later.';
  } finally {
    isDocumentLoading.value = false;
  }
}

// Update the closeDocumentDialog function to clean up URLs
function closeDocumentDialog() {
  if (documentUrl.value) {
    URL.revokeObjectURL(documentUrl.value);
  }
  showDocumentDialog.value = false;
  currentDocument.value = null;
  currentSubmissionId.value = null;
  documentUrl.value = null;
}

// Update the component's beforeUnmount hook to clean up any remaining URLs
onBeforeUnmount(() => {
  if (documentUrl.value) {
    URL.revokeObjectURL(documentUrl.value);
  }
});


// Close documents list modal
function closeDocumentsListModal() {
  showDocumentsListModal.value = false;
  currentSubmission.value = null;
}

// Method to download document
async function downloadDocument(assessmentId, documentId, fileName) {
  try {
    await securityStore.downloadDocument(assessmentId, documentId, fileName);
  } catch (err) {
    console.error('Error downloading document:', err);
    error.value = 'Failed to download document. Please try again later.';
  }
}

// Watchers
watch([filters, sortColumn, sortDirection], () => {
  currentPage.value = 1;
});

function setLoading(value) {
  isLoading.value = value;
  if (value) {
    error.value = null;
  }
}

function formatDate(date) {
  if (!date) return '-';
  return new Date(date).toLocaleDateString('en-CA', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
}

async function fetchSubmissions() {
  try {
    setLoading(true);
    const fetchedSubmissions = await authStore.fetchSecuritySubmissions();
    submissions.value = fetchedSubmissions;

    // Fetch documents for each submission
    for (const submission of submissions.value) {
      try {
        submission.documents = await securityStore.listAssessmentDocuments(submission.id);
      } catch (err) {
        console.error(`Error fetching documents for submission ${submission.id}:`, err);
        submission.documents = [];
      }
    }
  } catch (err) {
    console.error('Error fetching submissions:', err);
    error.value = 'Failed to load submissions. Please try again later.';
  } finally {
    setLoading(false);
  }
}

async function downloadPdf(id) {
  try {
    // Set loading state for this specific submission
    isPdfLoading.value = { ...isPdfLoading.value, [id]: true };

    const response = await axiosInstance.get(`/auth/security-assessment/${id}/pdf`, {
      responseType: 'blob'
    });

    // Create a Blob from the PDF stream
    const blob = new Blob([response.data], { type: 'application/pdf' });

    // Create an object URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a link element and trigger download
    const link = document.createElement('a');
    link.href = url;
    link.download = `security-assessment-${id}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Clean up the URL created
    URL.revokeObjectURL(url);
  } catch (err) {
    console.error('Error downloading PDF:', err);
    error.value = 'Failed to download PDF. Please try again later.';
  } finally {
    // Clear loading state
    isPdfLoading.value = { ...isPdfLoading.value, [id]: false };
  }
}

async function bulkExportPdf() {
  if (selectedSubmissions.value.length === 0) return;

  // For each selected submission, download the PDF
  for (const id of selectedSubmissions.value) {
    await downloadPdf(id);
    // Small delay to prevent overwhelming the browser
    await new Promise(resolve => setTimeout(resolve, 500));
  }
}

async function bulkMarkAsReviewed() {
  if (selectedSubmissions.value.length === 0) return;

  try {
    setLoading(true);
    await axiosInstance.post('/auth/security-assessment/bulk-review', {
      ids: selectedSubmissions.value
    });

    // Refresh submissions to show updated status
    await fetchSubmissions();

    // Clear selection after action
    selectedSubmissions.value = [];
  } catch (err) {
    console.error('Error marking submissions as reviewed:', err);
    error.value = 'Failed to update submissions. Please try again later.';
  } finally {
    setLoading(false);
  }
}

async function handleDocumentUpload(submissionId, file, documentType, description) {
  try {
    isLoading.value = true;

    await securityStore.uploadDocument(submissionId, {
      file,
      type: documentType,
      description
    });

    // Refresh submissions to show the new document
    await fetchSubmissions();

    return true;
  } catch (err) {
    console.error('Error uploading document:', err);
    error.value = 'Failed to upload document. Please try again later.';
    return false;
  } finally {
    isLoading.value = false;
  }
}

onMounted(async () => {
  if (!isAdminUser.value) {
    router.push('/dashboard');
    return;
  }

  await fetchSubmissions();
});
</script>